<template>
    <div class="a-studio__header">
        <div class="as-header__left">
            <p class="mb-0">
                Ad Builder
            </p>
            <div class="as-divider ml-5" />
            <v-menu
                v-if="shouldShowNewAdButton"
                v-model="addMenuOpen"
                transition="slide-y-transition"
                bottom
                left
                nudge-right="12"
                nudge-bottom="-22"
                offset-y
                allow-overflow
                :nudge-width="60"
                fixed
                :close-on-content-click="false">
                <template #activator="{ on }">
                    <button
                        class="add-button-wrapper"
                        v-on.prevent="on">
                        <icon
                            name="plus"
                            color="#00A2EA"
                            width="20"
                            height="20" />
                        <span class="mt-1">ADD</span>
                    </button>
                </template>
                <div class="triangle triangle-header">
                    -
                </div>
                <div class="dropdown-menu">
                    <a
                        @click="openNewAdModal">
                        Create New Ad(s)
                    </a>
                </div>
            </v-menu>
        </div>
        <div class="as-header__right">
            <div class="as-divider" />
            <v-menu
                v-if="!isInitializing"
                v-model="supportMenuOpen"
                transition="slide-y-transition"
                bottom
                left
                nudge-right="16"
                nudge-bottom="-13"
                offset-y
                allow-overflow
                :nudge-width="60"
                fixed
                :close-on-content-click="false">
                <template #activator="{ on }">
                    <button
                        class="exit-button-wrapper"
                        v-on.prevent="on">
                        <span class="help-label">Need Help?</span>
                        <icon
                            name="life-buoy"
                            color="#8f9ea6"
                            width="20"
                            height="20" />
                    </button>
                </template>
                <div class="triangle triangle-header">
                    -
                </div>
                <support-options-dropdown
                    @slideout-opened="supportMenuOpen = false" />
            </v-menu>
            <template v-if="!openFromDeployment || isOpenedFromLayeredDesigner">
                <div class="as-divider" />
                <button
                    class="exit-button-wrapper"
                    @click="exit">
                    <span class="exit-label">Exit</span>
                    <icon
                        name="close"
                        class="exit-icon"
                        color="#3A4044"
                        width="20"
                        height="20" />
                </button>
                <styled-dialog
                    v-if="uploadingCompleted"
                    v-model="closeConfirmationOpened">
                    <div class="text-xs-center">
                        <h3 class="mb-4">
                            Unsaved Work Will Be Lost
                        </h3>
                        <p>
                            Are you sure you want to continue without saving?  All of your work will be lost.
                        </p>
                        <styled-button
                            class="mt-4"
                            @click="close">
                            Close & Discard Changes
                        </styled-button>
                    </div>
                    <template #actions>
                        <a
                            href="#"
                            class="red--text"
                            @click.prevent="closeConfirmationOpened = false">
                            Cancel
                        </a>
                        <a
                            class="ml-4"
                            href="#"
                            @click.prevent="saveAndExit">
                            Save &amp; Exit
                        </a>
                    </template>
                </styled-dialog>
            </template>
        </div>
    </div>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import Icon from '@/components/globals/Icon';
import { mapState, mapGetters } from 'vuex';
import SupportOptionsDropdown from '@/components/globals/SupportOptionsDropdown';
import EventBus from '@/event-bus';

export default {
    components: {
        StyledDialog,
        StyledButton,
        Icon,
        SupportOptionsDropdown
    },
    props: {
        uploadingCompleted: {
            type: Boolean,
            required: true,
        },
        openFromDeployment: {
            type: Boolean,
            default: false,
        },
        isOpenedFromLayeredDesigner: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            supportMenuOpen: false,
            addMenuOpen: false,
            showNewAdModal: false,
            closeConfirmationOpened: false,
            shouldShowNewAdButton: false
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user,
            currentDealer: (state) => state.dealer.currentDealer,
            currentDealerId: (state) => state.dealer.currentDealerId,
        }),
        ...mapGetters([
            'isInitializing',
        ]),
        duAdOpsTicketLink() {
            return `https://form.jotform.com/buyerbridge/dealers-united-ticket-submission?bbdealerid=${this.currentDealerId}&dealerName=${encodeURIComponent(this.currentDealer?.name)}&whoIs=${encodeURIComponent(this.user?.name)}&submission_source=BuyerBridge`;
        }
    },
    created() {
        EventBus.$on('show-new-ad-button', () => this.shouldShowNewAdButton = true);
    },
    methods: {
        close() {
            this.$emit('close');
        },
        exit() {
            if (this.uploadingCompleted) {
                this.closeConfirmationOpened = true;
            } else {
               this.close();
            }
        },
        saveAndExit() {
            this.closeConfirmationOpened = false;
            this.$emit('save-and-exit');
        },
        openNewAdModal() {
            EventBus.$emit('open-new-ad-modal');
            this.addMenuOpen = false;
        },
    }
};
</script>

<style lang="scss" scoped>
.a-studio__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    position: relative;
    z-index: 2;
    background: linear-gradient(to right,#1F6886 0%, #1F6886 15%, #3A4044 35%, #3A4044 100%);
    .as-header__left{
        font-size: 22px;
        color: $white;
        padding-left: 30px;
        flex-direction: row;
        display: flex;
        align-items: center;

        .add-button-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding:15px;
          margin-left:10px;
          margin-right: 10px;
          span {
            font-size: 11px;
            color: $white;
          }
        }
    }
    .as-header__right{
        display: flex;
        align-items: center;
    }
    .as-divider{
        width: 1px;
        height: 40px;
        background-color: $gray;
    }
    .exit-button-wrapper {
        padding:15px;
        margin-left:10px;
        margin-right: 10px;
    }
    .exit-icon {
        margin-left: 10px;
        padding: 5px;
        border-radius: 50%;
        color: $white;
        background-color: $gray;
    }
    .exit-label {
        font-size: 16px;
        color: $gray-text;
        margin-right: 5px;
    }
    .help-label {
        font-size: 16px;
        color: $gray-text;
        margin-right:10px;
    }
}
.dropdown-menu {
    background-color: white;
    box-shadow: 0 0 6px 4px rgba(0,0,0,0.1);
    margin: 0 10px 10px 10px;
    border-radius: 5px;
    a {
        display: block;
        padding: 15px 40px 15px 40px;
        color: #00A4EB;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        transition: background-color 0.2s ease-in-out;
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}
.triangle-header:before {
    border-color: transparent transparent white transparent;
}
.v-menu__content, .v-card {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2) !important;
    border-radius: 0 !important;
}
</style>
