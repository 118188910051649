<template>
    <v-select
        v-model="comparison"
        :items="items"
        label="COMPARISON"
        class="styled-field" />
</template>

<script>
import { SET_COMPARISON } from '@/store/mutation-types';

export default {
    computed: {
        comparison: {
            get() {
                return this.$store.state.metrics.comparisonVisible ? 'Show' : 'Hide';
            },
            set(value) {
                const comparisonValue = value === 'Show' ? true : false;
                this.$store.commit(SET_COMPARISON, comparisonValue);
            }
        },
        items() {
            return ['Show','Hide'];
        }
    }
};
</script>

<style scoped>
.styled-field {
    width: 105px;
}
</style>