<template>
    <div class="funnel-container">
        <div
            class="styled-funnel"
            :class="{'hide-spend': hideSpend, 'hide-change': !showChange}">
            <div
                class="block"
                :class="{'active': metric.id == 'impressions'}"
                @click="selectMetric(availableMetrics[0])">
                <div class="block__nameholder">
                    <div class="block__name">
                        IMPRESSIONS
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ metrics.impressions.formatted }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="metrics.impressions.value"
                            :previous-metric-value="showChange ? metricsComparison.impressions.value : 0" />
                    </div>
                </div>
                <div class="block__divider" />
                <div class="block__metrics">
                    <div class="block__subname">
                        Spend
                    </div>
                    <div class="block__price">
                        {{ metrics.spend.formatted }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="metrics.spend.value"
                            :previous-metric-value="showChange ? metricsComparison.spend.value : 0" />
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block__nameholder">
                    <div class="block__name">
                        SWIPE UP RATE
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ metrics.swipes_rate.formatted }}
                    </div>
                </div>
            </div>
            <div
                class="block"
                :class="{'active': metric.id == 'swipes'}"
                @click="selectMetric(availableMetrics[1])">
                <div class="block__nameholder">
                    <div class="block__name">
                        SWIPE UPS
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ metrics.swipes.formatted }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="metrics.swipes.value"
                            :previous-metric-value="showChange ? metricsComparison.swipes.value : 0" />
                    </div>
                </div>
                <div class="block__divider" />
                <div class="block__metrics">
                    <div class="block__subname">
                        COST / SWIPE UP
                    </div>
                    <div class="block__price">
                        {{ metrics.swipes_cost_per.formatted }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="metrics.swipes_cost_per.value"
                            :previous-metric-value="showChange ? metricsComparison.swipes_cost_per.value : 0"
                            change-reversed />
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block__nameholder">
                    <div class="block__name">
                        Conversion Rate
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ metrics.conversion_sign_ups_rate.formatted }}
                    </div>
                </div>
            </div>
            <div
                class="block"
                :class="{'active': metric.id == 'leads'}"
                @click="selectMetric(availableMetrics[2])">
                <div class="block__nameholder">
                    <div class="block__name">
                        Leads
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ metrics.conversion_sign_ups.formatted }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="metrics.conversion_sign_ups.value"
                            :previous-metric-value="showChange ? metricsComparison.conversion_sign_ups.value : 0" />
                    </div>
                </div>
                <div class="block__divider" />
                <div class="block__metrics">
                    <div class="block__subname">
                        Cost / Lead
                    </div>
                    <div class="block__price">
                        {{ metrics.conversion_sign_ups_cost_per.formatted }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="metrics.conversion_sign_ups_cost_per.value"
                            :previous-metric-value="showChange ? metricsComparison.conversion_sign_ups_cost_per.value : 0"
                            change-reversed />
                    </div>
                </div>
            </div>
            <template>
                <div class="block">
                    <div class="block__nameholder">
                        <div class="block__name">
                            Conversion Rate
                        </div>
                    </div>
                    <div class="block__nums">
                        <div class="block__count">
                            {{ salesConversionRate }}
                        </div>
                    </div>
                </div>
                <div
                    class="block"
                    :class="{'active': metric.id == 'conversion_purchases'}"
                    @click="selectMetric(availableMetrics[3])">
                    <div class="block__nameholder">
                        <div class="block__name">
                            Sales
                        </div>
                    </div>
                    <div class="block__nums">
                        <div class="block__count">
                            {{ sales }}
                        </div>
                        <div class="block__comparison">
                            <percentage-change
                                :metric-value="metrics.conversion_purchases.value"
                                :previous-metric-value="showChange ? metricsComparison.conversion_purchases.value : 0" />
                        </div>
                    </div>
                    <div class="block__divider" />
                    <div class="block__metrics">
                        <div class="block__subname">
                            COST / SALE
                        </div>
                        <div class="block__price">
                            {{ costPerSale }}
                        </div>
                        <div class="block__comparison">
                            <percentage-change
                                :metric-value="metrics.cps.value"
                                :previous-metric-value="showChange ? metricsComparison.cps.value : 0"
                                change-reversed />
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <powered-by :platforms="['snapchat']" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PercentageChange from '@/components/globals/PercentageChange';
import PoweredBy from '@/components/globals/PoweredBy';
export default {
    name: 'Funnel',
    components: {
        PercentageChange,
        PoweredBy
    },
    props: {
        metric: {
            type: Object,
            required: true
        },
        metrics: {
            type: Object,
            required: true
        },
        metricsComparison: {
            type: Object,
            default: null
        },
        availableMetrics: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters('metrics', [
            'hideSpend'
        ]),
        ...mapGetters([
            'userIsClient'
        ]),
        ...mapState({
            comparisonVisible: (state) => state.metrics.comparisonVisible,
        }),
        showChange() {
            return !this.userIsClient && !!this.metricsComparison && this.comparisonVisible;
        },
        sales() {
            return !this.metrics.conversion_purchases.value ? '-' : this.metrics.conversion_purchases.formatted;
        },
        costPerSale() {
            return !this.metrics.cps.value ? '-' : this.metrics.cps.formatted;
        },
        salesConversionRate() {
            return !this.metrics.conversion_purchases_rate.value ? '-' : this.metrics.conversion_purchases_rate.formatted;
        }
    },
    methods: {
        selectMetric(metric) {
            this.$emit('changeMetric', metric);
        },
    }
};
</script>

<style lang="scss" scoped>
.funnel-container {
    position: relative;
    padding: 0px;
    display: flex;
    flex-direction: column;
}
</style>
