import axios from 'axios';
import moment from 'moment';
import HTTP from './http';
import ApiQuery from './api-query';
import { get, uniqBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import applyOptionalProperties from './helpers/applyOptionalProperties';
import { DMA, GEO, POSTAL_CODE } from '@/components/ad-deployment/store/constants';
import { formatToBuyerBridgeLocation } from './helpers/addressFormatters';
import { FACEBOOK_BB_PLATFORM_ID } from '@/helpers/globals';

class ApiRepository {

    constructor() {
        this.baseUrl = process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI;
        this.xApiBaseUrl = process.env.VUE_APP_X_API_URL;
        this.xApiKey = process.env.VUE_APP_X_API_KEY;
        this.locationApiBaseUrl = 'https://2tnv4k5fl7.execute-api.us-east-1.amazonaws.com/api';
    }

    getDealers(paginationOptions = null, searchQuery = null, siteProviderId = null, agencyId = null, archivedOptions = null, filters = null) {
        const apiQuery = new ApiQuery('dealers');
        apiQuery.withFields(
            'dealers.vehicle_data_last_processed',
        );

        apiQuery.withRelationships(
            'dealers.products',
        );

        if (window.userHasRole(['super_admin', 'performance_manager', 'agency_dashboard'])) {
            apiQuery.withRelationships(
                'dealers.site_provider',
                'dealers.contacts',
                'dealers.lead_destinations',
                'dealers.vehicle_data_sources',
                'dealers.remote_dealer_identifiers',
                'dealers.remote_files',
                'dealers.facebook_ad_account_annotations',
                'dealers.dealer_onboarding',
                'dealers.products',
                'dealers.account_groups',
                'dealers.platforms',
                'dealers.agency',
                'dealers.dealer_upgrades',
            );
        }

        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        if (siteProviderId != null) {
            apiQuery.withScope('dealers.site_provider_equals', {
                id: siteProviderId,
            });
        }

        if (agencyId != null) {
            apiQuery.withScope('dealers.agency_equals', {
                id: agencyId,
            });
        }

        if (archivedOptions != null) {
            apiQuery.setArchivedOptions(archivedOptions);
        }

        if (filters != null) {
            apiQuery.withFilters(filters);
        }

        return apiQuery.get();
    }

    getDealersMinimal(searchQuery = null, agencyId = null) {
        const apiQuery = new ApiQuery('dealers');
        apiQuery.withRelationships(
            'dealers.agency',
        );
        if (agencyId != null) {
            apiQuery.withScope('dealers.agency_equals', {
                id: agencyId,
            });
        }
        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }
        return apiQuery.get();
    }

    getDealersOnboarding(filters = [], withAgencySortByScope = false, paginationOptions, searchQuery) {
        const apiQuery = new ApiQuery('dealers');
        apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        apiQuery.setSearchQuery(searchQuery);
        apiQuery.withRelationships(
            'dealers.site_provider',
            'dealers.products',
            'dealers.contacts',
            'dealers.lead_destinations',
            'dealers.vehicle_data_sources',
            'dealers.remote_dealer_identifiers',
            'dealers.remote_files',
            'dealers.agency',
            'dealers.dealer_onboarding',
            'comments.user',
        );
        apiQuery.withWorkflowTransitionHistories(
            'dealers.PlumbingRequestWorkflow',
        );

        apiQuery.withScope('comments.sort_by_created_at_date_ascending');

        apiQuery.withScope('dealers.with_filters', {
            filters,
        });

        if (withAgencySortByScope) {
            apiQuery.withScope('dealers.sort_by_agency_name');
        }

        return apiQuery.get();
    }

    getDealersCurrentlyOnboarding(filters = [], paginationOptions, searchQuery, agencyId = null) {
        const apiQuery = new ApiQuery('dealers');
        apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        apiQuery.setSearchQuery(searchQuery);
        apiQuery.withRelationships(
            'dealers.site_provider',
            'dealers.products',
            'dealers.lead_destinations',
            'dealers.vehicle_data_sources',
            'dealers.dealer_onboarding',
            'dealers.agency',
            'dealers.dealer_upgrades',
        );

        apiQuery.withScope('dealers.with_filters', { filters });

        if (agencyId != null) {
            apiQuery.withScope('dealers.agency_equals', {
                id: agencyId,
            });
        }

        return apiQuery.get();
    }

    getAgencyDealersWithPlatform({ agencyId, platformId, pageNumber = 1, selectedGroups = null }) {
        const apiQuery = new ApiQuery('dealers');

        if (platformId !== FACEBOOK_BB_PLATFORM_ID) {
            apiQuery.withScope('dealers.where', [{
                column: 'ad_accounts.platform_id',
                value: platformId,
            }]);
        }

        apiQuery.withScope('dealers.agency_equals', {
            id: agencyId,
        });

        if (selectedGroups != null) {
            apiQuery.withScope('dealers.groups_in', {
                ids: selectedGroups,
            });
        }

        apiQuery.withRelationships([
            'dealers.ad_accounts',
            'dealers.agency',
            'dealers.facebook_ad_account_annotations',
            'dealers.products',
            'agencies.business_managers',
        ]);

        apiQuery.setPageNumber(pageNumber);
        apiQuery.setPageSize(100);
        return apiQuery.get();
    }

    getDealerPlatformMetrics({
                                 dealerId,
                                 platform,
                                 startDate,
                                 endDate,
                                 level = 'account',
                                 interval = null,
                             }) {

        const config = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            level,
            interval,
        };

        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/metrics`, config);
    }

    queueDealerAdStatsSync({ dealerId, platform }) {
        return (new HTTP()).post(`/dealers/${dealerId}/platforms/${platform}/ad_stats/queue-full-sync`);
    }

    getDealerAdStatsStatus({ dealerId, platform }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad_stats/status`);
    }

    // Ad account
    async getDealerPlatformAdAccount({ dealerId, platform, adAccountId }) {
        const { data } = await (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-accounts/${adAccountId}`);

        return data;
    }

    getDealerPlatformAdAccountMetrics({ dealerId, platform, adAccountId, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-accounts/${adAccountId}/metrics`, params);
    }

    // Campaigns
    getDealerPlatformAdAccountCampaigns({ dealerId, platform, adAccountId }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-accounts/${adAccountId}/campaigns`);
    }

    getDealerPlatformCampaigns({ dealerId, platform, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/campaigns`, params);
    }

    getDealerPlatformCampaignCount({ dealerId, platform, adAccountId, status }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-accounts/${adAccountId}/campaigns/count`, {
            status,
        });
    }

    getDealerPlatformCampaign({ dealerId, platform, campaignId }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/campaigns/${campaignId}`);
    }

    getDealerFacebookCampaignMetrics({ dealerId, platform, campaignId, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/campaigns/${campaignId}/metrics`, params);
    }

    getDealerSnapchatCampaignMetrics({ dealerId, platform, campaignId, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/campaigns/${campaignId}/metrics`, params);
    }

    getDealerTikTokCampaignMetrics({ dealerId, platform, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/campaigns/metrics`, params);
    }

    // Ad Sets
    getDealerPlatformCampaignAdSets({ dealerId, platform, campaignId }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/campaigns/${campaignId}/ad-sets`);
    }

    getDealerPlatformAdSets({ dealerId, platform, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets`, params);
    }

    getDealerPlatformAdSetCount({ dealerId, platform, status }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets/count`, {
            status,
        });
    }

    getDealerPlatformAdSet({ dealerId, platform, adSetId }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets/${adSetId}`);
    }

    getDealerFacebookAdSetMetrics({ dealerId, platform, adSetId, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets/${adSetId}/metrics`, params);
    }

    getDealerSnapchatAdSetMetrics({ dealerId, platform, adSetId, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets/${adSetId}/metrics`, params);
    }

    getDealerTikTokAdSetMetrics({ dealerId, platform, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets/metrics`, params);
    }

    // Ads
    getDealerPlatformAdSetsAds({ dealerId, platform, adSetIds }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets/${adSetIds}/ads`);
    }

    getDealerPlatformAds({ dealerId, platform, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ads`, params);
    }

    getDealerPlatformAdCount({ dealerId, platform, status, adSetIds = null }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ads/count`, {
            status,
            adSetIds,
        });
    }

    getDealerPlatformAd({ dealerId, platform, adId }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ads/${adId}`);
    }

    getPlatformAdsById({ dealerId, platform, adIds }) {
        const promises = adIds.map(adId => (
            this.$apiRepository.getDealerPlatformAd({
                dealerId,
                platform,
                adId,
            })
        ));

        return Promise.all(promises);
    }

    getDealerFacebookAdMetrics({ dealerId, platform, adId, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ads/${adId}/metrics`, params);
    }

    getDealerSnapchatAdMetrics({ dealerId, platform, adId, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ads/${adId}/metrics`, params);
    }

    getDealerTikTokAdMetrics({ dealerId, platform, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ads/metrics`, params);
    }

    // Ad Previews
    getDealerPlatformCampaignPreview({ dealerId, platform, campaignId, key }) {
        return axios.get(`${this.baseUrl}/dealers/${dealerId}/platforms/${platform}/campaigns/${campaignId}?key=${key}`);
    }


    getDealerPlatformAdSetPreview({ dealerId, platform, adSetId, key }) {
        return axios.get(`${this.baseUrl}/dealers/${dealerId}/platforms/${platform}/ad-sets/${adSetId}?key=${key}`);
    }

    getDealerPlatformAdSetsPreview(options) {

        const {
            dealerId,
            platform,
            key,
            stats = false,
        } = options;

        const params = { key, stats };

        applyOptionalProperties(options, params, {
            pageSize: 'page_size',
            campaignIds: 'campaignIds',
            status: 'status',
            page: 'page',
        });

        return axios.get(`${this.baseUrl}/dealers/${dealerId}/platforms/${platform}/ad-sets`, { params });
    }

    // It is very similar to the getDealerPlatformAdSetsPreview method above
    // but it uses the http endpoints which is using the auth token
    // this is used in ad deployment and not ad previews
    getInternalDealerPlatformAdsSetPreview(options) {
        const {
            dealerId,
            platform,
            params,
        } = options;

        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/ad-sets`, params);
    }

    getDealerPlatformAdPreview({ dealerId, platform, adId, key, preview = false, reset = false }) {
        return axios.get(`${this.baseUrl}/dealers/${dealerId}/platforms/${platform}/ads/${adId}`, {
            params: {
                key,
                preview,
                reset,
            },
        });
    }

    getDealerPlatformAdsPreview(options) {

        const {
            dealerId,
            platform,
            key,
            creative = false,
            stats = false,
        } = options;

        const params = { key, creative, stats };

        applyOptionalProperties(options, params, {
            pageSize: 'page_size',
            adSetIds: 'adSetIds',
            status: 'status',
            page: 'page',
        });

        return axios.get(`${this.baseUrl}/dealers/${dealerId}/platforms/${platform}/ads`, { params });
    }

    /**
     * Creates a storage record for ad previews which can be
     * used to pull up a secure public preview URL
     *
     * The `previews` data needs to be in this format:
     * ```js
     * {
     *   "facebook": {
     *     "ad_account_id": "1234"
     *     campaigns: [{
     *       "external_id": "1234",
     *       "ad_sets": [{
     *         "external_id": "1234",
     *         "ads": [{
     *           "external_id": "1234"
     *         }]
     *       }]
     *     }]
     *   }
     * }
     * ```
     *
     * @param {object} params
     * @param {object} params.dealerId
     * @param {object} params.agencyId
     * @param {object} params.userId
     * @param {object} params.previews The preview structure noted above
     * @returns
     */
    createAdPreview({ dealerId, agencyId, userId, previews }) {

        const uuid = uuidv4();

        const data = {
            key: `ad_previews:${uuid}`,
            agency_id: agencyId,
            user_id: userId,
            public: true,
            data: {
                previews,
                agency_id: agencyId,
                dealer_id: dealerId,
            },
        };

        return (new HTTP()).post('/data-storage', data);
    }

    createAdPreviewPublic({ dealerId, agencyId, userId, previews }) {

        const uuid = uuidv4();

        const data = {
            key: `ad_previews:${uuid}`,
            agency_id: agencyId,
            user_id: userId,
            public: true,
            data: {
                previews,
                agency_id: agencyId,
                dealer_id: dealerId,
            },
        };

        axios.defaults.headers.common['BB-Token'] = process.env.VUE_APP_BB_TOKEN;

        return axios.post(`${this.baseUrl}/data-storage`, data);

    }

    // snapchat public profiles
    getSnapchatPublicProfiles(dealerId, adAccountId) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/snapchat/ad-accounts/${adAccountId}/all-public-profiles`);
    }

    // Update campaign, ad-set and ad
    updateDealerPlatformCampaign({ dealerId, platform, campaignId, params }) {
        return (new HTTP()).put(`/dealers/${dealerId}/platforms/${platform}/campaigns/${campaignId}`, params);
    }

    updateDealerPlatformAdSet({ dealerId, platform, adSetId, params }) {
        return (new HTTP()).put(`/dealers/${dealerId}/platforms/${platform}/ad-sets/${adSetId}`, params);
    }

    updateDealerPlatformAd({ dealerId, platform, adId, params }) {
        return (new HTTP()).put(`/dealers/${dealerId}/platforms/${platform}/ads/${adId}`, params);
    }

    getDealerPlatformCustomAudiences({ dealerId, platform, adAccountId, params }) {
        let endpoint = `/dealers/${dealerId}/platforms/${platform}/ad-accounts/${adAccountId}/custom-audiences`;

        return (new HTTP()).get(endpoint, params);
    }

    getDealerPlatformBudgetValidation({ dealerId, platform, params }) {
        return (new HTTP()).get(`/dealers/${dealerId}/platforms/${platform}/budget-validate`, params);
    }

    getDealerAlerts(dealerId) {
        const apiQuery = new ApiQuery(`dealers/${dealerId}/alerts`);
        apiQuery.withScope('alerts.where', [{
            column: 'alert_type.public',
            operator: '=',
            value: true,
        }]);
        return apiQuery.get();
    }

    getAgencyAlerts({ agencyId, dealerName = '', alertType = null, paginationOptions = null, selectedGroups = null }) {

        const apiQuery = new ApiQuery(`agencies/${agencyId}/alerts`);

        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        }

        const where = [{
            column: 'alert_type.public',
            operator: '=',
            value: true,
        }];

        if (alertType) {
            where.push({
                column: 'alert_type.id',
                operator: '=',
                value: alertType,
            });
        }

        if (dealerName.length) {
            where.push({
                column: 'dealer.name',
                operator: 'like',
                value: `%${dealerName}%`,
            });
        }

        apiQuery.withScope('alerts.where', where);

        if (selectedGroups != null) {
            apiQuery.withScope('alerts.groups_in', {
                ids: selectedGroups,
            });
        }

        apiQuery.withRelationships([
            'alerts.dealer',
            'dealers.facebook_ad_account_annotations',
        ]);

        return apiQuery.get();
    }

    refreshAlertType({ dealerId, alertTypeId }) {
        return (
            (new HTTP).post(`/dealers/${dealerId}/alert-types/${alertTypeId}/alerts/refresh`)
        );
    }

    getDealersMonitoring(alertIds = [], paginationOptions = null, agencyId = null, dealerId = null) {
        let apiEndpoint = 'dealers';

        if (dealerId) {
            apiEndpoint = 'dealers/' + dealerId;
        }

        const apiQuery = new ApiQuery(apiEndpoint);
        if (paginationOptions) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        }

        apiQuery.withRelationships(
            'dealers.products',
            'dealers.dealer_onboarding',
            'dealers.agency',
            'dealers.dealer_upgrades',
            'dealers.alerts',
            'alerts.monitors',
        );

        apiQuery.withFields(
            'dealers.vehicle_data_last_processed',
        );

        apiQuery.withScope('dealers.alert_monitor_id_in', alertIds);

        if (agencyId != null) {
            apiQuery.withScope('dealers.agency_equals', {
                id: agencyId,
            });
        }

        return apiQuery.get();
    }

    getDealerOnboardingStatus(id) {
        const apiQuery = new ApiQuery(`dealers/${id}/onboarding-status`);
        return apiQuery.get();
    }

    getAgencyDealersOnboarding(filters = [], withAgencySortByScope = false, paginationOptions, searchQuery, id) {
        const apiQuery = new ApiQuery('agencies/' + id + '/dealers');
        apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        apiQuery.setSearchQuery(searchQuery);
        apiQuery.withRelationships(
            'dealers.site_provider',
            'dealers.products',
            'dealers.contacts',
            'dealers.lead_destinations',
            'dealers.vehicle_data_sources',
            'dealers.remote_dealer_identifiers',
            'dealers.remote_files',
            'dealers.agency',
            'dealers.dealer_onboarding',
            'comments.user',
        );
        apiQuery.withWorkflowTransitionHistories(
            'dealers.PlumbingRequestWorkflow',
        );

        apiQuery.withScope('comments.sort_by_created_at_date_ascending');

        apiQuery.withScope('dealers.with_filters', {
            filters,
        });

        if (withAgencySortByScope) {
            apiQuery.withScope('dealers.sort_by_agency_name');
        }

        return apiQuery.get();
    }

    getDealerProductOnboardings(agencyId = null) {
        const apiQuery = new ApiQuery('dealer_product_onboarding');

        apiQuery.setPageSize(1000);
        apiQuery.withRelationships(
            'dealer_product_onboarding.dealer',
            'dealer_product_onboarding.play',
            'dealer_product_onboarding.products',
            'dealer_product_onboarding.user',
        );

        if (agencyId !== null) {
            apiQuery.withScope('dealer_product_onboarding.agency_equals', {
                id: agencyId,
            });
        }

        return apiQuery.get();
    }

    getDealerProductOnboardingsForDealer(dealerId) {
        const apiQuery = new ApiQuery('dealer_product_onboarding');

        apiQuery.setPageSize(1000);
        apiQuery.withRelationships(
            'dealer_product_onboarding.play',
            'dealer_product_onboarding.products',
            'dealer_product_onboarding.user',
        );

        if (dealerId !== null) {
            apiQuery.withScope('dealer_product_onboarding.where', [{
                column: 'dealer_id',
                operator: '=',
                value: dealerId,
            }]);
        }

        return apiQuery.get();
    }

    getDealerAgreement(dealerId, agreement) {
        return (
            (new HTTP).get(`dealers/${dealerId}/legal-agreements/${agreement}`)
        );
    }

    acceptDealerAgreement({ dealerId, legalAgreementName, document }) {
        return (
            (new HTTP).post(`dealers/${dealerId}/legal-agreements/${legalAgreementName}/accept`, {
                document,
            })
        );
    }

    getAgencyAgreement(agencyId, legalAgreementName) {
        return (
            (new HTTP).get(`agencies/${agencyId}/legal-agreements/${legalAgreementName}`)
        );
    }

    acceptAgencyAgreement( agencyId, legalAgreementName, document ) {
        return (
            (new HTTP).post(`agencies/${agencyId}/legal-agreements/${legalAgreementName}/accept`, {
                document,
            })
        );
    }


    // individual dealer stuff

    // ------------------------

    getAgencyMonitoringOverview(agencyId = null) {
        return (
            (new ApiQuery('agencies/' + agencyId + '/monitoring/overview'))
                .get()
        );
    }

    getAgencies(paginationOptions = null, searchQuery = null, relationships = null) {
        const apiQuery = new ApiQuery('agencies');

        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        } else {
            apiQuery.setSortDirection('asc');
            apiQuery.setSortField('agencies.name');
            apiQuery.setPageSize(1000);
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        if (relationships) {
            apiQuery.withRelationships(relationships);
        }

        return apiQuery
            .withRelationshipCounts('agencies.dealers')
            .get();
    }

    getAgenciesIncludingDeleted(paginationOptions = null, searchQuery = null) {
        const apiQuery = new ApiQuery('agencies/get_all_including_deleted');

        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        } else {
            apiQuery.setSortDirection('asc');
            apiQuery.setSortField('agencies.name');
            apiQuery.setPageSize(1000);
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        return apiQuery.get();
    }

    getAgenciesNoRoot() {
        return (
            (new ApiQuery('agencies'))
                .setPageSize(1000)
                .withRelationships('agencies.business_managers')
                .withRelationshipCounts('agencies.dealers')
                .setSortDirection('asc')
                .setSortField('agencies.name')
                .get()
        );
    }

    getAgency(id) {
        return (
            (new ApiQuery('agencies/' + id))
                .withRelationshipCounts('agencies.dealers')
                .get()
        );
    }

    getAgencyAdAccounts(id) {
        return (
            (new ApiQuery('agencies/' + id + '/ad_accounts'))
                .get()
        );
    }

    getAgencyPixels(id) {
        return (
            (new ApiQuery('agencies/' + id + '/pixels'))
                .get()
        );
    }

    getAgencyOfflineEventSets(id) {
        return (
            (new ApiQuery('agencies/' + id + '/offline_event_sets'))
                .get()
        );
    }

    getAgencyProductCatalogs(id) {
        return (
            (new ApiQuery('agencies/' + id + '/product_catalogs'))
                .get()
        );
    }

    getAgencyAutomotiveCatalogs(id) {
        return (
            (new ApiQuery('agencies/' + id + '/automotive_catalogs'))
                .get()
        );
    }

    getAgencyPages(id) {
        return (
            (new ApiQuery('agencies/' + id + '/pages'))
                .get()
        );
    }

    getAgencyDealers(id, search = null) {
        const query = new ApiQuery('agencies/' + id + '/dealers');

        if (search) {
            query.setSearchQuery(search);
        }

        return query.setPageSize(25).get();
    }

    async getAgencyBranding(domain) {
        const response = await axios.get(this.baseUrl + '/branding', {
            params: {
                domain,
            },
        });
        // Make this call behave more like the others
        if (response.data.error) {
            throw response.data.error;
        }
        return response;
    }

    async getDataFromSessionsReport(business, path) {

        const response = await axios.post(`${this.xApiBaseUrl}${path}`, {
                business: {
                    geo: {
                        latitude: business.coordinates.longitude,
                        longitude: business.coordinates.latitude,
                    },
                    website_url: business.website,
                },
            },
            {
                headers: {
                    'x-api-key': this.xApiKey,
                },
            });
        return response.data;
    }

    getLeads(startDate = null, endDate = null, paginationOptions = null, searchQuery = null, id) {
        const apiQuery = new ApiQuery('dealers/' + id + '/stored_leads');
        apiQuery.withRelationships(
            'stored_leads.lead_platform',
            'stored_leads.vehicle',
            'stored_leads.call_recording',
        );

        if (paginationOptions != null) {
            if (Number.isInteger(paginationOptions)) {
                apiQuery.setPageNumber(paginationOptions);
                apiQuery.setPageSize(25);
            } else {
                apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
                apiQuery.setPageSize(25);
            }
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        if (startDate != null) {
            apiQuery.withScope(
                'stored_leads.date_within_range', {
                    'start_date': startDate,
                    'end_date': endDate,
                },
            );
        }

        return apiQuery.get();
    }

    getLeadsNoDateRange(paginationOptions, searchQuery, id) {
        return (
            (new ApiQuery('dealers/' + id + '/stored_leads'))
                .setPaginationOptionsFromVuetifyDataTable(paginationOptions)
                .setSearchQuery(searchQuery)
                .withRelationships(
                    'stored_leads.dealer',
                    'stored_leads.call_recording',
                )
                .get()
        );
    }

    getLead(id) {
        return (
            (new ApiQuery('stored_leads/' + id))
                .withRelationships(
                    'stored_leads.dealer',
                    'stored_leads.call_recording',
                )
                .get()
        );
    }

    createLead(data) {
        return (
            (new HTTP).post('/stored_leads', data)
        );
    }

    getUsers(paginationOptions = null, searchQuery = null) {
        const apiQuery = new ApiQuery('users');

        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        } else {
            apiQuery.setPageSize(1000);
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        return apiQuery.get();
    }

    getUser(id) {
        return (
            (new ApiQuery('users/' + id))
                .get()
        );
    }

    getRdiTypes() {
        return (
            (new ApiQuery('remote_dealer_identifier_types'))
                .get()
        );

    }

    getFeedProviders(isPublic = false, paginationOptions = null, searchQuery = null) {
        const apiQuery = new ApiQuery('feed_providers');

        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        } else {
            apiQuery.setPageSize(1000);
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        apiQuery.setSortDirection('asc');
        apiQuery.setSortField('vehicle_data_sources.name');

        if (isPublic) {
            apiQuery.withScope('feed_providers.is_public');
        }

        return apiQuery.get();
    }

    getSiteProviders(isPublic = false, paginationOptions = null, searchQuery = null) {
        const apiQuery = new ApiQuery('site_providers');

        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        } else {
            apiQuery.setPageSize(1000);
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        apiQuery.withFields(
            'site_providers.vdp_url_scraper_class_name',
        );

        apiQuery.setSortDirection('asc');
        apiQuery.setSortField('site_providers.name');

        if (isPublic) {
            apiQuery.withScope('site_providers.is_public');
        }

        return apiQuery.get();
    }

    getSiteProvider(id) {
        return (
            (new ApiQuery('site_providers/' + id)).get()
        );
    }

    getDealerInventory(dealerId, pageNumber = 1, pageSize = 25) {
        const apiQuery = new ApiQuery(`dealers/${dealerId}/inventory`);
        apiQuery.withRelationships(
            'vehicles.dealer_vehicle',
            'vehicles.images',
        );
        apiQuery.setPageNumber(pageNumber);
        apiQuery.setPageSize(pageSize);
        return apiQuery.get();
    }

    getInventory(startDate = null, endDate = null, paginationOptions = null, searchQuery = null, id) {
        const apiQuery = new ApiQuery('dealers/' + id + '/inventory');
        apiQuery.withRelationships(
            'vehicles.dealer_vehicle',
            //'vehicles.images',
        );

        if (paginationOptions != null) {
            if (Number.isInteger(paginationOptions)) {
                apiQuery.setPageNumber(paginationOptions);
                apiQuery.setPageSize(25);
            } else {
                apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
            }
        }

        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }

        if (startDate != null) {
            apiQuery.withScope(
                'vehicles.date_within_range', {
                    'start_date': startDate,
                    'end_date': endDate,
                },
            );
        }

        apiQuery.withScope(
            'vehicles.include_in_marketplace',
        );

        return apiQuery.get();
    }

    async getInventoryVehicles(data) {
        return (new HTTP).post('/vehicles/search', data);
    }

    async getInventoryProcesses(dealerId) {
        const inventoryProcesses = await (new HTTP).get(`dealers/${dealerId}/inventory-processes`, { limit: 50 });
        return inventoryProcesses;
    }

    async activateRdi(dealerId, feedProviderId, data) {
        await (new HTTP).post(`dealers/${dealerId}/remote_dealer_identifiers/${feedProviderId}/activate`, data);
    }

    async createRdi(dealerId, data) {
        const createdRdi = await (new HTTP).post(`dealers/${dealerId}/remote_dealer_identifiers/create`, data);
        return createdRdi;
    }

    async deleteRdi(dealerId, rdiId) {
        const deletedRdi = await (new HTTP).delete(`dealers/${dealerId}/remote_dealer_identifiers/${rdiId}`);
        return deletedRdi;
    }

    /**
     * Retrieves random inventory for a given dealer
     *
     * @param {string|number} dealerId
     * @param {number} limit
     * @returns
     */
    async getRandomInventory(dealerId, limit = 3) {
        const response = await (new HTTP).post(`dealers/${dealerId}/vehicles/search`, {
            query: {
                bool: {
                    must: [
                        {
                            function_score: {
                                random_score: {
                                    field: '_seq_no',
                                },
                            },
                        },
                    ],
                },
            },
            size: limit,
        });

        return response.data.hits.hits.map(hit => hit._source);

    }

    processDealerInventory(dealerId, rdiId, data = {}) {
        return (
            (new HTTP).post(`dealers/${dealerId}/remote_dealer_identifiers/${rdiId}/process-inventory`, data)
        );
    }

    getVehicle(id) {
        return (
            (new ApiQuery('vehicles/' + id))
                .withRelationships(
                    'vehicles.dealers',
                )
                .get()
        );
    }

    getPlatforms() {
        return (
            (new ApiQuery('platforms'))
                .withRelationships(
                    'platforms.products',
                )
                .get()
        );

    }

    getPlatformAuthStatus(agencyId, platformName) {
        return (
            (new HTTP)
                .get('agencies/' + agencyId + '/platforms/' + platformName + '/authenticate')
        );
    }

    getPlatformAuthUrl(agencyId, platformName, uiRedirectUrl) {
        return (
            (new HTTP)
                .get('agencies/' + agencyId + '/platforms/' + platformName + '/authurl', {
                    uiRedirectUrl,
                })
        );
    }

    getProducts() {
        return (
            (new ApiQuery('products'))
                .get()
        );
    }

    getProduct(id) {
        return (
            (new ApiQuery('products/' + id)).get()
        );
    }

    getProductStatus(dealerId, productId) {
        const data = {
            product_id: productId,
        };
        return (
            (new HTTP).post(`/dealers/${dealerId}/validate/product`, data)
        );
    }

    getFeatureStatus(dealerId, feature, params = {}) {
        return (
            (new HTTP).post(`/dealers/${dealerId}/validate/${feature}/status`, params)
        );
    }

    getDealerProducts(id) {
        return (
            (new ApiQuery('dealers', id))
                .withRelationships(
                    'dealers.products',
                )
                .get()
        );
    }

    getDNSRecords(dealerId) {
        return (
            (new HTTP).get(`/dealers/${dealerId}/validate/dns-records`)
        );
    }

    setupEventFlowPlus(dealerId) {
        return (
            (new HTTP).post(`/dealers/${dealerId}/features/event_flow_plus/setup`)
        );
    }

    getPrivacyBanners() {
        return (
            (new ApiQuery('/privacy_banners')).get()
        );
    }

    getDealerPrivacyBanners() {
        return (
            (new ApiQuery('/dealer_privacy_banners'))
                .withRelationships(
                    'dealer_privacy_banners.dealer',
                    'dealer_privacy_banners.legal_agreement',
                    'dealer_privacy_banners.privacy_banner',
                )
                .get()
        );
    }

    savePrivacyBanner(data) {
        return (new HTTP).post('dealer_privacy_banners', data);
    }

    deletePrivacyBanner(id) {
        return (new HTTP).delete('dealer_privacy_banners/' + id);
    }

    async getDealerFeatures(id) {

        const response = await this.getDealerProducts(id);

        const dealer = response.data?.data;

        const dealerProducts = get(dealer, 'products.data', null) || [];

        // Collect all features
        let features = [];
        dealerProducts.forEach(product => {
            const productFeatures = product.features?.data || [];
            features = [...features, ...productFeatures];
        });

        // Return unique features by ID
        return uniqBy(features, 'id');
    }


    async getDealerFeatureStatus(id) {
        // Get the latest list of features for the given dealer
        const dealerFeatures = await this.getDealerFeatures(id);

        // Retrieve the status for all dealer features at once
        const featureStatusPromises = dealerFeatures.map(feature => {
            return this.getFeatureStatus(id, feature);
        });


        return Promise.all(featureStatusPromises);
    }


    async getCompletedDealerFeatures(id) {

        const featureStatus = await this.getDealerFeatureStatus(id);

        // Find completed features
        return featureStatus
            .filter(feature => feature.status === 'complete')
            .map(feature => feature.feature);
    }


    getDealerProduct(id) {
        return (
            (new ApiQuery('dealer_products', id))
                .withRelationships(
                    'dealer_products.product',
                    'dealer_products.dealer',
                )
                .get()
        );
    }

    createDealerProduct(dealerId, productId, configuration = {}) {
        return (new HTTP).post('dealer_products', {
            dealer_id: dealerId,
            product_id: productId,
            dealer_product_configuration: configuration,
        });
    }

    deleteDealerProduct(id) {
        return (new HTTP).delete('dealer_products/' + id);
    }

    getDealerPlays(id) {
        return (
            (new ApiQuery(`dealers/${id}/dealer_plays`))
                .withRelationships('dealer_play.play')
                .get()
        );
    }

    getDealer(id) {
        return (new ApiQuery('dealers', id)).get();
    }

    getDealerByUuid(uuid) {
        axios.defaults.headers.common['BB-Token'] = process.env.VUE_APP_BB_TOKEN;

        return axios.get(`${this.baseUrl}/dealers/${uuid}`);
    }

    async getFullDealer(id, userIsClient = false) {

        let relationships = [
            'dealers.site_provider',
            'dealers.products',
            'dealers.platforms',
            'dealers.contacts',
            'dealers.lead_destinations',
            'dealers.vehicle_data_sources',
            'dealers.remote_dealer_identifiers',
            'dealers.remote_files',
            'remote_dealer_identifiers.vehicle_data_source',
            'dealers.dealer_upgrades',
            'dealers.agency',
            'dealers.users',
            'agencies.marketplace_catalogs',
            'users.roles',
            'dealers.twilio_lead_sources',
            'agencies.business_managers',
            'dealers.facebook_ad_account_annotations',
            'dealers.ad_accounts',
            'dealers.identities',
            'dealers.facebook_pixel_annotations',
            'dealers.pixels',
            'dealers.facebook_product_catalog_annotations',
            'dealers.catalogs',
            'dealers.facebook_page_annotations',
            'dealers.facebook_offline_event_set_annotations',
            'dealers.dealer_product_onboarding',
            'dealers.timezone',
            'dealers.vertical',
            'dealers.conversion_endpoints',
            //'dealers.vertical',
        ];


        if (!userIsClient) {
            relationships = relationships.concat([
                'dealer_product_onboarding.play',
                'dealer_product_onboarding.products',
                'dealer_product_onboarding.user',
            ]);
        }

        return (
            (new ApiQuery('dealers', id))
                .withFields(
                    'dealers.default_remote_filename',
                    'lead_destinations.lead_sender_class_name',
                    'lead_destinations.lead_sender_configuration',
                    'contacts.first_name',
                    'contacts.last_name',
                )
                .withRelationships(...relationships)
                .withRelationshipCounts(
                    'dealers.vehicles',
                    'dealers.completed_onboardings',
                )
                .get()
        );
    }

    getDealerCallTracking(id) {
        return (
            (new ApiQuery('dealers', id))
                .withRelationships(
                    'dealers.twilio_lead_sources',
                    'dealers.products',
                    'dealers.agency',
                )
                .get()
        );
    }


    getDealerProductOnboarding(id) {

        const apiQuery = new ApiQuery('dealer_product_onboarding', id);

        apiQuery.withRelationships(
            'dealer_product_onboarding.dealer',
            'dealer_product_onboarding.play',
            'dealer_product_onboarding.products',
            'dealer_product_onboarding.user',
        );

        return apiQuery.get();
    }

    createDealerProductOnboarding(data) {
        return (
            (new HTTP).post('dealer_product_onboarding', data)
        );
    }

    createDealerProductOnboardingProduct(data) {
        return (
            (new HTTP).post('dealer_product_onboarding_product', data)
        );
    }

    updateDealerProductOnboarding(id, configuration) {
        return (
            (new HTTP).patch('dealer_product_onboarding/' + id, {
                configuration,
            })
        );
    }

    deleteDealerProductOnboarding(id) {
        return (
            (new HTTP).delete('dealer_product_onboarding/' + id)
        );
    }

    completeDealerProductOnboarding(id) {
        return (
            (new HTTP).post(`dealer_product_onboarding/${id}/complete`)
        );
    }

    async fetchFbSwapTokenUrl() {
        const fbLoginUrl = await axios.get(`${this.baseUrl}/fb-login?swap=true`);
        if (fbLoginUrl.data && fbLoginUrl.data.loginUrl) {
            return fbLoginUrl.data.loginUrl;
        }
    }

    async setFbBmAndToken(data) {
        return await (new HTTP()).post('/agencies/set-facebook-token', data);
    }

    async getFacebookBusinessManagers({ token }) {
        return await axios.post(this.baseUrl + '/agency-onboarding/business-managers', {
            token,
        });
    }

    async getPlatformBusinessManagerDetails({ agency_id, platform }) {
        return await (new HTTP()).get(`/agencies/${agency_id}/platforms/${platform}/business-manager-details`);
    }

    loginUser({ email, password, trustedDeviceToken }) {

        // This call points directly to the local laravel instance
        const requestData = {
            username: email,
            password,
            trustedDeviceToken,
        };

        return axios.post(`${this.baseUrl}/api/login`, requestData);
    }

    logout(email) {
        return axios.post(`${this.baseUrl}/api/logout`, { email });
    }

    async enableTwoFa({ email, password }) {
        const requestData = {
            force: true,
            username: email,
            password,
        };

        try {
            return await axios.post(`${this.baseUrl}/api/v1/user/login/two-factor-authentication`, requestData);
        } catch (error) {
            return error;
        }
    }

    twoFaChallenge({ email, password, code, rememberDevice }) {
        const requestData = {
            username: email,
            password,
            code,
            rememberDevice,
        };

        try {
            return axios.post(`${this.baseUrl}/api/v1/two-factor-challenge`, requestData);
        } catch (error) {
            return error;
        }
    }

    resetPassword(userId, token, newPassword, passwordSetupType, recaptchaToken, enforcedReset) {
        // Use axois directly since this call uses a different url syntax and doesn't require a token
        const url = `${this.baseUrl}/reset_password`;
        return axios.post(url, {
            user_id: userId,
            token,
            password: newPassword,
            password_setup_type: passwordSetupType,
            recaptchaToken,
            enforcedReset,
        });
    }

    sendPasswordReset(email, enforcedReset = false) {
        // Use axois directly since this call uses a different url syntax and doesn't require a token
        const url = `${this.baseUrl}/send_password_reset`;
        return axios.post(url, {
            email,
            enforcedReset
        });
    }

    getIsPasswordResetValid(userId, token) {
        // Use axois directly since this call uses a different url syntax and doesn't require a token
        const url = `${this.baseUrl}/is_password_reset_valid`;
        return axios.post(url, {
            user_id: userId,
            token,
        });
    }

    getCurrentUser() {
        return (
            (new ApiQuery('users/current'))
                .withRelationships(
                    'users.roles',
                    'roles.permissions',
                    'users.dealers',
                    'users.agency',
                    'dealers.products',
                    'dealers.agency',
                )
                .withRelationships(
                    'dealers.remote_dealer_identifiers',
                    'remote_dealer_identifiers.vehicle_data_source',
                )
                .withRelationshipCounts(
                    'dealers.vehicles',
                )
                .get()
        );
    }

    getSpecificUsers(roles, agencyId) {
        return (
            (new ApiQuery('users'))
                .withRelationships(
                    'users.roles',
                )
                .withScope('users.roles_in', {
                    names: roles,
                })
                .withScope('users.agency_equals', {
                    id: agencyId,
                })
                .setPageSize(1000)
                .get()
        );
    }

    async updateUser(data) {
        return (
            (new HTTP).put('users/update/', data)
        );
    }

    getCurrentUserDealers() {
        return (
            (new ApiQuery('users/current/dealers'))
                .withRelationshipCounts(
                    'dealers.vehicles',
                )
                .withRelationships(
                    'dealers.remote_dealer_identifiers',
                    'remote_dealer_identifiers.vehicle_data_source',
                )
                .withScope('dealers.has_analytics')
                .get()
        );
    }

    getThrivecartOnboardingUser() {
        return (
            (new ApiQuery('users/current'))
                .withRelationships(
                    'users.thrivecart_onboarding_data',
                )
                .get()
        );
    }

    getUserDealers(userId) {
        return (
            (new ApiQuery('users/' + userId + '/dealers'))
                .withRelationshipCounts(
                    'dealers.vehicles',
                )
                .withRelationships(
                    'dealers.remote_dealer_identifiers',
                    'remote_dealer_identifiers.vehicle_data_source',
                )
                .get()
        );
    }

    getDealerPickerInitialDealers(agencyId = null) {

        const apiQuery = new ApiQuery('dealers');

        apiQuery
            .withRelationships(
                'dealers.agency',
                'dealers.products',
            )
            .setPageSize(25);

        if (agencyId != null) {
            apiQuery.withScope('dealers.agency_equals', {
                id: agencyId,
            });
        }

        return apiQuery.get();
    }

    getDealerPickerSearchDealers(searchQuery, agencyId = null) {

        const apiQuery = new ApiQuery('dealers');

        apiQuery
            .withRelationships('dealers.agency')
            .setSearchQuery(searchQuery)
            .setPageSize(25)
            .setSortDirection('asc')
            .setSortField('dealers.name');

        if (agencyId != null) {
            apiQuery.withScope('dealers.agency_equals', {
                id: agencyId,
            });
        }

        return apiQuery.get();
    }

    getFacebookPage(id) {
        return (
            (new HTTP)
                .getFromApi('dealers/' + id + '/facebook-pages')
        );
    }

    detectPageAccess(id) {
        return (
            (new HTTP)
                .post('dealers/' + id + '/detect-page-access')
        );
    }

    getVehicles(startDate, endDate, id) {
        return (
            (new HTTP)
                .getFromApi('dealers/' + id + '/reports/ads-performance-metrics-vehicles', {
                    start_date: startDate,
                    end_date: endDate,
                })
        );
    }

    getCallTrackingNumbers(area_code, country) {
        return (
            (new HTTP)
                .getFromApi('call-tracking-numbers', {
                    area_code,
                    country,
                })
        );
    }

    getRDIList(dealerId) {
        return (
            (new HTTP)
                .getFromApi('dealers/' + dealerId + '/inventory/rdi/list')
        );
    }

    getCSVResponse(dealerId, rdiId) {
        return (
            (new HTTP)
                .getFromApi(`dealers/${dealerId}/inventory/rdi/${rdiId}.csv`)
        );
    }


    async getFacebookLocation(address) {
        const response = await (new HTTP).get('/geocode', {
            q: address,
        });

        if (!response.data.items.length) {
            throw 'No Facebook geocoding results found';
        }

        const location = response.data.items[0];

        // Create an array with the street parts so it can be joined downstream
        // we have to do this because we can't always rely on both
        const streetParts = [];
        const streetNumber = get(location, 'address.houseNumber');
        if (streetNumber) {
            streetParts.push(streetNumber);
        }
        const route = get(location, 'address.street');
        if (route) {
            streetParts.push(route);
        }

        // Add the result back into the return for external use
        location.address.formattedStreet = streetParts.join(' ');

        return location;
    }

    getTicketCustomFields() {
        return (
            (new ApiQuery('tickets/customfields'))
                .setPageSize(1000)
                .get()
        );
    }

    getPlays() {
        return (
            (new ApiQuery('plays'))
                .setPageSize(1000)
                .get()
        );
    }

    async getAds() {
        return (
            (new ApiQuery('ads'))
                .withRelationships('ads.playbooks')
                .setPageSize(1000)
                .get()
        );
    }

    async createAd(data) {
        return (
            (new HTTP).post('ads', data)
        );
    }

    async deleteAd(data) {
        return (
            (new HTTP).delete(`ads/${data.id}`)
        );
    }

    async editAd(data) {
        return (
            (new HTTP).put(`ads/${data.id}`, data)
        );
    }

    async deployAdToPlatform(dealer_id, platform_id, ad_data) {
        return (
            (new HTTP).post(`dealers/${dealer_id}/platforms/${platform_id}/ads/build`, ad_data)
        );
    }

    async getAdPlaybooks() {
        return (
            (new ApiQuery('ad_playbooks'))
                .setPageSize(1000)
                .get()
        );
    }

    createAdPlaybook(data) {
        return (
            (new HTTP).post('/ad_playbooks', data)
        );
    }

    deleteAdPlaybook(id) {
        return (
            (new HTTP).delete('/ad_playbooks/' + id)
        );
    }

    async getPlaybooks(search = '', filters = [], pageSize = 1000, page = null, aggregations = [], sortAggregations = null) {
        try {
            const params = {
                page_size: pageSize,
                filters,
                q: search,
                aggregations,
                sort_aggregations: sortAggregations
            };

            if (page != null) {
                params.page = page;
            }

            return (new HTTP).post('playbooks/search', params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (err) {
            console.log(err);
        }
    }

    getCustomPlays() {
        return (
            (new ApiQuery('plays'))
                .withScope('plays.is_custom')
                .setPageSize(1000)
                .get()
        );
    }

    getCustomPlaybooks() {
        return (
            (new ApiQuery('playbooks'))
                .withRelationships('playbooks.plays')
                .withScope('playbooks.is_custom')
                .setPageSize(1000)
                .get()
        );
    }

    createAdDeployment(data) {
        return (
            (new HTTP).post('/ad_deployments', data)
        );
    }

    retryDealerPlayDeployment(dealerId, dealerPlayId) {
        return (
            (new HTTP).post(`dealers/${dealerId}/dealer_play/${dealerPlayId}/retry`)
        );
    }

    getAdDeployment(id) {
        return (
            (new ApiQuery(`ad_deployments/${id}`))
                .withRelationships(
                    'ad_deployments.dealer_plays',
                    'ad_deployments.user',
                    'dealer_play.platform',
                )
                .withFields('ad_deployments.configuration')
                .get()
        );
    }

    deleteAdDeployment(id) {
        return (new HTTP).delete(`/ad_deployments/${id}`);
    }

    updateAdDeployment({ id, data }) {
        return (
            (new HTTP).patch(`/ad_deployments/${id}`, { configuration: data })
        );
    }

    getAdDeployments({ dealerId, pageSize = 1000, page = 1 }) {
        return (
            (new ApiQuery('ad_deployments'))
                .withScope('ad_deployments.where', [{
                    column: 'dealer_id',
                    value: dealerId,
                }])
                .withRelationships(
                    'ad_deployments.dealer_plays',
                    'ad_deployments.user',
                    'dealer_play.platform',
                )
                .withFields('ad_deployments.ads', 'ad_deployments.platforms')
                .setPageSize(pageSize)
                .setPageNumber(page)
                .get()
        );
    }

    getPlay(id) {
        return (
            (new ApiQuery('plays/' + id)).get()
        );
    }

    getPlayStatus(dealerId, playId) {
        const data = {
            play_id: playId,
        };
        return (
            (new HTTP).post(`/dealers/${dealerId}/validate/play`, data)
        );
    }

    getPlayCampaigns(playId) {
        return (
            (new HTTP).get(`plays/${playId}/campaigns`)
        );
    }

    async upgradeToEventFlow(dealerId) {
        return (
            await (new HTTP).post(`/dealers/${dealerId}/gtm-to-eventflow`)
        );
    }

    async updateDealerConfiguration(dealerId, configuration) {
        // Since this call will update the entire object make sure our data is fresh
        const response = await (new HTTP).get(`/dealers/${dealerId}`);
        const dealer = response.data.data;

        // Retrieve the configuration and merge it in with our requested update
        const currentConfiguration = dealer.configuration || {};
        configuration = { ...currentConfiguration, ...configuration };

        // Apply the update to the server
        return (new HTTP).patch(`/dealers/${dealerId}`, { configuration });
    }

    async createDealerAdAccount(id, platformId, configuration) {
        const response = await new ApiQuery('dealers/' + id).withRelationships('dealers.ad_accounts').get();
        const dealerAdAccounts = response.data.data.ad_accounts.data;
        const existingAdAccount = dealerAdAccounts.filter(adAccount => adAccount.platform_id == platformId);
        if (existingAdAccount.length) {
            return (new HTTP).patch(`/ad_accounts/${existingAdAccount[0].id}`, configuration);
        }
        return (new HTTP).post('/ad_accounts', configuration);
    }

    async updateAssetDesign(assetId, updatedData) {
        return (new HTTP).patch(`/assets/${assetId}`, updatedData);
    }

    async removeUploadedAsset(assetId) {
        return (new HTTP).delete(`/assets/${assetId}`);
    }

    async updateDealerAddress(dealerId, address) {
        // Since this call will update the entire object make sure our data is fresh
        const response = await (new HTTP).get(`/dealers/${dealerId}`);
        const dealer = response.data.data;

        const currentAddress = (({ address, city, state, zip, country_code, latitude, longitude }) => ({
            address,
            city,
            state,
            zip,
            country_code,
            latitude,
            longitude,
        }))(dealer);

        // Merge the updated fields with the current dealer
        address = { ...currentAddress, ...address };

        // Make the requested updates
        return await (new HTTP).patch(`/dealers/${dealerId}`, { ...address });
    }

    /**
     * Retrieves website inventory from the server
     *
     * @param {object} params
     * @param {string} params.website The target website to retrieve inventory for
     * @param {number} params.size The size of the results
     * @param {number} params.from The offset
     * @param {array<{ [field: string]: string }>} params.sort The fields to sort by
     * Example:
     * ```json
     * "sort" : [
     *   {"vin": "desc"},
     *   {"year": "asc"}
     * ]
     * ```
     *
     * @returns {AxiosPromise<any>}
     */
    getWebsiteInventory(params) {

        const defaults = {
            size: 50,
            from: 0,
        };

        return (
            (new HTTP).post('/website-inventory/search', {
                ...defaults,
                ...params,
            })
        );
    }

    /**
     * Retrieves website inventory counts
     *
     * @param {string} website The website to retrieve an overview for
     * @returns {AxiosPromise<any>}
     */
    getWebsiteInventoryCounts(website) {
        return (
            (new HTTP).post('/website-inventory/overview', {
                website,
            })
        );
    }

    async checkWebsiteInventorySupport(website) {
        try {
            // This call returns a 500 if there's no inventory
            const response = await this.getWebsiteInventoryCounts(website);

            if (response.data?.count > 0 && response.data?.dealer_id) {
                return response.data;
            }

            throw new Error('Website is supported but there is no inventory or some other error occurred');

        } catch (error) {
            console.log(`
                Website inventory not supported or some
                other error occurred:`,
                error.response ?? error,
            );
            return false;
        }
    }

    async getUserLocation() {
        const response = await axios.get('https://api.ipstack.com/check', {
            params: {
                access_key: process.env.VUE_APP_IP_STACK_KEY,
            },
        });
        // Make this call behave more like the others
        if (response.data.error) {
            throw response.data.error;
        }
        return response;
    }

    getLegalAgreements() {
        return (
            (new ApiQuery('legal_agreements'))
                .get()
        );
    }

    async getLegalAgreementByNameServerSide(name) {
        return axios.get(`${this.baseUrl}/legal_agreements/${name}`);
    }

    async getLegalAgreement(name) {
        const response = await this.getLegalAgreements();
        const agreements = response.data.data;
        const agreement = agreements.find(agreement => {
            return agreement.name === name;
        });
        if (!agreement) {
            throw 'Agreement not found';
        }
        return agreement;
    }

    getTimezones() {
        return (
            (new ApiQuery('timezones'))
                .setPageSize(1000)
                .get()
        );
    }

    async updateDealerFeatureConfiguration(dealerFeatureId, configuration) {
        // Since this call will update the entire object make sure our data is fresh
        const response = await (new HTTP).get(`/dealer_feature/${dealerFeatureId}`);
        const dealerFeature = response.data.data;

        // Retrieve the configuration and merge it in with our requested update
        const currentConfiguration = dealerFeature.configuration || {};
        configuration = { ...currentConfiguration, ...configuration };

        // Apply the update to the server
        return await (new HTTP).patch(`/dealer_feature/${dealerFeatureId}`, { configuration });
    }

    getDesignatedMarketingAreas() {
        return (
            (new ApiQuery('designated_marketing_areas'))
                .setPageSize(500)
                .get()
        );
    }

    getDealerMarketplaceTasksSummary(dealerId) {
        return (new HTTP).get(`/dealers/${dealerId}/facebook-marketplace-tasks/summary`);
    }

    getDealerMarketplaceTasks(dealerId) {
        const apiQuery = new ApiQuery(`dealers/${dealerId}/facebook-marketplace-tasks`);
        return apiQuery.get();
    }

    getAgencyMarketplaceTasksSummary(agencyId) {
        return (new HTTP).get(`/agencies/${agencyId}/facebook-marketplace-tasks/summary`);
    }

    getAgencyMarketplaceTasks(agencyId) {
        const apiQuery = new ApiQuery(`agencies/${agencyId}/facebook-marketplace-tasks`);
        return apiQuery.get();
    }

    uploadMediaAsset(data) {
        return (new HTTP).post('/assets/upload', data, true);
    }

    createAsset(data) {
        return (new HTTP).post('/assets/create', data);
    }

    transformAsset(data) {
        return (new HTTP).post('/assets/transform', data);
    }

    getVerticals() {
        return (new HTTP).get('/verticals');
    }

    async updateOrganizationConfiguration(organizationId, configuration) {
        const { data } = await (new HTTP).put(`/agencies/${organizationId}/configuration`, configuration);
        return data;
    }

    async disableSingleCallTrackingNumber(dealerId, trackingNumber) {
        return (new HTTP).delete(`/dealers/${dealerId}/call-tracking`, {
            trackingNumber,
        });
    }

    /**
     * Retrieves a Google place by its ID
     *
     * @param {number|string} placeId The Place ID
     * @returns
     */
    getGooglePlace(placeId) {
        return (new HTTP).get('/google/maps/place/details/json', {
            place_id: placeId,
        });
    }

    /**
     * Creates a an identity record for the target platform and dealer
     *
     * @param {number|string} dealerId The current dealer ID
     * @param {number|string} platformId The target platform ID
     * @param {number|string} externalId The external identity ID
     * @returns
     */
    createIdentity(dealerId, platformId, externalId) {
        return (new HTTP).post('/identities', {
            dealer_id: dealerId,
            platform_id: platformId,
            external_identity_id: externalId,
        });
    }

    /**
     * Removes an identity record by its ID
     *
     * @param {number|string} id The ID of the identity record
     * @returns
     */
    deleteIdentity(id) {
        return (new HTTP).delete(`/identities/${id}`);
    }

    /**
     * Sends request to the location API to search by zip code
     *
     * @param {number} miles The radius to search
     * @param {number} lat The latatude
     * @param {number} lng The longitude
     * @returns {array} The response from the API
     */
    async searchPostalCodes(miles = 20, lat, lng) {
        try {
            const params = {
                'filters': [
                    {
                        'geo_shape': {
                            'geometry': {
                                'shape': {
                                    'type': 'circle',
                                    'radius': `${miles}mi`,
                                    'coordinates': [lng, lat],
                                },
                            },
                        },
                    },
                ],
            };

            const response = await axios.post(`${this.locationApiBaseUrl}/search-postal-code`, params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.data.locations.map(location => (formatToBuyerBridgeLocation(location, POSTAL_CODE)));
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Sends request to the location API to search geo location lat, lng by DMA
     *
     * @param {number} miles The radius to search
     * @param {number} lat The latitude
     * @param {number} lng The longitude
     * @returns {array} The response from the API
     */
    async searchDmaByGeo(miles = 20, lat, lng) {
        try {
            const params = {
                'filters': [
                    {
                        'geo_shape': {
                            'geometry': {
                                'shape': {
                                    'type': 'circle',
                                    'radius': `${miles}mi`,
                                    'coordinates': [lng, lat],
                                },
                            },
                        },
                    },
                ],
            };

            const response = await axios.post(`${this.locationApiBaseUrl}/search-dma`, params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.data.locations.map(location => (formatToBuyerBridgeLocation(location, DMA)));
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Sends request to the location API to search by name for DMAs
     *
     * @param {string} name The name to search
     * @returns {array} The response from the API
     */
    async searchDmaByName(name) {
        try {
            const params = {
                'search': name,
            };

            const response = await axios.post(`${this.locationApiBaseUrl}/search-dma`, params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.data.locations.map(location => (formatToBuyerBridgeLocation(location, DMA)));
        } catch (error) {
            console.log(error);
        }

    }

    /**
     * Sends request to the location API to search for postal codes
     *
     * @param {string} postalCode The postal code to search
     * @returns {array} The response from the API
     */
    async searchPostalCodeByName(postalCode) {
        try {
            const params = {
                'search': postalCode,
            };

            const response = await axios.post(`${this.locationApiBaseUrl}/search-postal-code`, params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.data.locations.map(location => (formatToBuyerBridgeLocation(location, POSTAL_CODE)));
        } catch (error) {
            console.log(error);
        }

    }

    /**
     * Sends request to the location API to search by address
     *
     * @param {object} address The address to search
     * @returns {array} The response from the API
     */
    async searchAddress(address) {
        try {
            const params = {
                'search': address,
            };

            const response = await axios.post(`${this.locationApiBaseUrl}/search-address`, params, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            return response.data.locations.map((location) => (formatToBuyerBridgeLocation(location, GEO)));
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Gets all product groups on a certain platform for a dealer
     * @param dealerId
     * @param platform
     * @returns {Promise<*>}
     */
    async getDealerProductGroups(dealerId, platform) {
        const { data } = await (new HTTP).get(`/dealers/${dealerId}/platforms/${platform}/product-groups`);

        return data;
    }

    /**
     *
     * @param dealerId
     * @param useHistory
     * @param useTemplate
     * @param content
     * @param service
     * @param serviceId
     * @returns {Promise<void>}
     */
    async openApiChat({ dealerId, useHistory, useTemplate, content, service, serviceId, platform }) {
        const { data } = await (new HTTP).post(`/dealers/${dealerId}/open-ai/chat`, {
            use_history: useHistory,
            use_template: useTemplate,
            content,
            service,
            service_id: serviceId,
            platform,
        });

        return data;
    }

    /**
     * Elasticsearch query to get presets from an agency
     *
     * @param agencyId
     * @param q
     * @returns {Promise<*>}
     */
    async getPresets({ agencyId, q = '' }) {
        const { data } = await (new HTTP).post('/presets/search', {
            agency_id: agencyId,
            q,
        });

        return data;
    }

    // account management endpoints
    async createAccountGroup(data) {
        return (new HTTP).post('/account_groups', data);
    }

    async getAccountGroups(agencyId = null) {
        const apiQuery = new ApiQuery('account_groups');
        if (agencyId != null) {
            apiQuery.withScope('groups.agency_equals', {
                id: agencyId,
            });
        }
        return apiQuery.get();
    }

    async addToGroup(data) {
        return (new HTTP).post('/account_groups/share', data);
    }

    async getAccountGroupsQuery(paginationOptions = null, searchQuery = null, filters = null, agencyId = null, selectedGroups = null) {
        const apiQuery = new ApiQuery('account_groups');
        apiQuery.withRelationships(
            'groups.performance_manager',
            'groups.agency',
            'groups.dealers',
            'groups.created_by_user',
            'users.roles',
            'dealers.groups_shared_by_info',
            'dealer_groups.shared_by',
            'dealers.groups_shared_with_info',
        );
        if (paginationOptions != null) {
            apiQuery.setPaginationOptionsFromVuetifyDataTable(paginationOptions);
        }
        if (searchQuery != null) {
            apiQuery.setSearchQuery(searchQuery);
        }
        if (filters != null) {
            apiQuery.withFilters(filters);
        }
        if (agencyId != null) {
            apiQuery.withScope('groups.agency_equals', {
                id: agencyId,
            });
        }
        if (selectedGroups != null) {
            apiQuery.withScope('groups.groups_in', {
                ids: selectedGroups,
            });
        }
        return apiQuery.get();
    }

    async deleteGroup(groupId) {
        return (new HTTP).delete('/account_groups/' + groupId);
    }

    async updateGroup(groupId, data) {
        return (new HTTP).put(`/account_groups/${groupId}`, data);
    }

    async deleteAccount(data) {
        return (new HTTP).post('/account_groups/unshare/', data);
    }

    /**
     * Get data processor results for a dealer
     * @param dealerId
     * @returns {Promise<*>}
     */
    async getDataProcessorResults(dealerId) {
        return (
            (new ApiQuery('data_processors'))
                .withRelationships([
                    'data_processors.data_processor_destinations',
                    'data_processors.data_destinations',
                ])
                .withScope('data_processors.where', [{
                    column: 'dealer_id',
                    value: dealerId,
                }])
                .setSortDirection('asc')
                .setPageSize(1000)
                .get()
        );
    }

    async facebookGraphApiPost({ dealerId, node, payload }) {
        const { data } = await (new HTTP).post(`/dealers/${dealerId}/graph-api/${node}`, payload);

        return data;
    }

    async facebookGraphApiGet({ dealerId, node, payload }) {
        const { data } = await (new HTTP).get(`/dealers/${dealerId}/graph-api/${node}`, payload);

        return data;
    }

    async getFacebookOfflineEventSetUploads({ eventSetId }) {
        const { data } = await (new HTTP).get(`/facebook_offline_event_set_annotations/${eventSetId}/uploads`);

        return data;
    }

    async getAlerts(data) {
        const alerts = await (new HTTP).post('alerts/search', data);

        return alerts;
    }

    async getMonitors() {
        const scope = [
            {
                column: 'public',
                operator: '=',
                value: true
            },
            {
                column: 'schedule',
                operator: '!=',
                value: 'disabled'
            }
        ];

        const { data } = await new ApiQuery('monitors')
            .withScope('alert_types.where', scope)
            .withRelationships('alert_types')
            .setPageSize(50)
            .get();

        return data;
    }

    async getCounts(agencyId, selectedGroups) {
        const data = {
            agency_id: agencyId,
            filters: {
                group_ids: selectedGroups,
                dismissed: 'false'
            },
            aggregations: ['alert_type_id'],
            aggregations_query_size: 1000,
            page_size: 0
        };
        const counts = await (new HTTP).post('alerts/search', data);
        return counts;
    }

    async getAllAlertsCount(agencyId, selectedGroups) {
        const data = {
            agency_id: agencyId,
            filters: {
                group_ids: selectedGroups,
                dismissed: 'false'
            }
        };
        const count = await (new HTTP).post('alerts/search', data);
        return count;
    }

    async getDismissedAlertsCount(agencyId, selectedGroups) {
        const data = {
            agency_id: agencyId,
            filters: {
                group_ids: selectedGroups,
                dismissed: 'true'
            }
        };
        const count = await (new HTTP).post('alerts/search', data);
        return count;
    }

    async dismissAlert(alertId, data) {
        return (new HTTP).post(`alerts/${alertId}/dismiss`, data);
    }

    async enableAlert(alertId) {
        return (new HTTP).post(`alerts/${alertId}/enable`);
    }

    async getAlertTypes() {
        const { data } = await new ApiQuery('alert_types')
            .withScope('alert_types.where', [{
                column: 'public',
                operator: '=',
                value: true,
            }])
            .setPageSize(50)
            .get();

        return data;
    }

    async getPlatformTokenStatus(agencyId, platform) {
        return new HTTP().get(
            `agencies/${agencyId}/platforms/${platform}/get_token_status`
        );
    }
}

export default ApiRepository;
