<template>
    <styled-card class="mb-5 mt-4 mx-3 flex">
        <template #heading>
            Campaign Stats
        </template>
        <template #action-buttons>
            <action-button
                icon="download"
                @click="exportCSV()">
                Export CSV
            </action-button>
        </template>
        <loader v-if="metricsLoading" />
        <custom-table
            v-else
            id="campaign-stats-table"
            :key="tableKey"
            :class="{'with-comparison': comparisonVisible}"
            :items="currentCampaigns"
            :fields="fields"
            :loading="loading"
            hide-sort
            has-filters
            :header-margin="145"
            min-width="1200px"
            name="campaign-stats" />
    </styled-card>
</template>

<script>
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledCard from '@/components/globals/StyledCard';
import Loader from '@/components/globals/Loader';
import ActionButton from '@/components/globals/ActionButton';

import { mapState, mapGetters } from 'vuex';
import { ExportToCsv } from 'export-to-csv';

export default {
    name: 'CustomTableDemo',
    components: {
        CustomTable,
        StyledCard,
        Loader,
        ActionButton,
    },
    data() {
        return {
            reset: false,
            loading: false,
            tableKey: 1,
            fields: [
                {
                    id: 'campaign_name',
                    header: 'Name',
                    value: 'campaign_name.value',
                    align: 'left',
                    width: '180px',
                    sortable: false,
                    type: 'single',
                },
                {
                    id: 'effective_status',
                    header: 'Status',
                    value: 'effective_status',
                    align: 'center',
                    width: '100px',
                    filter: 'multiple',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/EffectiveStatus.vue'),
                    props: (item, value) => {
                        return {
                            item,
                            value
                        };
                    }
                },
                {
                    id: 'reach',
                    header: {
                        top: 'Reach',
                        bottom: 'Spend',
                    },
                    value: '',
                    align: 'right',
                    width: '150px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'reach',
                        metricSecond: 'spend',
                    }
                },
                {
                    id: 'impressions',
                    header: {
                        top: 'Impressions',
                        bottom: 'CPM',
                    },
                    value: '',
                    align: 'right',
                    width: '160px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'impressions',
                        metricSecond: 'cpm',
                        metricSecondReversed: true
                    }
                },
                {
                    id: 'clicks',
                    header: {
                        top: 'Clicks',
                        bottom: 'CPC',
                    },
                    value: '',
                    align: 'right',
                    width: '140px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'clicks',
                        metricSecond: 'cost_per_click',
                        metricSecondReversed: true
                    }
                },
                {
                    id: 'outbound_clicks',
                    header: {
                        top: 'Outbound Clicks',
                        bottom: 'CPOC',
                    },
                    value: '',
                    align: 'right',
                    width: '175px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'outbound_clicks',
                        metricSecond: 'cost_per_outbound_click',
                        metricSecondReversed: true
                    }
                },
                {
                    id: 'unique_outbound_clicks',
                    header: {
                        top: 'Unique Outbound',
                        bottom: 'CPUOC',
                    },
                    value: '',
                    align: 'right',
                    width: '185px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'unique_outbound_clicks',
                        metricSecond: 'cost_per_unique_outbound_click',
                        metricSecondReversed: true
                    }
                },
                {
                    id: 'landing_page_views',
                    header: {
                        top: 'Landing Page Views',
                        bottom: 'CPLPV',
                    },
                    value: '',
                    align: 'right',
                    width: '195px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'landing_page_views',
                        metricSecond: 'cost_per_landing_page_view',
                        metricSecondReversed: true
                    }
                },
                {
                    id: 'leads',
                    header: {
                        top: 'Total Leads',
                        bottom: 'CPL',
                    },
                    value: '',
                    align: 'right',
                    width: '150px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'leads',
                        metricSecond: 'cost_per_lead',
                        metricSecondReversed: true
                    }
                },
                {
                    id: 'sales',
                    header: {
                        top: 'Sales',
                        bottom: 'CPS',
                    },
                    value: '',
                    align: 'right',
                    width: '145px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/StackedComparison.vue'),
                    props: {
                        metricFirst: 'offline_purchase',
                        metricSecond: 'cost_per_offline_purchase',
                        metricSecondReversed: true
                    }
                },
                {
                    id: 'button',
                    header: '',
                    value: '',
                    align: 'right',
                    width: '80px',
                    type: 'component',
                    component: () => import('@/components/reports/facebook-advertising/AdAccountButton.vue'),
                    props: (item) => {
                        return {
                            item
                        };
                    }
                },
            ],
            currentCampaigns: [],
            currentCampaignsComparison: []
        };
    },
    computed: {
        ...mapState({
            campaigns: (state) => state.metrics.facebook.ads.campaigns,
            campaignsSelected: (state) => state.metrics.facebook.ads.campaignsSelected,
            comparisonVisible: (state) => state.metrics.comparisonVisible,
        }),
        ...mapGetters('metrics/facebook/ads', {
            metricsLoading: 'metricsLoading',
            campaignStatsData: 'metricsCampaignsFormatted',
            campaignStatsDataComparison: 'metricsCampaignsComparisonFormatted',
            canViewOfflinePurchaseAttribution: 'canViewOfflinePurchaseAttribution',
        }),
    },
    watch: {
        metricsLoading(status) {
            if (status === false) {
                this.requestCampaignBreakout();
                this.requestCampaignComparisonBreakout();
                this.addComparisonData();
            }
        },
        currentCampaigns() {
            if (this.currentCampaigns.length) {
                this.loading = false;
            } else {
                this.loading = true;
            }
        },
        comparisonVisible() {
            this.tableKey += 1;
        }
    },
    created() {
        this.$title = 'Table Component Demo';
        // allowing to view sales amount
        if (this.canViewOfflinePurchaseAttribution) {
            const sales = this.fields.find(i => i.id === 'sales');
            const indexOfSales = this.fields.indexOf(sales);
            sales.value.top = 'offline_purchase.formatted';
            sales.value.bottom = 'cost_per_offline_purchase.formatted';
            if (indexOfSales !== -1) {
                this.fields.splice(indexOfSales, 1, sales);
            }
        }
    },
    mounted() {
        this.requestCampaignBreakout();
        this.requestCampaignComparisonBreakout();
        this.addComparisonData();
    },
    methods: {
        requestCampaignBreakout() {
            if (this.campaignsSelected.length) { // was changed
                this.currentCampaigns = this.campaignStatsData.map(campaign => {
                    const newCampaign = { ...campaign };

                    // If there's insights for this campaign merge them in
                    const campaignInsights = this.campaignsSelected.find(selectedCampaign => campaign.campaign_id.value == selectedCampaign.id); // was changed
                    if (campaignInsights) {
                        newCampaign.has_insights = true;
                        return { ...campaignInsights, ...newCampaign };
                    }
                    // Otherwise return the original campaign
                    return campaign;
                });
            } else {
                this.currentCampaigns = this.campaignStatsData.map(campaignInsights => {
                    // If there's insights for this campaign merge them in
                    const campaign = this.campaigns.find(campaign => campaign.id == campaignInsights.campaign_id.value);
                    if (campaign) {
                        const newCampaign = { ...campaign };
                        newCampaign.has_insights = true;
                        return { ...newCampaign, ...campaignInsights };
                    }

                    // Otherwise return the original campaign
                    return campaignInsights;
                });
            }
        },
        requestCampaignComparisonBreakout() {
            if (this.campaignsSelected.length) {
                this.currentCampaignsComparison = this.campaignStatsDataComparison.map(campaign => {
                    const newCampaign = { ...campaign };
                    const campaignInsightsComparison = this.campaignsSelected.find(selectedCampaign => campaign.campaign_id.value == selectedCampaign.id);
                    if (campaignInsightsComparison) {
                        newCampaign.has_insights = true;
                        return { ...campaignInsightsComparison, ...newCampaign };
                    }
                    return campaign;
                });
            } else {
                this.currentCampaignsComparison = this.campaignStatsDataComparison.map(campaignInsightsComparison => {
                    const campaign = this.campaigns.find(campaign => campaign.id == campaignInsightsComparison.campaign_id.value);
                    if (campaign) {
                        const newCampaign = { ...campaign };
                        newCampaign.has_insights = true;
                        return { ...newCampaign, ...campaignInsightsComparison };
                    }
                    return campaignInsightsComparison;
                });
            }
        },
        addComparisonData() {
            if (this.currentCampaigns.length && this.currentCampaignsComparison.length) {
                this.currentCampaigns.forEach(campaign => {
                    const campaignToCompare = this.currentCampaignsComparison.find(campaignComparison => campaignComparison.campaign_name.value === campaign.campaign_name.value);
                    campaign.comparison = campaignToCompare;
                });
            }
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Campaign Stats',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `campaign_stats_${new Date().getTime()}`
            };

            const csvExporter = new ExportToCsv(options);
            const exportData = this.campaignStatsData.map(campaign => {
                const newData = {};
                for (let metric in campaign) {
                    newData[metric] = campaign[metric].formatted;
                }
                return newData;
            });

            csvExporter.generateCsv(exportData);
        },
    },
};
</script>

<style lang="scss">
#campaign-stats-table.with-comparison {
    .header-cell .stacked {
        padding-right: 50px;
    }
}
</style>
