<template>
    <styled-slideout
        class="generate-report-slideout"
        :value="value"
        label="Generate Report"
        @input="$emit('input', $event)">
        <div class="generate-report-settings">
            <h2>Report Settings</h2>
            <p>This panel will assist you with generating a PDF report for the currently selected Dealer.</p>
            <div class="template">
                <loader v-if="loading" />
                <v-autocomplete
                    v-else
                    v-model="selectedTemplateId"
                    class="styled-field"
                    label="Select Report Template"
                    placeholder="Select One"
                    :items="templates"
                    item-text="display_name"
                    item-value="id" />
            </div>
            <div class="date-range">
                <h3>Verify Date Range</h3>
                <p>This date range can be changed using the date range picker at the top of the page after closing this panel.</p>
                <div class="date">
                    <p class="label">
                        Start Date:
                    </p>
                    <p class="value">
                        {{ dateRange.startDate | moment('MM-DD-YYYY') }}
                    </p>
                </div>
                <div class="date">
                    <p class="label">
                        End Date:
                    </p>
                    <p class="value">
                        {{ dateRange.endDate | moment('MM-DD-YYYY') }}
                    </p>
                </div>
                <div
                    v-if="campaignsSelected.length > 0"
                    class="date">
                    <p class="label">
                        Campaigns:
                    </p>
                    <div class="value">
                        <div
                            v-for="(campaign, index) in campaignsSelected"
                            :key="index">
                            {{ campaign.label }} ({{ campaign.id }}) {{ index < campaignsSelected.length - 1 ? ', ' : '' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="attribution-windows">
                <h3>Attribution Windows</h3>
                <div class="windows">
                    <div class="window">
                        <p class="label">
                            Website Attribution:
                        </p>
                        <p class="value">
                            {{ attribution.website.view }} view / {{ attribution.website.click }} click
                        </p>
                    </div>
                    <div class="window">
                        <p class="label">
                            Offline Attribution:
                        </p>
                        <p class="value">
                            {{ attribution.offline.view }} view / {{ attribution.offline.click }} click
                        </p>
                    </div>
                </div>
            </div>
            <div class="report-disclaimer mt-2">
                Please note that the generated report may differ from what you see in the dashboard, as comparison features are currently unavailable in the PDF export. Stay tuned for updates as we enhance your reporting experience!
            </div>
            <styled-button
                class="generate-button"
                :loading="generating"
                @click="generateReport">
                Generate Report
            </styled-button>
        </div>
    </styled-slideout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import StyledSlideout from '@/components/globals/StyledSlideout';
import StyledButton from '@/components/globals/StyledButton';
import Loader from '@/components/globals/Loader';
import PdfService from '@/pdf-service';
import TemplatesApi from '@/templates-api';
import moment from 'moment';

export default {
    name: 'GenerateReportSlideout',
    components: {
        StyledSlideout,
        StyledButton,
        Loader
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        templateId: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            loading: false,
            generating: false,
        };
    },
    computed: {
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            templates: (state) => state.report.availableTemplates,
            dateRange: (state) => state.metrics.dateRange,
            attribution: (state) => state.metrics.facebook.ads.attribution,
            campaignsSelected: (state) => state.metrics.facebook.ads.campaignsSelected,
        }),
        ...mapGetters([
            'dealerAdSpendOverride',
        ]),
        selectedTemplateId() {
            return this.templates.find(template => template.id === this.templateId)?.id;
        }
    },
    watch: {
        value() {
            if (this.value) {
                this.getReportTemplates();
            }
        }
    },
    methods: {
        ...mapActions([
            'setAvailableTemplates'
        ]),
        async getReportTemplates() {
            try {
                this.loading = true;
                const templates = await (new TemplatesApi()).getAgencyTemplates(this.currentAgencyId);
                this.setAvailableTemplates(templates);
            } finally {
                this.loading = false;
            }
        },
        async generateReport() {
            try {
                this.generating = true;
                const templateId = this.selectedTemplateId;
                const dealerId = this.currentDealerId;
                const startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                const endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                const campaignIds = this.campaignsSelected.map(campaign => campaign.id);

                // This downloads the report to the browser
                await (new PdfService()).getReport({
                    templateId,
                    dealerId,
                    startDate,
                    endDate,
                    campaignIds
                });
            } catch(error) {
                this.$flash('An error occured while generating your report.  Check the console for more info.', 'red');
            } finally {
                this.generating = false;
            }
        }
    }

};
</script>

<style lang="scss" scoped>
.generate-report-settings {
    padding: 30px;
    .template {
        margin-top: 30px;
    }
    h3 {
        font-size: 16px;
        font-weight: 600;
    }
    .date-range {
        margin-top: 30px;
        .date {
            display: flex;
        }
        .label {
            font-weight: 600;
            margin: 0;
            margin-right: 5px;
        }
    }
    .spend-override {
        margin-top: 30px;
        width: 50%;
    }
    .attribution-windows {
        margin-top: 30px;
        .windows {
            display: flex;
        }
        .window {
            padding: 15px;
        }
        .label {
            margin: 0;
            font-weight: 600;
        }

    }
    .generate-button {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 30px;
        text-transform: uppercase;
        font-weight: 600;
    }
}
.report-disclaimer {
    font-style: italic;
    font-size: 13px;
}
</style>