const getItems = (dealer, agency, platforms, userIsClient) => {

    const hasOnlyFBMarketplace = platforms.facebook.marketplace
        && !platforms.facebook.ads
        && !platforms.pinterest
        && !platforms.snapchat
        && !platforms.tiktok
        && userIsClient;

    const separator = {
        template: '<hr>'
    };

    let dealerItems = [];

    if (dealer) {
        dealerItems = [
            {
                title: `${dealer.name} DASHBOARD`,
                icon: 'bb-icon-marketplace',
                child: [
                    {
                        title: 'ACCOUNT SETTINGS',
                        exactPath: true,
                        href: {
                            name: 'dealer',
                            params: {
                                dealer_id: dealer.id
                            }
                        }
                    }
                ]

            },
            {
                title: 'LEADS',
                icon: 'bb-icon-funnel',
                exactPath: true,
                href: {
                    name: 'leads-all',
                    params: {
                        dealer_id: dealer.id
                    }
                }
            },
            {
                title: 'INVENTORY',
                icon: 'bb-icon-inventory',
                exactPath: true,
                href: {
                    name: 'inventory',
                    params: {
                        dealer_id: dealer.id
                    }
                }
            },
            {
                title: 'PLAYBOOKS',
                icon: 'bb-icon-play',
                child: [
                    {
                        title: 'BROWSE',
                        exactPath: true,
                        href: {
                            name: 'dealer-playbooks',
                            params: {
                                dealer_id: dealer.id
                            }
                        }
                    },
                    {
                        title: 'MANAGE',
                        exactPath: true,
                        href: {
                            name: 'dealer-playbooks-manage',
                            params: {
                                dealer_id: dealer.id
                            }
                        }
                    },
                    {
                        title: 'CUSTOM',
                        exactPath: true,
                        href: {
                            name: 'dealer-playbooks-custom',
                            params: {
                                dealer_id: dealer.id
                            }
                        }
                    }
                ]
            },
            {
                title: 'CAMPAIGN MANAGEMENT',
                icon: 'bb-icon-megaphone',
                exactPath: true,
                href: {
                    name: 'campaign-management',
                    params: {
                        dealer_id: dealer.id
                    }
                }
            },
            {
                title: 'ADS LIBRARY',
                icon: 'bb-icon-library',
                exactPath: true,
                href: {
                    name: 'ads-library'
                }
            }
        ];

        const globalDashboard = {
            title: 'DASHBOARD',
            exactPath: true,
            href: {
                name: 'global-dashboard',
                params: {
                    dealer_id: dealer.id
                }
            }
        };

        if (!hasOnlyFBMarketplace) dealerItems[0].child.unshift(globalDashboard);

    }

    const channelItems = [
        {
            title: 'CHANNELS',
            header: true,
            roles: [
                'super_admin',
                'admin',
                'performance_manager',
                'agency_dashboard',
                'client'
            ]
        }
    ];

    // FACEBOOK
    if (!platforms.facebook.ads && !platforms.facebook.marketplace) {
        // Dealer hasn't onboarded with Facebook
        channelItems.push({
            title: 'META',
            icon: 'bb-icon-meta',
            exactPath: true,
            href: {
                name: 'channel-setup-product-selection',
                params: {
                    dealer_id: dealer.id,
                    channel: 'facebook'
                }
            }
        });
    } else if (platforms.facebook.marketplace && !platforms.facebook.ads) {
        //channelItems.push({
        //    title: 'FACEBOOK',
        //    icon: 'bb-icon-facebook',
        //child: [
        //    {
        //       title: 'MARKETPLACE TOOL',
        //        exactPath: true,
        //        href: {
        //            name: 'facebook-marketplace',
        //            params: {
        //                dealer_id: dealer.id
        //            }
        //        }
        //    }
        //]
        //});
    } else {
        channelItems.push({
            title: 'META',
            icon: 'bb-icon-meta',
            child: [
                {
                    title: 'DASHBOARD',
                    href: {
                        name: 'facebook-dashboard',
                        params: {
                            dealer_id: dealer.id
                        }
                    }
                },
                {
                    title: 'REPORTS',
                    child: [
                        {
                            title: 'VEHICLE ADVERTISING',
                            exactPath: true,
                            href: {
                                name: 'reports-vehicle-performance',
                                params: {
                                    dealer_id: dealer.id
                                }
                            }
                        }

                    ]
                },
                //{
                //    title: 'MARKETPLACE TOOL',
                //    exactPath: true,
                //    href: {
                //        name: 'facebook-marketplace',
                //        params: {
                //            dealer_id: dealer.id
                //        }
                //    }
                //},
                {
                    title: 'OFFLINE EVENT UPLOADER',
                    exactPath: true,
                    href: {
                        name: 'dealer-offline-events',
                        params: {
                            dealer_id: dealer.id
                        }
                    }
                },
                {
                    title: 'MONITORING',
                    href: {
                        name: 'dealer-dashboard',
                        params: {
                            dealer_id: dealer.id
                        }
                    }
                }

            ]
        });
    }

    // SNAPCHAT
    if (!platforms.snapchat) {
        // Dealer hasn't onboarded with Snapchat
        channelItems.push({
            title: 'SNAPCHAT',
            icon: 'bb-icon-snapchat',
            exactPath: true,
            href: {
                name: 'channel-setup-product-selection',
                params: {
                    dealer_id: dealer.id,
                    channel: 'snapchat'
                }
            }
        });
    } else {
        channelItems.push({
            title: 'SNAPCHAT',
            icon: 'bb-icon-snapchat',
            child: [
                {
                    title: 'DASHBOARD',
                    href: {
                        name: 'snapchat-dashboard',
                        params: {
                            dealer_id: dealer.id
                        }
                    }
                }
            ]
        });
    }

    // TIKTOK
    if (!platforms.tiktok) {
        // Dealer hasn't onboarded with TikTok
        channelItems.push({
            title: 'TIKTOK',
            icon: 'bb-icon-tiktok',
            exactPath: true,
            href: {
                name: 'channel-setup-product-selection',
                params: {
                    dealer_id: dealer.id,
                    channel: 'tiktok'
                }
            }
        });

    } else {
        channelItems.push({
            title: 'TIKTOK',
            icon: 'bb-icon-tiktok',
            child: [
                {
                    title: 'DASHBOARD',
                    href: {
                        name: 'tiktok-dashboard',
                        params: {
                            dealer_id: dealer.id
                        }
                    }
                }
            ]
        });
    }

    // PINTEREST
    if (!platforms.pinterest) {
        // Dealer hasn't onboarded with Pinterest
        channelItems.push({
            title: 'PINTEREST',
            icon: 'bb-icon-pinterest',
            exactPath: true,
            href: {
                name: 'channel-setup-product-selection',
                params: {
                    dealer_id: dealer.id,
                    channel: 'pinterest'
                }
            }
        });
    } else {
        channelItems.push({
            title: 'PINTEREST',
            icon: 'bb-icon-pinterest',
            child: [
                {
                    title: 'DASHBOARD',
                    href: {
                        name: 'pinterest-dashboard',
                        params: {
                            dealer_id: dealer.id
                        }
                    }
                }
            ]
        });
    }

    let agencyItems = [];
    if (agency && !hasOnlyFBMarketplace) {
        agencyItems = [
            {
                title: 'ORGANIZATION',
                header: true,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            },
            {
                title: 'ONBOARDING',
                icon: 'bb-icon-handshake',
                child: [
                    {
                        title: 'ADD ACCOUNT',
                        exactPath: true,
                        href: {
                            name: 'account-new'
                        }
                    },
                    {
                        title: 'ACCOUNT STATUS',
                        exactPath: true,
                        href: {
                            name: 'account-onboarding-status'
                        }
                    }
                ]
            },
            {
                title: 'ACCOUNT MANAGEMENT',
                icon: 'bb-icon-building',
                child: [
                    {
                        title: 'ALL ACCOUNTS',
                        exactPath: true,
                        href: {
                            name: 'account-management',
                            params: {
                                tab: 'accounts'
                            }
                        }
                    },
                    {
                        title: 'ALL GROUPS',
                        exactPath: true,
                        href: {
                            name: 'account-management',
                            params: {
                                tab: 'groups'
                            }
                        }
                    },
                    {
                        title: 'ALL USERS',
                        exactPath: true,
                        href: {
                            name: 'account-management',
                            params: {
                                tab: 'users'
                            }
                        }
                    }
                ]
            },
            {
                title: 'MARKETANALYZER SESSIONS',
                icon: 'bb-icon-analyzer',
                exactPath: true,
                href: {
                    name: 'sessions'
                }
            },
            {
                title: 'REPORTS',
                icon: 'bb-icon-reports',
                child: [
                    {
                        title: 'REPORT BUILDER',
                        exactPath: true,
                        href: {
                            name: 'report-templates'
                        }
                    },
                    {
                        title: 'ALERTS',
                        exactPath: true,
                        href: {
                            name: 'communication-central',
                            params: {
                                agency_id: agency.id
                            }
                        }
                    },
                    {
                        title: 'META',
                        child: [
                            {
                                title: 'META BUDGETING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-channel-budgeting',
                                    params: {
                                        agency_id: agency.id,
                                        platform: 'facebook'
                                    }
                                }
                            },
                            {
                                title: 'META ADVERTISING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-facebook-advertising',
                                    params: {
                                        agency_id: agency.id
                                    }
                                }
                            },
                            {
                                title: 'META OFFLINE EVENTS',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-facebook-offline-events',
                                    params: {
                                        agency_id: agency.id
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: 'SNAPCHAT',
                        child: [
                            {
                                title: 'SNAPCHAT ADVERTISING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-channel-advertising',
                                    params: {
                                        agency_id: agency.id,
                                        platform: 'snapchat'
                                    }
                                }
                            },
                            {
                                title: 'SNAPCHAT BUDGETING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-channel-budgeting',
                                    params: {
                                        agency_id: agency.id,
                                        platform: 'snapchat'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: 'TIKTOK',
                        child: [
                            {
                                title: 'TIKTOK ADVERTISING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-channel-advertising',
                                    params: {
                                        agency_id: agency.id,
                                        platform: 'tiktok'
                                    }
                                }
                            },
                            {
                                title: 'TIKTOK BUDGETING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-channel-budgeting',
                                    params: {
                                        agency_id: agency.id,
                                        platform: 'tiktok'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: 'PINTEREST',
                        child: [
                            {
                                title: 'PINTEREST ADVERTISING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-channel-advertising',
                                    params: {
                                        agency_id: agency.id,
                                        platform: 'pinterest'
                                    }
                                }
                            },
                            {
                                title: 'PINTEREST BUDGETING',
                                exactPath: true,
                                href: {
                                    name: 'resellers-reports-channel-budgeting',
                                    params: {
                                        agency_id: agency.id,
                                        platform: 'pinterest'
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: 'BILLING',
                        exactPath: true,
                        href: {
                            name: 'resellers-billing',
                            params: {
                                agency_id: agency.id
                            }
                        }
                    },
                    {
                        title: 'PLAYBOOKS',
                        exactPath: true,
                        href: {
                            name: 'resellers-reports-playbooks',
                            params: {
                                agency_id: agency.id
                            }
                        }
                    },
                    {
                        title: 'WEBSITE INVENTORY',
                        exactPath: true,
                        href: {
                            name: 'website-inventory-review'
                        }
                    },
                    {
                        title: 'PRICING CALCULATOR',
                        exactPath: true,
                        roles: [
                            'super_admin',
                            'admin',
                            'agency_dashboard',
                            'performance_manager'
                        ],
                        href: {
                            name: 'pricing-calculator'
                        },
                        badge: {
                            text: 'ADMIN',
                            class: 'vsm--badge_default'
                        }
                    },
                    {
                        title: 'AGENCY TOKEN REPORT',
                        exactPath: true,
                        roles: [
                            'super_admin'
                        ],
                        href: {
                            name: 'agency-token-report'
                        },
                        badge: {
                            text: 'ADMIN',
                            class: 'vsm--badge_default'
                        }
                    }
                ]
            },
            {
                title: 'SETTINGS',
                icon: 'bb-icon-gear',
                exactPath: true,
                href: {
                    name: 'reseller-settings'
                }
            }
        ];
    }

    let adminItems = [];
    if (!hasOnlyFBMarketplace) {
        adminItems = [
            {
                component: separator,
                roles: [
                    'super_admin',
                    'admin',
                    'performance_manager',
                    'agency_dashboard'
                ]
            },
            {
                title: 'ADMIN',
                icon: 'bb-icon-gear',
                roles: [
                    'super_admin',
                    'admin'
                ],
                child: [
                    {
                        title: 'ORGANIZATIONS',
                        child: [
                            {
                                title: 'ALL',
                                exactPath: true,
                                href: {
                                    name: 'resellers'
                                }
                            },
                            {
                                title: 'INVITES',
                                exactPath: true,
                                href: {
                                    name: 'resellers-invites'
                                }
                            }
                        ]
                    }
                ]
            }
        ];
    }

    return [
        ...dealerItems,
        ...channelItems,
        ...agencyItems,
        ...adminItems
    ];
};

export default getItems;
