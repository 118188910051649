<template>
    <div class="wrapper">
        <div class="stacked-content">
            <div class="top">
                {{ firstMetric }}
            </div>
            <div class="bottom">
                {{ secondMetric }}
            </div>
        </div>
        <div
            v-if="comparisonVisible"
            class="stacked-comparison">
            <div class="top-comparison">
                <percentage-change
                    :metric-value="firstMetricValue"
                    :previous-metric-value="firstMetricComparison"
                    :change-reversed="metricFirstReversed" />
            </div>
            <div class="bottom-comparison">
                <percentage-change
                    :metric-value="secondMetricValue"
                    :previous-metric-value="secondMetricComparison"
                    :change-reversed="metricSecondReversed" />
            </div>
        </div>
    </div>
</template>

<script>
import PercentageChange from '@/components/globals/PercentageChange';
import { mapState } from 'vuex';

export default {
    components: {
        PercentageChange
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        metricFirst: {
            type: String,
            required: true
        },
        metricSecond: {
            type: String,
            required: true
        },
        metricFirstReversed: {
            type: Boolean,
            default: false
        },
        metricSecondReversed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            comparisonVisible: (state) => state.metrics.comparisonVisible,
        }),
        firstMetric() {
            return this.item[this.metricFirst].formatted;
        },
        secondMetric() {
            return this.item[this.metricSecond].formatted;
        },
        firstMetricValue() {
            return this.item[this.metricFirst].value;
        },
        secondMetricValue() {
            return this.item[this.metricSecond].value;
        },
        firstMetricComparison() {
            const comparisonMetric = this.item.comparison ?? null;
            if (comparisonMetric) { return comparisonMetric[this.metricFirst].value }
            return 0;
        },
        secondMetricComparison() {
            const comparisonMetricSecond = this.item.comparison ?? null;
            if (comparisonMetricSecond) { return comparisonMetricSecond[this.metricSecond].value }
            return 0;
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    justify-content: flex-end;
}
.stacked-content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .top {
        border-bottom: 1px solid #989898;
        padding-bottom: 5px;
    }
    .bottom {
        padding-top: 5px;
        transform: translateY(-1px);
        border-top: 1px solid #989898;
    }
}
.stacked-comparison{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50px;
    transform: translateX(5px) scale(0.9);
    .top-comparison {
        padding-bottom: 5px;
        transform: translateY(-2px)
    }
    .bottom-comparison {
        padding-top: 5px;
        transform: translateY(2px);
    }
}
</style>
