<template>
    <div class="chart-container">
        <div class="top-panel">
            <styled-selector
                class="metric-selector"
                label="Metric"
                :items="selectorMetrics"
                :value="metric.id"
                @input="selectMetric" />
            <div class="primary-total">
                <p class="metric">
                    {{ metric.name }}
                </p>
                <p class="value">
                    {{ (metricTotals && metricTotals.primary) ? metricTotals.primary.toLocaleString() : '--' }}
                </p>
            </div>
            <div
                v-if="metricTotals"
                class="secondary-totals">
                <div
                    v-if="metricTotals.cost && !hideSpend"
                    class="total">
                    <p class="metric">
                        {{ metricTotals.cost.name }}
                    </p>
                    <p class="value purple-text">
                        {{ metricTotals.cost.value }}
                    </p>
                </div>
                <div
                    v-if="metric.spend && !hideSpend"
                    class="total">
                    <p class="metric">
                        {{ metric.spend.name }}
                    </p>
                    <p class="value green-text">
                        {{ metrics.selected.spend.formatted }}
                    </p>
                </div>
            </div>
        </div>
        <div class="chart">
            <apex-chart
                type="area"
                :height="200"
                :options="options"
                :series="series" />
        </div>
        <div class="bottom-panel">
            <div class="stat previous">
                <p class="name">
                    Previous Period
                </p>
                <p class="date-range">
                    {{ formatDate(dateRangeComparison.startDate) }} to {{ formatDate(dateRangeComparison.endDate) }}
                </p>
                <p class="value">
                    {{ previousPeriodTotal.metric ? previousPeriodTotal.metric.toLocaleString() : previousPeriodTotal.metric }}
                </p>
                <template v-if="metric.cost && !hideSpend">
                    <p class="secondary-stat name">
                        {{ metric.cost ? metric.cost.name : '--' }}
                    </p>

                    <p class="value">
                        ${{ previousPeriodTotal.cost ? previousPeriodTotal.cost.toLocaleString(undefined, { minimumFractionDigits:2, maximumFractionDigits: 2 }) : previousPeriodTotal.cost }}
                    </p>
                </template>
            </div>
            <div class="stat last-month">
                <p class="name">
                    Last Month
                </p>
                <p class="date-range">
                    {{ formatDate(dateRangePreviousMonth.startDate) }} to {{ formatDate(dateRangePreviousMonth.endDate) }}
                </p>
                <p class="value">
                    {{ previousMonthTotal.metric ? previousMonthTotal.metric.toLocaleString() : previousMonthTotal.metric }}
                </p>
                <template v-if="metric.cost && !hideSpend">
                    <p class="secondary-stat name">
                        {{ metric.cost ? metric.cost.name : '--' }}
                    </p>

                    <p class="value">
                        ${{ previousMonthTotal.cost ? previousMonthTotal.cost.toLocaleString(undefined, { minimumFractionDigits:2, maximumFractionDigits: 2 }) : previousMonthTotal.cost }}
                    </p>
                </template>
            </div>
            <div class="stat average">
                <p class="name">
                    90 Day Average
                </p>
                <p class="date-range">
                    {{ formatDate(dateRangePrevious90Days.startDate) }} to {{ formatDate(dateRangePrevious90Days.endDate) }}
                </p>
                <p class="value">
                    {{ average90DayTotal.metric ? average90DayTotal.metric.toLocaleString() : average90DayTotal.metric }}
                </p>
                <p class="interval">
                    /MO
                </p>
                <template v-if="metric.cost && !hideSpend">
                    <p class="secondary-stat name">
                        {{ metric.cost ? metric.cost.name : '--' }}
                    </p>

                    <p class="value">
                        ${{ average90DayTotal.cost ? average90DayTotal.cost.toLocaleString(undefined, { minimumFractionDigits:2, maximumFractionDigits: 2 }) : average90DayTotal.cost }}
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import StyledSelector from '@/components/globals/StyledSelector';
import ApexChart from 'vue-apexcharts';
import moment from 'moment';
import colors from '@/helpers/colors';

export default {
    name: 'Chart',
    components: {
        StyledSelector,
        ApexChart
    },
    props: {
        metric: {
            type: Object,
            required: true
        },
        metricSeries: {
            type: Array,
            required: true
        },
        metrics: {
            type: Object,
            required: true
        },
        availableMetrics: {
            type: Array,
            required: true
        },
        updated: {
            type: String,
            default: () => {return moment().format('M/D/Y @ h:mma')}
        }
    },
    data() {
        return {
            options: {
                grid: {
                    show: false
                },
                legend: {
                    show: false
                },
                colors: [colors.blueDark, colors.purpleChartLine, colors.green],
                fill: {
                    type: ['gradient', 'solid'],
                    colors: ['#00CFF6']
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    width: 2,
                    curve: 'straight',
                    dashArray: [0, 0, 5]
                },
                markers: {
                    size: [0, 3],
                    colors: ['#FFFFFF'],
                    strokeColors: ['', colors.purpleChartLine],
                    strokeWidth: 1,
                },
                chart: {
                    height: 200,
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                },
                yaxis: [
                    {
                        show: false
                    },
                    {
                        show: false,
                        opposite: true
                    }
                ],
                tooltip: {
                    y: [
                        {
                            formatter(value) {
                                return value.toLocaleString();
                            }
                        },
                        {
                            formatter(value) {
                                return '$' + value.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                });
                            }
                        },
                        {
                            formatter(value) {
                                return '$' + value.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                });
                            }
                        }
                    ]
                }
            },
            series: []
        };
    },
    computed: {
        ...mapState({
            dateRange: state => state.metrics.dateRange,
            dateRangeComparison: state => state.metrics.dateRangeComparison,
            platformsSelected: state => state.metrics.aggregate.platformsSelected,
        }),
        ...mapGetters('metrics', [
            'dateRangePreviousMonth',
            'dateRangePrevious90Days',
            'hideSpend',
        ]),
        selectorMetrics() {
            return this.availableMetrics
                .map(m => {
                return {
                    name: m.name,
                    value: m.id
                };
            });
        },
        metricTotals() {
            if (this.metric.cost) {
                return {
                    primary: this.metrics.selected[this.metric.value].value,
                    cost: {
                        name: this.metric.cost.name,
                        value: this.metrics.selected[this.metric.cost.key].formatted
                    },
                };
            } else if(!this.metric.cost) {
                return {
                    primary: this.metrics.selected[this.metric.value]?.value,
                };
            } else {
                return false;
            }
        },
        previousPeriodTotal() {
            if (!this.loading && this.metrics.previousPeriod && this.metric.cost) {
                return {
                    metric: this.metrics.previousPeriod[this.metric.value].value,
                    cost: this.metrics.previousPeriod[this.metric.cost.key].value
                };
            } else if (!this.metric.cost) {
                return {
                    metric: this.metrics.previousPeriod[this.metric.value]?.value
                };
            } else {
                return '--';
            }
        },
        previousPeriodChange() {
                if(this.previousPeriodTotal.metric === 0 || this.previousPeriodTotal.metric === '--') {
                    return '--';
                }
                return (
                    ((this.metricTotals.primary - this.previousPeriodTotal.metric) / this.previousPeriodTotal.metric) *100).toFixed(1) + '%';
        },
        previousMonthTotal() {
            if (!this.loading && this.metrics.previousMonth && this.metric.cost) {
                return {
                    metric: this.metrics.previousMonth[this.metric.value].value,
                    cost: this.metrics.previousMonth[this.metric.cost.key].value
                };
            } else if (!this.metric.cost) {
                return {
                    metric: this.metrics.previousMonth[this.metric.value]?.value
                };
            } else {
                return '--';
            }
        },
        average90DayTotal() {
            if (this.metrics.average90Day && this.metric.cost) {
                return {
                    metric: Math.round(this.metrics.average90Day[this.metric.value].value / 3),
                    cost: this.metric.cost.key == 'spend' ? this.metrics.average90Day[this.metric.cost.key].value / 3 : this.metrics.average90Day[this.metric.cost.key].value
                };
            } else if(!this.metric.cost) {
                return {
                    metric: Math.round(this.metrics.average90Day[this.metric.value]?.value / 3)
                };
            } else {
                return '--';
            }
        }
    },
    watch: {
        metric() {
            this.setSeries();
        },
        hideSpend() {
            this.setSeries();
        },
        platformsSelected() {
            this.setSeries();
        }
    },
    created() {
        this.setSeries();
    },
    methods: {
        setSeries() {
            this.series = [];
            this.series.push(this.getPrimarySeries());

            const secondary = this.getSecondarySeries('cost');
            if (secondary && !this.hideSpend) {
                this.series.push(secondary);
            }

            const spend = this.getSecondarySeries('spend');
            if (spend && !this.hideSpend) {
                this.series.push(spend);
            }
        },
        formatDate(date) {
            if (date instanceof Date) {
                return moment(date).format('M/D/YY');
            }
            return moment(date.slice(0, -1)).format('M/D/YY');
        },
        selectMetric(selected) {
            let metric = this.availableMetrics.find(m => m.id == selected.value);
            this.$emit('changeMetric', metric);
        },
        getPrimarySeries() {
            return {
                name: this.metric.name,
                type: 'area',
                data: this.metricSeries
                    .filter(row => row.stats[this.metric.value].value > 0)
                    .map(row => {
                        return [
                            row.start_date,
                            row.stats[this.metric.value].value
                        ];
                    })
            };
        },
        getSecondarySeries(seriesName) {
            const secondaryMetric = this.metric[seriesName];

            if (secondaryMetric) {
                return {
                    name: secondaryMetric.name,
                    type: 'line',
                    data: this.metricSeries
                        .filter(row => row.stats[this.metric.value].value > 0)
                        .map(row => {
                            return [
                                row.start_date,
                                row.stats[secondaryMetric.key].value || 0
                            ];
                        })
                };
            } else {
                return false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.metric-selector {
    padding: 30px;
}
.top-panel {
    position: relative;
    height: 250px;
    p {
        margin-bottom: 0;
    }
    .primary-total {
        text-align: center;
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        .metric {
            font-size: 12px;
            text-transform: uppercase;
        }

        .value {
            font-size: 36px;
            font-weight: 600;
            color: #00a2ea;
        }
    }
    .secondary-totals {
        display: flex;
        justify-content: center;
        width: 300px;
        flex-wrap: wrap;
        position: absolute;
        top: 200px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        .total {
            width: 50%;
        }
        .metric {
            font-size: 10px;
            text-transform: uppercase;
        }

        .value {
            font-size: 18px;
            font-weight: 600;
            color: #00a2ea;
        }
        .value.purple-text{
            color: $purple;
        }
        .value.green-text{
            color: $green;
        }
    }
}
.chart {
    margin: 0 -12px -30px -12px;

}
.bottom-panel {
    flex: 1;
    display: flex;
    flex-direction: row;
    .stat {
        padding: 15px;
        padding-top: 30px;
        justify-content: center;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #d8d8d8;

        &:last-of-type {
            border: none;
        }
        p {
            margin-bottom: 0;
        }

        .name {
            text-transform: uppercase;
            font-size: 14px;
        }

        .date-range {
            font-size: 13px;
            color: #787878;
        }
        .value {
            font-size: 28px;
            font-weight: 600;
            color: #00a2ea;
        }
        .interval {
            font-size: 14px;
            font-weight: 400;
            color: #5c5c5c;
        }
        .secondary-stat {
            margin-top: 15px;
            font-size: 13px;
            color: #787878;
        }
    }
}
</style>
