import { METRICS_STORAGE_KEY } from '@/helpers/storageKeys';
import { getDefaultDateRangeComparison } from './helpers';
import { startOfDay, subDays } from 'date-fns';
import { INTERVAL_DAY } from '@/helpers/globals';

const getLocalData = () => {
    const currentSettingsJson = localStorage.getItem(METRICS_STORAGE_KEY);
    return currentSettingsJson ? JSON.parse(currentSettingsJson) : null;
};

const localData = { ...getLocalData() };
const savedDate = localData?.dateRange ?? null;
const savedDateComparison = localData?.dateRangeComparison ?? null;

const dateRange = {
    startDate: savedDate?.startDate ?? startOfDay(subDays(new Date(), 31)),
    endDate: savedDate?.endDate ?? startOfDay(subDays(new Date(), 1)),
};

const getDefaultData = () => {
    return {
        dateRange,
        dateRangeComparison: savedDateComparison ? savedDateComparison : getDefaultDateRangeComparison(dateRange),
        spendOverrideDisable: false,
        comparisonVisible: true,
        interval: INTERVAL_DAY,
    };
};

const getInitialData = () => ({
    ...getDefaultData(),
    ...getLocalData()
});

export default getInitialData;