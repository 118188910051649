<template>
    <styled-interface no-padding>
        <metric-info-slideout v-model="showMetricInfo" />

        <template #interface-toolbar-external>
            <dealer-toolbar-global :settings-open.sync="settingsSlideoutOpen" />
        </template>
        <div class="layout wrap">
            <global-alert />

            <div class="flex">
                <div
                    v-if="campaignsSelected.length"
                    class="campaign-holder wrap">
                    <v-chip
                        v-for="campaign in campaignsSelected.slice(0, 4)"
                        :key="campaign.id"
                        class="chip-item"
                        small
                        color="grey lighten-2">
                        <span>
                            {{ campaign.name }}
                        </span>
                        <v-icon
                            class="ml-2"
                            size="18"
                            color="grey lighten-1"
                            @click="unselectCampaign(campaign)">
                            cancel
                        </v-icon>
                    </v-chip>
                    <a
                        v-if="campaignsSelected.length > 4 && showMoreCampaigns == false"
                        @click.prevent="showMoreCampaigns = true">
                        and {{ campaignsSelected.slice(4, campaignsSelected.length).length }} more
                    </a>
                    <span v-if="showMoreCampaigns">
                        <v-chip
                            v-for="campaign in campaignsSelected.slice(4, campaignsSelected.length)"
                            :key="campaign.id"
                            class="chip-item"
                            small
                            color="grey lighten-2">
                            <span>
                                {{ campaign.name }}
                            </span>
                            <v-icon
                                class="ml-2"
                                size="18"
                                color="grey lighten-1"
                                @click="unselectCampaign(campaign)">
                                cancel
                            </v-icon>
                        </v-chip>
                    </span>
                    <a
                        v-if="showMoreCampaigns == true"
                        @click.prevent="showMoreCampaigns = false">
                        show less
                    </a>
                </div>
            </div>

            <div class="dealer-status">
                <div class="item account">
                    <p class="label">
                        Account:
                    </p>
                    <p class="value">
                        {{ currentDealer.name }}
                    </p>
                </div>
                <div
                    v-if="!userIsClient"

                    class="item product">
                    <p class="label">
                        Active Products:
                    </p>
                    <p class="value">
                        {{ productsFormatted }}
                    </p>
                </div>
                <div class="item deployment">
                    <p class="label">
                        Active Deployment:
                    </p>
                    <p
                        v-if="activeDeployment"
                        class="value">
                        <a
                            href="#"
                            @click.prevent="gotoOnboarding">{{ activeDeployment.label }}</a>
                    </p>
                    <p
                        v-else
                        class="value">
                        None at this time
                    </p>
                </div>
                <div class="item actions">
                    <action-button
                        v-if="!hideSpend"
                        class="mx-2"
                        icon="pdf"
                        @click="showGenerateReportSlideout = true">
                        Generate
                    </action-button>
                    <action-button
                        class="mx-2"
                        icon="download"
                        @click="exportCSV()">
                        Export
                    </action-button>
                    <action-button
                        v-if="dealerHasAdProduct"
                        class="mx-2"
                        icon="outbound-link"
                        @click="openAdAccount()">
                        Ad Account
                    </action-button>
                    <action-button
                        v-if="!userIsClient"
                        class="mx-2"
                        icon="cog"
                        :to="{name: 'dealer'}">
                        Account <br>Settings
                    </action-button>
                    <action-button
                        v-if="!userIsClient && dealerHasAdProduct"
                        class="mx-2"
                        icon="cog"
                        @click="settingsSlideoutOpen = true">
                        Advertising <br>Settings
                    </action-button>
                </div>
            </div>
            <onboarding-card
                v-if="!userIsClient"
                class="flex xs12"
                :dealer="currentDealer" />
            <overall-status
                v-if="!userIsClient" />
            <no-advertising-data
                v-if="!insightsLoading && !hasInsights && dealerHasAdvertising" />
            <metric-funnel
                v-if="hasInsights && dealerHasAdvertising"
                @show-info="showMetricInfo = true" />
            <budget-pacing v-if="showBudgeting" />
            <facebook-ad-stats
                v-if="hasInsights && dealerHasAdvertising && !hideSpend" />
            <ads-upsell
                v-if="!dealerHasAdProduct"
                id="upsell" />
            <template v-else>
                <v-alert
                    type="error"
                    :value="!metricTotals && !metricsLoading"
                    class="mb-4 mx-4">
                    There is no advertising data available matching the selected criteria
                </v-alert>
                <offline-events-card
                    v-if="!loadingAccountInsights && metricTotals"
                    class="mx-3 mt-4"
                    :date-range="dateRange"
                    :dealer-id="currentDealerId"
                    :metric-totals="metricTotals" />
                <meta-ads-overall-stats />
                <campaign-stats-table />
            </template>
        </div>
        <generate-report-slideout
            v-model="showGenerateReportSlideout"
            :template-id="FACEBOOK_REPORT_ID" />
    </styled-interface>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MetricInfoSlideout from './metric-funnel/MetricInfoSlideout';
import ActionButton from '@/components/globals/ActionButton';
import GlobalAlert from '@/components/globals/GlobalAlert';
import StyledInterface from '@/components/globals/StyledInterface';
import OnboardingCard from '@/components/dealers/DealerID/OnboardingCard';
import OverallStatus from './cards/OverallStatus';
import NoAdvertisingData from './cards/NoAdvertisingData';
import MetricFunnel from './metric-funnel/MetricFunnel';
import BudgetPacing from './cards/BudgetPacing';
import FacebookAdStats from '@/components/globals/FacebookAdStats';
import DealerToolbarGlobal from '@/components/globals/DealerToolbarGlobal';
import GenerateReportSlideout from '@/components/report-builder/GenerateReportSlideout';
import MetaAdsOverallStats from '@/components/reports/facebook-advertising/MetaAdsOverallStats';
import CampaignStatsTable from '@/components/reports/facebook-advertising/CampaignStatsTable';
import OfflineEventsCard from '@/components/reports/facebook-advertising/OfflineEventsCard';
import AdsUpsell from '@/components/globals/AdsUpsell';
import { SET_FB_DEALER_CAMPAIGNS_SELECTED } from '@/store/mutation-types';
import { FACEBOOK_REPORT_ID } from '@/helpers/globals';
import { ExportToCsv } from 'export-to-csv';

export default {
    name: 'FacebookDashboard',
    title: 'Facebook Dashboard',
    components: {
        DealerToolbarGlobal,
        ActionButton,
        MetaAdsOverallStats,
        CampaignStatsTable,
        OfflineEventsCard,
        AdsUpsell,
        StyledInterface,
        OnboardingCard,
        OverallStatus,
        NoAdvertisingData,
        MetricFunnel,
        BudgetPacing,
        FacebookAdStats,
        MetricInfoSlideout,
        GlobalAlert,
        GenerateReportSlideout
    },
    data() {
        return {
            settingsSlideoutOpen: false,
            showMoreCampaigns: false,
            missingAdvertisingData: false,
            showGenerateReportSlideout: false,
            showMetricInfo: false,
            FACEBOOK_REPORT_ID
        };
    },
    computed: {
        ...mapState({
            dateRange: (state) => state.metrics.dateRange,
            currentDealerId: (state) => state.dealer.currentDealerId,
            campaignsSelected: (state) => state.metrics.facebook.ads.campaignsSelected,
            loadingAccountInsights: (state) => state.metrics.facebook.ads.metricsTotalsLoading,
            currentDealer: state => state.dealer.currentDealer
        }),
        ...mapGetters([
            'dealerProducts',
            'dealerHasFeatures',
            'activeDealerOnboardings',
            'dealerFacebookAdAccountUrlFiltered',
            'dealerHasAdProduct',
            'userIsClient'
        ]),
        ...mapGetters('metrics', [
            'hideSpend'
        ]),
        ...mapGetters('metrics/facebook/ads', {
            hasInsights: 'hasMetrics',
            campaignStatsData: 'metricsCampaignsFormatted',
            metricTotals: 'metricsTotalsFormatted',
            metricsLoading: 'metricsLoading',
            insightsLoading: 'metricsLoading'
        }),
        dealerHasAdvertising() {
            return this.dealerHasFeatures(['facebook_ad_account']);
        },
        showBudgeting() {

            let allowedAgencyIds = [2]; // Agency IDs we're enabling this for

            if(
                allowedAgencyIds.includes(this.currentDealer.agency_id) &&
                !this.userIsClient &&
                this.hasInsights &&
                this.dealerHasAdvertising &&
                !this.hideSpend
            ) {
                // Data can be shown at this point
                return true;
            }
            return false;
        },
        productsFormatted() {
            let names = this.dealerProducts
            .filter(p => p.platform_id == 1)
            .map(p => p.display_name);
            if(names.length == 1) {
                return names[0];
            } else if (names.length == 2) {
                return names.join(', ');
            }
            return names;
        },
        activeDeployment() {
            if(this.activeDealerOnboardings.length > 0) {
                return this.activeDealerOnboardings[0];
            }
            return false;
        }
    },
    methods: {
        unselectCampaign(unselectedCampaign) {
            const newCampaigns = this.campaignsSelected.filter(campaign => {
                return campaign.id !== unselectedCampaign.id;
            });
            this.$store.commit(SET_FB_DEALER_CAMPAIGNS_SELECTED, newCampaigns);
        },
        openAdAccount() {
            const url = this.dealerFacebookAdAccountUrlFiltered({
                dateRange: this.dateRange,
                campaigns: this.campaignsSelected.map(campaign => campaign.id)
            });
            window.open(url);
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Campaign Stats',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `campaign_stats_${new Date().getTime()}`
            };
            const csvExporter = new ExportToCsv(options);
            const exportData = this.campaignStatsData.map(campaign => {
                const newData = {};
                for (let metric in campaign) {
                    newData[metric] = campaign[metric].formatted;
                }
                return newData;
            });
            csvExporter.generateCsv(exportData);
        },
        gotoOnboarding() {
            if (this.userIsClient) {
                this.$router.push({ name: 'dealer-client-onboarding' });
            }

            this.$router.push({
                name: 'dealer-onboarding',
                params: {
                    dealer_id: this.activeDeployment.dealer_id,
                    onboarding_id: this.activeDeployment.id
                }
            });
        }
    }

};
</script>

<style lang="scss">
.interface-actions {
    .action-button {
        margin: 0 10px;
    }
}
#upsell {
    width: 100%;
    .fill-height {
        height: 250px;
    }
}
</style>

<style lang="scss" scoped>
.comparison-dates {
    margin-left: 20px;
    margin-top: 5px;
    .title {
        display: block;
        color: $gray;
        text-transform: uppercase;
        font-size: 12px !important;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        letter-spacing: normal !important;
    }
    .date-range {
        padding: 10px 0;
        font-size: 16px;
    }
}
.layout {
    position: relative;
    #metric-funnel {
        position: relative;
        z-index: 2;
    }
}
.dealer-status {
    margin: 15px 30px 0;
    width: 100%;
    @media (min-width: $bp-sm) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .item {
        margin: 0 0 20px 0;
        @media (min-width: $bp-sm) {
            margin: 0 50px 0 0;
        }
    }
    .actions {
        margin-left: auto;
        margin-right: 0;
        .action-button {
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .label {
        font-size: 13px;
    }
    .value {
        font-size: 16px;
        color: #00a2ea;
        margin-top: -18px;
    }
}
.campaign-holder {
    align-items: center;
    padding: 15px 15px 0 15px;
}
</style>
