<template>
    <custom-table
        class="table"
        name="agency-token-report"
        min-width="1200px"
        :loading="loading"
        :items="agencies"
        :fields="fields"
        :rows-per-page="50"
        :header-margin="145"
        notification=""
        notification-type=""
        initial-sorting="Dealer ID"
        initial-sort-direction="desc"
        :footer-titles-colspan="3"
        slim-cells />
</template>

<script>
import CustomTable from '@/components/globals/CustomTable';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';

export default {
    components: {
        CustomTable
    },
    data() {
        return {
            loading: true,
            agencies: [],
            platforms: [
                PLATFORM_FACEBOOK,
                PLATFORM_TIKTOK,
                PLATFORM_SNAPCHAT,
                PLATFORM_PINTEREST
            ],
            fields: [
                {
                    id: 'name',
                    header: 'Agency Name',
                    value: 'name',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single'
                },
                {
                    id: 'meta',
                    header: 'Meta',
                    value: 'meta',
                    align: 'center',
                    width: '110px',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/reports/AgencyTokenReport/PlatformStatus'),
                    props: {
                        platform: 'facebook'
                    }
                },
                {
                    id: 'tiktok',
                    header: 'TikTok',
                    value: 'tiktok',
                    align: 'center',
                    width: '110px',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/reports/AgencyTokenReport/PlatformStatus'),
                    props: {
                        platform: 'tiktok'
                    }
                },
                {
                    id: 'snapchat',
                    header: 'Snapchat',
                    value: 'snapchat',
                    align: 'center',
                    width: '110px',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/reports/AgencyTokenReport/PlatformStatus'),
                    props: {
                        platform: 'snapchat'
                    }
                },
                {
                    id: 'pinterest',
                    header: 'Pinterest',
                    value: 'pinterest',
                    align: 'center',
                    width: '110px',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/reports/AgencyTokenReport/PlatformStatus'),
                    props: {
                        platform: 'pinterest'
                    }
                },
                {
                    id: 'more-details',
                    header: '',
                    value: 'meta',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/reports/AgencyTokenReport/MoreDetails')
                }
            ]
        };
    },
    async mounted() {
        try {
            const { data } = await this.$apiRepository.getAgencies(this.pagination, this.search, 'agencies.platform_tokens');

            this.agencies = await this.formatAgencies(data?.data ?? []);
        } catch (error) {
            this.$flash('An error occurred while loading agencies', 'red');
        } finally {
            this.loading = false;
        }
    },
    methods: {
        async validateAgencyPlatforms(agencyId) {
            const authPromises = this.platforms.map(platform =>
                this.validateAgencyAuth(agencyId, platform)
            );

            return Promise.all(authPromises);
        },
        async validateAgencyAuth(agency, platform) {
            try {
                if (!agency.platform_tokens?.data?.some(token => token.platform_name === platform)) {
                    return 'N/A';
                }

                await this.$apiRepository.getPlatformTokenStatus(agency.id, platform);

                return 'success';
            } catch (error) {
                return 'failed';
            }
        },
        async formatAgencies(agencies) {
            const formattedPromises = [];

            for (const agency of agencies) {

                // eslint-disable-next-line space-before-function-paren
                const promise = async () => {
                    const platformAuth = await this.validateAgencyPlatforms(agency);

                    return {
                        ...agency,
                        ...Object.fromEntries(this.platforms.map((platform, index) =>
                            [platform, platformAuth[index]]
                        ))
                    };
                };

                formattedPromises.push(promise());
            }

            return Promise.all(formattedPromises);
        }
    }
};
</script>
