<template>
    <div>
        <advertising-settings-slideout v-model="settingsSlideoutOpen" />
        <div
            ref="toolbar"
            class="dealer-toolbar">
            <div class="dealer-toolbar-section">
                <date-range-picker-presets
                    v-if="!hideDateRange"
                    styled />
                <previous-date-range-picker v-if="!hideComparison" />
                <comparison-picker-global v-if="!hideComparison && !userIsClient" />
                <interval-picker-global
                    v-if="!hideInterval" />
            </div>
            <div
                v-if="!hideCampaigns"
                class="dealer-toolbar-section">
                <campaign-picker-global />
            </div>
            <div
                v-if="!hideAttribution"
                class="dealer-toolbar-section">
                <attribution-picker-global />
            </div>
            <div
                v-if="!hideSpendOverride && !userIsClient && dealerAdSpendOverride"
                class="dealer-toolbar-section">
                <spend-override-toggle-global />
                <action-button
                    icon="cog"
                    size="15"
                    @click="settingsSlideoutOpen = true" />
            </div>
        </div>
    </div>
</template>

<script>
import AdvertisingSettingsSlideout from '@/components/globals/AdvertisingSettingsSlideout';
import AttributionPickerGlobal from '@/components/globals/AttributionPickerGlobal';
import CampaignPickerGlobal from '@/components/globals/CampaignPickerGlobal';
import DateRangePickerPresets from '@/components/globals/DateRangePickerPresets';
import PreviousDateRangePicker from '@/components/globals/PreviousDateRangePicker';
import SpendOverrideToggleGlobal from '@/components/globals/SpendOverrideToggleGlobal';
import { mapGetters } from 'vuex';
import IntervalPickerGlobal from '@/components/globals/IntervalPickerGlobal';
import ComparisonPickerGlobal from '@/components/globals/ComparisonPickerGlobal';
import ActionButton from '@/components/globals/ActionButton';
import SyncBodyClassMixin from '@/mixins/sync-body-class';
import { debounce } from 'lodash';


export default {
    components: {
        AdvertisingSettingsSlideout,
        DateRangePickerPresets,
        PreviousDateRangePicker,
        CampaignPickerGlobal,
        AttributionPickerGlobal,
        SpendOverrideToggleGlobal,
        IntervalPickerGlobal,
        ComparisonPickerGlobal,
        ActionButton
    },
    mixins: [
        SyncBodyClassMixin('dealer-toolbar-active')
    ],
    props: {
        hideDateRange: {
            type: Boolean,
            default: false,
        },
        hideComparison: {
            type: Boolean,
            default: false,
        },
        hideInterval: {
            type: Boolean,
            default: false,
        },
        hideCampaigns: {
            type: Boolean,
            default: false,
        },
        hideAttribution: {
            type: Boolean,
            default: false,
        },
        hideSpendOverride: {
            type: Boolean,
            default: false,
        },
        settingsOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            onResize: null,
            settingsSlideoutOpen: true,
            isActive: true,
        };
    },
    computed: {
        ...mapGetters([
            'dealerAdSpendOverride',
            'userIsClient'
        ])
    },
    watch: {
        settingsOpen: {
            handler(value) {
                this.settingsSlideoutOpen = value;
            },
            immediate: true
        },
        settingsSlideoutOpen(value) {
            this.$emit('update:settings-open', value);
        }
    },
    created() {
        this.onResize = debounce(() => {
            this.handleWindowSize();
        }, 300);

        window.addEventListener('resize', this.onResize.bind(this));
    },
    mounted() {
        this.handleWindowSize();
    },
    activated() {
        this.isActive = true;
    },
    deactivated() {
        this.isActive = false;
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize.bind(this));
    },
    methods: {
        handleWindowSize() {
            if (!this.isActive) {
                return;
            }

            const toolbarHeight = this.$refs.toolbar?.clientHeight || 0;
            const interfaceElem = document.querySelector('.interface-container');

            if (window.innerWidth >= 1264) {
                interfaceElem.style.marginTop = toolbarHeight + 'px';
            } else {
                interfaceElem.style.marginTop = '0px';
            }
        },
    },
};
</script>

<style lang="scss">
.dealer-toolbar {
    padding: 20px;
    width: 100%;
    background-color: $alabaster-dark;
    border-bottom: 1px solid $gray-light;
    transition: max-width 0.3s ease-in-out;
    @media (min-width: $bp-md) {
        display: flex;
        padding: 0 20px;
    }
    @media (min-width: $bp-lg) {
        position: fixed;
        max-width: calc(100% - 50px);
        z-index: 100;
    }
    .sidebar-menu-open & {
        @media (min-width: $bp-lg) {
            max-width: calc(100% - 350px);
        }
    }
    .dealer-toolbar-section {
        margin: 0 0 20px 0;
        @media (min-width: $bp-md) {
            min-height: 100%;
            border-right: 1px solid $gray-light;
            padding: 20px 14px 20px 0;
            margin: 0 14px 0 0;
        }
        @media (min-width: $bp-lg) {
            min-height: 0;
            display: flex;
            align-items: center;
            flex-direction: row;
        }
        & > * {
            margin: 0 0 20px 0;
            @media (min-width: $bp-lg) {
                margin: 0 8px 0 0;
            }
            &:last-child {
                margin: 0;
            }
        }
        &:last-child {
            border-right: none;
            margin: 0;
        }
    }
}
</style>