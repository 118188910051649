import mutationTypes from '@/store/mutation-types';
import removeNamespace from '@/helpers/removeNamespace';
import { merge } from 'lodash';

const _mutationTypes = removeNamespace('metrics/facebook/ads/', mutationTypes);

export default {
    [_mutationTypes.SET_FB_ATTRIBUTION](state, attribution) {
        state.attribution = attribution;
    },
    [_mutationTypes.UPDATE_FB_ATTRIBUTION](state, data) {
        state.attribution = merge({ ...state.attribution }, data);
    },
    [_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS](state, events) {
        state.offlineEvents = events;
    },
    [_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_LOADING](state, isLoading) {
        state.offlineEventsLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_CURRENT](state, events) {
        state.offlineEventsCurrent = events;
    },
    [_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_CURRENT_LOADING](state, isLoading) {
        state.offlineEventsCurrentLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_OFFLINE_EVENTS_ERROR](state, error) {
        state.offlineEventsError = error;
    },
    [_mutationTypes.SET_FB_DEALER_CAMPAIGNS](state, campaigns) {
        state.campaigns = campaigns;
    },
    [_mutationTypes.SET_FB_DEALER_CAMPAIGNS_SELECTED](state, selections) {
        state.campaignsSelected = selections;
    },
    [_mutationTypes.SET_FB_DEALER_CAMPAIGNS_LOADING](state, isLoading) {
        state.campaignsLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_ERROR](state, error) {
        state.dealerInsightsError = error;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS](state, insights) {
        state.metricsTotals = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_LOADING](state, isLoading) {
        state.metricsTotalsLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_INTERVALS](state, insights) {
        state.metricsIntervals = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_INTERVALS_LOADING](state, isLoading) {
        state.metricsIntervalsLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS](state, insights) {
        state.metricsCampaigns = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS_LOADING](state, isLoading) {
        state.metricsCampaignsLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON](state, insights) {
        state.metricsCampaignsComparison = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON_LOADING](state, isLoading) {
        state.metricsCampaignsComparisonLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_INVENTORY](state, insights) {
        state.metricsInventory = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_INVENTORY_LOADING](state, isLoading) {
        state.metricsInventoryLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON](state, insights) {
        state.metricsTotalsComparison = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON_LOADING](state, isLoading) {
        state.metricsTotalsComparisonLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS](state, insights) {
        state.metricsTotalsPrevious90Days = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS_LOADING](state, isLoading) {
        state.metricsTotalsPrevious90DaysLoading = isLoading;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH](state, insights) {
        state.metricsTotalsPreviousMonth = insights;
    },
    [_mutationTypes.SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH_LOADING](state, isLoading) {
        state.metricsTotalsPreviousMonthDayLoading = isLoading;
    },
};