<template>
    <div class="custom-table-container">
        <div v-if="!hideHeader">
            <filter-table-row
                ref="filterRow"
                :headers="fields"
                :actions="actions"
                :hide-sort="hideSort"
                :hide-search="hideSearch"
                :search-label="searchLabel"
                :table-name="name"
                :search="search"
                :column-presets="presets"
                :pagination="localPagination"
                :selected-rows="selectedRows"
                :total-items="items.length"
                :has-customization="hasCustomization"
                @show-notification="showNotification"
                @onGlobalSearch="onGlobalSearch"
                @onUpdateColumnsPreset="onUpdatePreset"
                @sortColumn="changeSort">
                <template>
                    <slot name="header-slot" />
                </template>
            </filter-table-row>
            <div class="filter-row-placeholder" />
        </div>
        <v-divider class="mt-3" />
        <div
            ref="tableContainer"
            class="table-overflow">
            <div
                ref="tableWrap"
                class="table-wrap">
                <table
                    ref="table"
                    class="custom-table">
                    <!-- when header becomes sticky we need to fill his place in order to avoid jumping of all content -->
                    <tr
                        ref="headerPlaceholder"
                        class="header-placeholder">
                        <td v-if="selectable || expandable" />
                        <td
                            v-for="(header, headerIndex) in columnsToShow"
                            :key="headerIndex"
                            :style="{width: header.width}" />
                        <td v-if="hasMenu" />
                    </tr>

                    <!-- header + notificaton bar -->
                    <tbody
                        ref="stickyWrapper"
                        class="sticky-wrapper">
                        <tr
                            ref="tableHeader"
                            class="table-header">
                            <td
                                v-if="selectable || expandable"
                                ref="selectAllCell"
                                class="header-cell select-all">
                                <div v-if="selectAll">
                                    <p class="label">
                                        <span class="single">Select all</span>
                                    </p>
                                    <div class="filter">
                                        <div class="dealers-onboarding-checkbox">
                                            <v-checkbox
                                                v-model="allSelectedRows"
                                                height="25" />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td
                                v-for="(header, headerIndex) in columnsToShow"
                                :key="headerIndex"
                                class="header-cell"
                                :class="{
                                    sortable: header.sortable,
                                    active: header.id === localPagination.sortBy,
                                    asc: !localPagination.descending,
                                    desc: localPagination.descending
                                }"
                                :style="{
                                    textAlign: header.align,
                                    width: header.width
                                }">
                                <p class="label">
                                    <template v-if="header.header.top">
                                        <div
                                            :class="{'no-filter': !header.filter}"
                                            class="stacked">
                                            <div class="top">
                                                {{ header.header.top }}
                                            </div>
                                            <div class="bottom">
                                                {{ header.header.bottom }}
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div
                                            :class="{'no-filter': !header.filter}"
                                            class="single"
                                            @click="changeSort(header)">
                                            <styled-tooltip
                                                :key="tooltipKey"
                                                :max-width="250"
                                                position="bottom">
                                                <template
                                                    v-if="header.tooltip"
                                                    #content>
                                                    <div>{{ header.tooltip }}</div>
                                                </template>
                                                <span :style="{whiteSpace: header.whitespace}">{{ header.header }}</span>
                                                <img
                                                    v-if="header.sortable && localPagination.sortBy === header.id"
                                                    :src="require('@/assets/icons/arrow-sort.svg')"
                                                    alt="sort"
                                                    :class="{rotated: !localPagination.descending}">
                                            </styled-tooltip>
                                        </div>
                                    </template>
                                </p>
                                <!-- column filters -->
                                <template v-if="header.filter && hasFilters">
                                    <div class="filter">
                                        <v-select
                                            v-if="header.filter === 'multiple' && !backendFiltering"
                                            :ref="'multiple-'+header.id"
                                            v-model="columnsToShow[header.value]"
                                            :placeholder="header.placeholder"
                                            height="25"
                                            :items="columnValueList(header.value)"
                                            :class="`styled-field table-filter-${header.id}`"
                                            multiple
                                            clearable
                                            outlined>
                                            <template #selection="{ item, index }">
                                                <span
                                                    v-if="item && index === 0"
                                                    class="multiple-selected">
                                                    {{ columnsToShow[header.value].length }} selected
                                                </span>
                                            </template>
                                        </v-select>
                                        <v-select
                                            v-if="header.filter === 'multiple' && backendFiltering"
                                            :ref="'multiple-'+header.id"
                                            :value="currentSelection(header.value)"
                                            :placeholder="header.placeholder"
                                            height="25"
                                            :items="columnValueList(header.value)"
                                            :class="`styled-field table-filter-${header.id}`"
                                            :menu-props="{
                                                contentClass: 'custom-table-menu',
                                            }"
                                            multiple
                                            clearable
                                            outlined
                                            @change="applyFiltering($event, header.id, true)">
                                            <template #selection="{ index }">
                                                <span
                                                    class="multiple-back-end-selected">
                                                    ({{ +index + 1 }})
                                                </span>
                                            </template>
                                        </v-select>
                                        <v-select
                                            v-if="header.filter === 'single' && !backendFiltering"
                                            :ref="'single-'+header.id"
                                            v-model="columnsToShow[header.value]"
                                            :placeholder="header.placeholder"
                                            height="25"
                                            :items="columnValueList(header.value)"
                                            :class="`styled-field table-filter-${header.id}`"
                                            clearable
                                            return-object
                                            outlined />
                                        <v-select
                                            v-if="header.filter === 'single' && backendFiltering"
                                            :ref="'single-'+header.id"
                                            :value="currentSelection(header.value)"
                                            :placeholder="header.placeholder"
                                            height="25"
                                            :items="columnValueList(header.value)"
                                            :class="`styled-field table-filter-${header.id}`"
                                            clearable
                                            return-object
                                            outlined
                                            @change="applyFiltering($event, header.id)" />
                                        <i
                                            v-if="header.filter === 'search' && header.type != 'stacked'"
                                            class="search-icon material-icons">search</i>
                                        <input
                                            v-if="header.filter === 'search' && header.type != 'stacked'"
                                            :ref="'input-'+header.id"
                                            :value="currentSearch(header.value)"
                                            placeholder=""
                                            :name="header.value"
                                            height="25"
                                            type="text"
                                            class="styled-search"
                                            autocomplete="off"
                                            @input="onSingleSearch($event, header.id)">
                                    </div>
                                </template>
                                <!-- END column filters -->
                            </td>
                            <td
                                v-if="hasMenu"
                                ref="menuCell"
                                class="header-cell placeholder" />
                        </tr>

                        <!-- notification bar -->
                        <tr class="notification-wrapper">
                            <td :colspan="headerColumnsLength">
                                <div
                                    v-if="alertBar"
                                    class="alert-wrapper">
                                    <custom-alert-bar
                                        :show-alert="alertBar"
                                        :alert="alert"
                                        :loading="loading"
                                        @close="alertBar = false">
                                        <template
                                            v-if="selectedRows.length && selectedRows.length < totalRows"
                                            #selectedRows>
                                            <a @click="selectAllRows">Select all {{ totalRows }} rows</a>
                                        </template>
                                    </custom-alert-bar>
                                </div>
                            </td>
                        </tr>
                        <!-- END notification bar -->
                    </tbody>
                    <!-- END header + notification bar -->

                    <tbody
                        ref="cells"
                        :key="tableKey">
                        <slot v-for="(item,rowIndex) in paginatedItems">
                            <tr
                                :key="item[getItemKey]"
                                class="table-row"
                                :class="{
                                    'alt-color': rowIndex % 2 !== 0,
                                    'selected-row': selectedRow(item)
                                }">
                                <!-- column for selectable / expandable -->
                                <td v-if="selectable || expandable">
                                    <div class="selection-cell">
                                        <button
                                            v-if="expandable"
                                            class="row-toggle"
                                            @click="toggleExpand(item.id)">
                                            <div
                                                :ref="`arrow-${item.id}`"
                                                class="toggle rotate">
                                                <icon
                                                    size="20"
                                                    name="collapse" />
                                            </div>
                                        </button>
                                        <div
                                            v-if="selectable"
                                            class="dealers-onboarding-checkbox">
                                            <span
                                                class="styled-checkbox-checkmark"
                                                :class="{'selected': item.selected}"
                                                @click="selectItem(item, item.selected)" />
                                        </div>
                                    </div>
                                </td>
                                <!-- END column for selectable / expandable -->

                                <table-cell
                                    v-for="(cell, cellIndex) in columnsToShow"
                                    :key="cellIndex"
                                    :config="cell"
                                    :slim="slimCells"
                                    :item="item" />

                                <!-- menu dots -->
                                <td
                                    v-if="hasMenu"
                                    v-outside-click="hideMenu"
                                    class="row-menu">
                                    <div
                                        :ref="'vertical-'+item.id"
                                        class="vertical-menu"
                                        @click="renderVerticalMenuContent(item.id)">
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                    <div
                                        :ref="'menu-'+item.id"
                                        class="floating-menu">
                                        <slot
                                            name="menu"
                                            :item="item" />
                                    </div>
                                </td>
                                <!-- END menu dots -->
                            </tr>

                            <!-- here we store our expandable stuff -->
                            <template v-if="expandable">
                                <tr
                                    :ref="`expand-${item.id}`"
                                    :key="`${item.id}-show`"
                                    class="expand-hidden">
                                    <td
                                        class="expand-area"
                                        :colspan="headerColumnsLength">
                                        <slot
                                            name="expand"
                                            :item="item" />
                                    </td>
                                </tr>
                            </template>
                        </slot>

                        <!-- totals row -->
                        <tr
                            v-if="hasTotals && paginatedItems.length"
                            class="totals-row">
                            <td
                                v-if="footerTitlesColspan"
                                :colspan="footerTitlesColspan"
                                class="footer-title">
                                <div>Total:</div>
                                <div>Average:</div>
                                <div>Calculated:</div>
                            </td>
                            <td
                                v-for="(total, index) in totals.slice(footerTitlesColspan)"
                                :key="index + footerTitlesColspan"
                                :style="{ textAlign: total.header.align || 'left' }"
                                class="column-total">
                                <copy-text
                                    :value="total.value"
                                    show-text-only>
                                    {{ total.formatted }}
                                </copy-text>
                                <div v-if="total.average_value">
                                    <copy-text
                                        :value="total.average_value"
                                        show-text-only>
                                        {{ total.average_formatted }}
                                    </copy-text>
                                </div>
                                <div v-if="total.calculated">
                                    <copy-text
                                        :value="total.calculated"
                                        show-text-only>
                                        <channel-advertising-calculated-tooltip
                                            :value="total.calculated"
                                            :details="total" />
                                    </copy-text>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </td>
                            <td v-if="hasMenu" />
                        </tr>

                        <!-- END totals row -->
                        <tr v-if="!paginatedItems.length">
                            <td colspan="100%">
                                <div
                                    v-if="!hasInventory && !loading && backendFiltering"
                                    class="no-inventory">
                                    No inventory available. Please configure your inventory data source during onboarding to use this tool.
                                </div>
                                <div
                                    v-else-if="loading"
                                    class="mt-4" />
                                <h2
                                    v-else-if="noDataText"
                                    class="no-data-text">
                                    {{ noDataText }}
                                </h2>
                                <h3
                                    v-else
                                    class="ml-3 mt-4">
                                    Sorry, but nothing matched your search criteria...
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    ref="lazyLoader"
                    class="lazy-loader" />
            </div>
        </div>

        <!-- pagination -->
        <div
            v-if="!loading && !hidePagination"
            class="pagination"
            :class="{'modal-view': modalView}">
            <div class="controls">
                <div class="rows-per-page">
                    <p class="label">
                        Rows per Page:
                    </p>
                    <p class="selector">
                        <v-select
                            ref="rows-selector"
                            v-model="localPagination.rowsPerPage"
                            :items="rowsPerPageOptions"
                            item-text="text"
                            item-value="value"
                            flat
                            small
                            dense
                            @change="resetPage" />
                    </p>
                </div>
                <p class="currently-viewing">
                    {{ startingRow + 1 }} - {{ endingRow > totalRows ? totalRows : endingRow }} of {{ totalRows }}
                </p>
                <div class="prev-next">
                    <button
                        class="prev"
                        :class="{active: hasPreviousPage}"
                        @click="previousPage">
                        <icon
                            name="chevron-left"
                            width="20"
                            height="20" />
                    </button>
                    <button
                        class="next"
                        :class="{active: hasNextPage}"
                        @click="nextPage">
                        <icon
                            name="chevron-right"
                            width="20"
                            height="20" />
                    </button>
                </div>
            </div>
        </div>
        <!-- END of pagination -->
    </div>
</template>

<script>
import FilterTableRow from '@/components/globals/CustomTable/elements/FilterTableRow';
import TableCell from '@/components/globals/CustomTable/elements/TableCell';
import CustomAlertBar from '@/components/globals/CustomTable/elements/CustomAlertBar';
import StyledTooltip from '@/components/globals/StyledTooltip';
import CopyText from '@/components/globals/CopyText';

import Icon from '@/components/globals/Icon';

import getNumberFormats from '@/helpers/numberFormats';
import numeral from 'numeral';
import { get, debounce } from 'lodash';
import ChannelAdvertisingCalculatedTooltip from '@/components/globals/ChannelAdvertisingCalculatedTooltip.vue';

const numberFormats = getNumberFormats(true);

export default {
    name: 'CustomTable',
    components: {
        ChannelAdvertisingCalculatedTooltip,
        Icon,
        FilterTableRow,
        TableCell,
        CustomAlertBar,
        StyledTooltip,
        CopyText
    },
    props: {
        name: {
            type: String,
            required: true
        },
        fields: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        rowsPerPage: {
            type: Number,
            default: 10,
        },
        rowsPerPageItems: {
            type: Array,
            default: () => [10, 20, 50],
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideSort: {
            type: Boolean,
            default: false,
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
        searchLabel: {
            type: String,
            default: 'Search',
        },
        itemKey: {
            type: String,
            default: null,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
        hasFilters: {
            type: Boolean,
            default: false,
        },
        minWidth: {
            type: String,
            default: '100%',
        },
        hideMargins: {
            type: Boolean,
            default: false,
        },
        pagination: {
            type: Object,
            default: () => {},
        },
        totalItems: {
            type: Number,
            default: 0,
        },
        filters: {
            type: Object,
            default: () => {},
        },
        loading: {
            type: Boolean,
            default: false,
        },
        notification: {
            type: String,
            default: null,
        },
        notificationType: {
            type: String,
            default: 'info',
        },
        presets: {
            type: Array,
            default: () => [],
        },
        hasTotals: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        selectAll: {
            type: Boolean,
            default: false,
        },
        backendSelection: {
            type: Boolean,
            default: false,
        },
        hasMenu: {
            type: Boolean,
            default: false,
        },
        hasCustomization: {
            type: Boolean,
            default: false,
        },
        isExternal: {
            type: Boolean,
            default: false,
        },
        reset: {
            type: Boolean,
            default: false,
        },
        headerMargin: {
            type: Number,
            default: 70,
        },
        backendFiltering: {
            type: Boolean,
            default: false,
        },
        slimCells: {
            type: Boolean,
            default: false,
        },
        customFilters: {
            type: Array,
            default: () => [],
        },
        modalView: {
            type: Boolean,
            default: false,
        },
        hidePagination: {
            type: Boolean,
            default: false
        },
        stickyPanel: {
            type: Boolean,
            default: false
        },
        lazyLoading: {
            type: Boolean,
            default: false
        },
        noDataText: {
            type: String,
            default: ''
        },
        // Inventory Dashboard exclusive props
        activeReasons: {
            type: Array,
            default: () => [],
        },
        activeChannels: {
            type: Array,
            default: () => [],
        },
        hasInventory: {
            type: Boolean,
            default: true
        },
        selectedFilters: {
            type: Object,
            default: () => {},
        },
        footerTitlesColspan: {
            type: Number,
            default: 0
        },
        initialSorting: {
            type: String,
            default: ''
        },
        initialSortDirection: {
            type: String,
            default: 'asc'
        },
    },
    data() {
        return {
            search: '',
            localPagination: {
                rowsPerPage: null,
                descending: false,
            },
            columnsToShow: [],
            currentPage: 1,
            selectedRows: [],
            allSelectedRows: false,
            alertBar: false,
            alert: {
                color: '#03A2EA',
                type: 'info',
                message: '',
            },
            initialRows: null,
            start: 1,
            end: 1,
            tableWidth: 0,
            allPagesAndRows: false,
            singleSearchData: {},
            singleSearchValue: '',
            tooltipKey: 0,
            tableKey: 0
        };
    },
    computed: {
        headerColumnsLength() {
            let colspanLength = this.columnsToShow.length;
            if (this.hasMenu) {
                colspanLength += 1;
            }
            if (this.expandable || this.selectable) {
                colspanLength += 1;
            }
            return colspanLength;
        },
        getItemKey() {
            if (this.itemKey) {
                return this.itemKey;
            }
            return 'id';
        },
        totalRows() {
            if (this.totalItems) {
                return this.totalItems;
            }
            return this.searchedItems.length;
        },
        totalColumns() {
            return this.fields.length + 2;
        },
        startingRow() {
            if (this.pagination) {
                return this.pagination.meta.from - 1;
            } else {
                return (this.currentPage-1) * this.localPagination.rowsPerPage;
            }
        },
        endingRow() {
            if (this.pagination) {
                return this.pagination.meta.to;
            } else {
                if (this.localPagination.rowsPerPage == -1) {
                    return this.searchedItems.length;
                } else {
                    return this.localPagination.rowsPerPage * this.currentPage;
                }
            }
        },
        hasPreviousPage() {
            if (this.pagination) {
                return this.pagination.meta.current_page > 1;
            }
            return this.currentPage > 1;
        },
        hasNextPage() {
            if (this.pagination) {
                return this.pagination.meta.current_page < this.pagination.meta.last_page;
            }
            return this.searchedItems.length > this.endingRow;
        },
        rowsPerPageOptions() {
            const items = this.rowsPerPageItems || [];
            // Get the unique result of the default items and whatever the user has provided
            const combinedItems = [
                ...new Set([...items, ...[this.initialRows]]),
            ].sort((a, b) => a - b);

            // Add all to the end
            combinedItems.push({ text: 'All', value: 10000 });
            return combinedItems;
        },
        filteredItems() {
            return this.items.filter(item => {
                return Object.keys(this.columnsToShow).every(field => {
                    const convertedPath = get(item, field);
                    const itemValue = convertedPath ? convertedPath.toString().toLowerCase() : convertedPath;
                    let filterValues = this.columnsToShow[field];

                    // if we have v-select with single value it returned as a string so let's wrap it in array
                    if (typeof filterValues == 'string') {
                        filterValues = [filterValues];
                    }
                    // fixing bug when clearing input
                    if (typeof filterValues == 'undefined') {
                        filterValues = [];
                    }
                    if (filterValues.length) {
                        const lowCaseValues = [];
                        filterValues.forEach(val =>{
                            lowCaseValues.push(val.toString().toLowerCase());
                        });
                        if (lowCaseValues.includes(itemValue)) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                });
            });
        },
        searchedItems() {
            return this.filteredItems.filter(item => {

                // ----- GLOBAL SEARCH ----- //
                if (this.search) {
                    return this.fields.some((header) => {
                        const initialValue = header.value;
                        const value = get(item, initialValue) || '';

                        // for 'stacked' type
                        if (typeof initialValue == 'object') {
                            const initialTopValue = header.value.top;
                            const initialBottomValue = header.value.bottom;
                            const topValue = get(item, initialTopValue);
                            const bottomValue = get(item, initialBottomValue);

                            if(topValue && bottomValue) {
                                if (topValue.toString().toLowerCase().includes(this.search.toLowerCase())) {
                                    return true;
                                }
                                if (bottomValue.toString().toLowerCase().includes(this.search.toLowerCase())) {
                                    return true;
                                }
                            }
                        }

                        if (value.toString().toLowerCase().includes(this.search.toLowerCase())) {
                            return true;
                        }
                    });
                }

                // ----- MULTIPLE SEARCHES BY IDIVIDUAL COLUMNS ----- //
                if (this.singleSearchValue || Object.keys(this.singleSearchData).length) {
                    return this.fields.some((header) => {

                        // let's clear our data if search value is empty (this piece is for preventing some bugs)
                        Object.keys(this.singleSearchData).forEach(key => {
                            if (this.singleSearchData[key] === '') {
                                delete this.singleSearchData[key];
                            }
                        });

                        if (header.value in this.singleSearchData) {
                            const filtered = [];
                            Object.keys(item).forEach(field =>{
                                for (let property in this.singleSearchData) {
                                    const vals = property.split('.');
                                    if (field == vals[0]) {
                                        const valueFromItem = get(item, property);
                                        if (valueFromItem.toString().toLowerCase().includes(this.singleSearchData[property].toLowerCase())) {
                                            filtered.push(item);
                                        }
                                    }
                                }
                            });
                            // then check if all filters across different columns applied - if 3 filters were applied then we must have number '3'.
                            // if number is different (lower) it means that we have no items that match our filtering criteria
                            if (filtered.length && filtered.length == Object.keys(this.singleSearchData).length) {
                                return this.singleSearchData[header.value].toString().toLowerCase().includes(this.singleSearchValue.toLowerCase());
                            }
                        }
                    });
                }
                // if no filters applied just return all items
                return true;
            });
        },
        sortedItems() {
            const sortedItems = [...this.searchedItems];
            return sortedItems.sort((a, b) => {
                const sortDirection = this.localPagination.descending ? -1 : 1;
                let firstItem = get(a, this.sortingValue) ?? '-1';
                let secondItem = get(b, this.sortingValue) ?? '-1';
                let stringItem1;
                let stringItem2;
                if (typeof firstItem == 'string' && typeof secondItem == 'string') {
                    stringItem1 = Number(firstItem.replace(/(^\$|,)/g,''));
                    stringItem2 = Number(secondItem.replace(/(^\$|,)/g,''));
                }
                if (isNaN(stringItem1) && isNaN(stringItem2)) {
                    if (typeof firstItem == 'string' && typeof secondItem == 'string') {
                        firstItem = firstItem.toLowerCase();
                        secondItem = secondItem.toLowerCase();
                    }
                    if (firstItem < secondItem) {
                        return -1 * sortDirection;
                    } else if (firstItem > secondItem) {
                        return 1 * sortDirection;
                    } else {
                        return 0;
                    }
                } else {
                    if (stringItem1 < stringItem2) {
                        return -1 * sortDirection;
                    } else if (stringItem1 > stringItem2) {
                        return 1 * sortDirection;
                    } else {
                        return 0;
                    }
                }
            });
        },
        paginatedItems() {
            if (this.pagination) {
                return this.items;
            } else {
                return this.sortedItems.slice(this.startingRow, this.endingRow);
            }
        },
        totals() {
            if (!this.hasTotals) {
                return [];
            }

            // Setup an array of totals with null values for each column
            const totals = new Array(this.columnsToShow.length).map(
                () => null
            );

            this.searchedItems.forEach((item) => {
                this.fields.forEach((header, index) => {
                    // Only compute if a total property was provided
                    if (!header.total) {
                        return;
                    }
                    let value = get(item, header.value) || 0;
                    if (typeof value == 'string') {
                        value = value.replace(/(^\$|,)/g,'');
                    }
                    if (!totals[index]) {
                        totals[index] = parseFloat(value);
                    } else {
                        if (!isNaN(value)) {
                            totals[index] += parseFloat(value);
                        }
                    }
                });
            });

            // Build up the totals object since we allow the user to use this
            // or order to created total fields that compute totals from the
            // results generated above!
            const totalsObj = {};
            this.fields.forEach((header, index) => {
                // Only work on this header if there's a corresponding total and ID
                if (!totals[index] || !header.id) {
                    return;
                }

                // Add the total to the totals object
                totalsObj[header.id] = totals[index];
                totalsObj[header.id + '_format'] = header.format;
            });

            // Now loop through all the fields one last time and call up
            // any functions assigned to the total property
            this.fields.forEach((header, index) => {
                // If the header is defined as a function call it!
                if (typeof header.total === 'function') {
                    try {
                        totals[index] = header.total.call(null, totalsObj);
                    } catch (error) {
                        console.error(error.message || error);
                    }
                }
            });

            // set initial values to fields
            this.fields.forEach((header, index) => {
                if (isNaN(totals[index])) {
                    totals[index] = {
                        value: '',
                        formatted: '',
                        header,
                    };
                } else {
                    totals[index] = {
                        value: totals[index],
                        formatted: header.format ? numeral(totals[index]).format(numberFormats[header.format]) : totals[index],
                        header,
                    };
                }
            });

            // Handle averages and setting formatted values
            const finalTotals = [];
            totals.forEach(t =>{
                if (t.value == '') {
                    t.value = '';
                    t.formatted = '';
                    finalTotals.push(t);
                } else {
                    if (t.header.average) {
                        // calculate averages only from items that have values
                        const itemsWithValidData = this.searchedItems.filter(item => {
                            const validValue = get(item, t.header.value);
                            if (validValue) {
                                return item;
                            }
                        });
                        t.average_value = t.value / itemsWithValidData.length;
                        t.average_formatted = t.header.format ? numeral(t.average_value).format(numberFormats[t.header.format]) : t.average_value;
                    }

                    if (t.header.calculated) {
                        t.calculated = t.header.calculated && typeof t.header.calculated === 'function' ? t.header.calculated.call(null, totalsObj) : '';
                        t.totalObject = totalsObj;
                    }

                    t.formatted = t.header.format ? numeral(t.value).format(numberFormats[t.header.format]) : '';
                    finalTotals.push(t);
                }
            });

            // setting the 'readable' value for percents and cutting long numbers
            totals.forEach(t =>{
                if (t.header.format == 'percent') {
                    t.value = t.value * 100;
                    if (t.header.average) {
                        t.average_value = t.average_value * 100;
                    }
                }
                let calculated = t.header.calculated ? t.calculated ?? '' : '';
                let totalObject = t.header.calculated ? t.totalObject ?? '' : '';

                const fixedValue = t.value;
                t.value = Number(fixedValue).toFixed(2);
                if (t.header.average) {
                    const fixedAverageValue = t.average_value;
                    if (t.header.format == 'number') {
                        t.average_value = Number(fixedAverageValue).toFixed();
                    } else {
                        t.average_value = Number(fixedAverageValue).toFixed(2);
                    }
                }

                if (t.header.calculated) {
                    if (t.header.format === 'percent') {
                        t.formatted = '-';
                        t.average_formatted = '-';
                    }
                    t.calculated = t.header.format && typeof t.header.calculated !== 'undefined' ? numeral(calculated).format(numberFormats[t.header.format]) : calculated ? calculated : '';
                    t.totalObject = totalObject;
                }
            });

            // show only totals for columns that selected for showing
            const columnsToShowTotals = [];
            this.columnsToShow.forEach(column => {
                finalTotals.forEach(t => {
                    if (column.id == t.header.id) {
                        columnsToShowTotals.push(t);
                    }
                });
            });
            return columnsToShowTotals;
        },
    },
    watch: {
        paginatedItems() {
            this.tableKey += 1;
        },
        pagination(value) {
            this.currentPage = value.meta.current_page;
            this.localPagination = value;
        },
        filters(value) {
            this.columnsToShow = value;
        },
        loading(value) {
            this.alertBar = value;
        },
        notification(value) {
            if(value != '') {
                this.alertBar = true;
                this.alert.message = value;
                this.alert.type = this.notificationType;
            }
        },
        notificationType(value) {
            this.alert.message = this.notification;
            this.alert.type = value;
        },
        descending(value) {
            this.localPagination.descending = value;
        },
        sortedItems() {
            if(this.localPagination.rowsPerPage *(this.currentPage-1) > this.totalRows) {
                this.currentPage = 1;
            }
        },
        selectedRows(value) {
            this.selectedRows = value;
            this.allPagesAndRows = false;
            if (this.selectedRows.length === this.items.length) {
                this.allPagesAndRows = true;
            }
            this.renderAlertBar();
        },
        allSelectedRows(value) {
            if (value) {
                if (this.allPagesAndRows) {
                    this.selectedRows = this.items;
                } else {
                    this.selectedRows = this.paginatedItems.map((item) => item);
                }
            } else {
                this.selectedRows = [];
            }
        },
        reset(value) {
            this.resetFilters(value);
        },
    },
    updated() {
        this.setMinWidth();
    },
    mounted() {
        this.setThinHeader();
        this.setMinWidth();

        // Set the initial rows
        if (this.loading || this.notification) {
            this.alertBar = true;
            this.alert.message = this.notification;
            this.alert.type = this.notificationType || 'info';
        }
        // set pagination if external data
        this.localPagination.rowsPerPage = this.rowsPerPage;
        this.initialRows = this.rowsPerPage;
        if (this.pagination) {
            this.localPagination.descending = this.pagination.descending;
        }
        if(this.columnsToShow.length === 0) {
            this.columnsToShow = this.fields;
        }
        window.addEventListener('scroll', this.handleHeader);
        if (this.lazyLoading) { window.addEventListener('scroll', this.handleLazyLoad) }
        const tableContainer = this.$refs.tableContainer;
        const resizeObserver = new ResizeObserver(() => {
            this.handleHeader();
        });
        resizeObserver.observe(tableContainer);
        // set initial sorting if available
        if (this.initialSorting) {
            const sortValue = this.columnsToShow.find(column => column.header === this.initialSorting);
            this.changeSort(sortValue);
            if (this.initialSortDirection === 'desc') { this.changeSort(sortValue) }
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleHeader);
        if (this.lazyLoading) { window.removeEventListener('scroll', this.handleLazyLoad) }
    },

    methods: {
        selectItem(item, value) {
            const selection = value ?? false;
            this.$emit('select-item', item, !selection);
        },
        setThinHeader() {
            const stackedField = [];
            this.fields.forEach(f => {
                if (f.type == 'stacked') {
                    stackedField.push(f);
                }
            });
            if (stackedField.length == 0 && !this.hasFilters) {
                const stickyWrapper = this.$refs.stickyWrapper;
                const headerPlaceholder = this.$refs.headerPlaceholder;
                stickyWrapper.classList.add('thin');
                headerPlaceholder.classList.add('thin');
            }
        },
        resetFilters(reset) {
            if (reset) {
                this.search = '';
                this.singleSearchData = {};
                this.singleSearchValue = '';

                // clear all single-column filters and multiple dropdowns
                const searchIds = [];
                const multipleIds = [];
                const singleIds = [];
                this.columnsToShow.forEach(f => {
                    if (f.filter === 'search') {
                        searchIds.push(f.id);
                    }
                    if (f.filter === 'multiple') {
                        multipleIds.push(f.id);
                    }
                    if (f.filter === 'single') {
                        singleIds.push(f.id);
                    }
                });
                searchIds.forEach(h =>{
                    this.$refs['input-'+h][0].value = '';
                });
                multipleIds.forEach(h =>{
                    this.$refs['multiple-'+h][0].reset();
                });
                singleIds.forEach(h =>{
                    this.$refs['single-'+h][0].reset();
                });

                this.currentPage = 1;
                this.changeSort();
            }
        },
        toggleExpand(id) {
            const currentRow = this.$refs['expand-'+id][0];
            currentRow.classList.toggle('expand-hidden');
            this.toggleArrowClass(id);
        },
        toggleArrowClass(id) {
            const currentArrow = this.$refs['arrow-'+id][0];
            currentArrow.classList.toggle('rotate-180');
        },
        selectedRow(ele) {
            if (!this.selectedRows.length) return false;
            return this.selectedRows.indexOf(ele) !== -1;
        },
        selectAllRows() {
            this.selectedRows = this.items.map((item) => item);
            this.allSelectedRows = true;
        },
        renderAlertBar() {
            if (this.selectedRows.length > 0) {
                this.alertBar = true;
                this.alert.type = 'info';
                this.alert.message = `${
                    this.selectedRows.length === this.items.length
                        ? `All rows (${this.selectedRows.length}) selected!`
                        : `${this.selectedRows.length} rows are currently selected.`
                }`;
            } else {
                this.alertBar = false;
            }
        },
        showNotification(res) {
            this.alert.type = res.response;
            this.alert.message = res.message;
            this.alertBar = true;
            setTimeout(() => {
                this.alert.message = '';
                this.alertBar = false;
            }, 4000);
        },
        onUpdatePreset(columns) {
            if(columns) {
                const columnIDs = [];
                columns.forEach(column => {
                    columnIDs.push(column.id);
                });
                const filteredFields = this.fields.filter(field => columnIDs.includes(field.id));
                this.columnsToShow = filteredFields.slice().sort(function(a, b) {
                    return columnIDs.indexOf(a.id) - columnIDs.indexOf(b.id);
                });
            } else {
                this.columnsToShow = this.fields;
            }
            this.tooltipKey += 1; // re-render tooltip for new position
        },
        changeSort(header) {
            const pagination = { ...this.localPagination };
            if (header) {
                this.sortingValue = header.value;
            }
            if(!header) {
                delete pagination['sortBy'];
                this.localPagination = pagination;
            } else if(header.sortable) {
                if (pagination.sortBy === header.id) {
                    pagination.descending = !pagination.descending;
                } else {
                    pagination.sortBy = header.id;
                    pagination.descending = false;
                }
                if (this.pagination) {
                    this.$emit('update-pagination', pagination);
                } else {
                    this.localPagination = pagination;
                }
            }
        },
        applySingleSearch: debounce(function(e, filter) {
            this.applyFiltering(e, filter);
        }, 500),
        onSingleSearch(e, filter) {
            if (this.backendFiltering) {
                this.applySingleSearch(e.target.value, filter);
            } else {
                this.singleSearchValue = e.target.value;
                this.singleSearchData[e.target.name] = e.target.value;
                // let's clear our search data if value is empty to avoid bugs
                if (e.target.value === '') {
                    delete this.singleSearchData[e.target.name];
                }
                this.search = '';
            }
        },
        clearSearch() {
            //fixing strange bug - when 'watch' is not triggered by setting empty value...
            this.search = 'clear';
            setTimeout(() => {
                this.search = '';
            }, 100);
        },
        onGlobalSearch(v) {
            if (this.pagination) {
                this.$emit('search', v);
            } else {
                this.search = v;
                // clearing all single-column filters
                const headerIds = [];
                this.columnsToShow.forEach(f => {
                    if (f.filter === 'search') {
                        headerIds.push(f.id);
                    }
                });
                headerIds.forEach(h =>{
                    this.$refs['input-'+h][0].value = '';
                });
                if (this.search) { this.singleSearchData = {} }
                this.singleSearchValue = '';
            }
        },
        currentSelection(headerValue) {
            if (headerValue === 'filtered_reason') {
                return this.activeReasons;
            }
            if (headerValue === 'filtered_channel') {
                return this.activeChannels;
            }
            else {
                return this.selectedFilters[headerValue];
            }
        },
        currentSearch(headerValue) {
            if (this.backendFiltering && this.selectedFilters[headerValue]) {
                if (this.selectedFilters[headerValue].length) {
                    return this.selectedFilters[headerValue];
                } else {
                    return '';
                }
            }
            else if (this.singleSearchData[headerValue]) {
                return this.singleSearchData[headerValue];
            }
            return '';
        },
        columnValueList(value) {
            if (this.backendFiltering) {
                const filter = this.customFilters.find(f => f[value]);
                if (filter) {
                    return filter[value];
                }
            }
            return this.items.map((item) => {
                return get(item, value);
            }).sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0));
        },
        applyFiltering(value, filter, closeOnClick) {
            this.$emit('update-filtering', value, filter);
            if (closeOnClick) {
                this.$refs['multiple-'+filter][0].blur();
            }
        },
        nextPage() {
            if (this.pagination) {
                const pagination = { ...this.localPagination };
                pagination.page = this.currentPage + 1;
                this.$emit('update-pagination', pagination, this.lazyLoading);
            } else if (this.hasNextPage) {
                this.currentPage++;
            }
        },
        previousPage() {
            if (this.pagination) {
                const pagination = { ...this.localPagination };
                pagination.page = this.currentPage - 1;
                this.$emit('update-pagination', pagination);
            } else if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        toggleRow(itemId) {
            this.searchedItems.map((item) => {
                if (item.id === itemId) {
                    item.show = item.show === false;
                }
            });
        },
        closeFloatingMenus() {
            const visibleMenus = document.getElementsByClassName('visible');
            visibleMenus.forEach(menu =>{
                menu.classList.remove('visible');
            });
        },
        renderVerticalMenuContent(id) {
            const currentMenu = this.$refs['menu-'+id][0];
            this.closeFloatingMenus();
            currentMenu.classList.toggle('visible');
        },
        hideMenu(event) {
            // prevent closing of floating menu if we click on it and close if we click elsewhere on page
            const clickedInsideMenu = [];
            event.composedPath().forEach(i =>{
                if (i.classList) {
                    if (i.classList.contains('floating-menu') || i.classList.contains('vertical-menu')) {
                        clickedInsideMenu.push('that was click inside menu!');
                    }
                }
            });
            if (clickedInsideMenu.length === 0) {
                this.closeFloatingMenus();
            }
        },
        resetPage() {
            this.currentPage = 1;
            this.localPagination.page = 1;
            this.$emit('update-pagination', this.localPagination);
        },
        setMinWidth() {
            const tableHeaderCells = Array.from(this.$refs.tableHeader.children);
            const bodyWrapper = this.$refs.tableWrap;
            bodyWrapper.style.minWidth = 'auto';
            const mainWrapper = bodyWrapper.parentElement;
            let totalWidth = 0;
            tableHeaderCells.forEach(cell =>{
                if (cell.classList.contains('select-all') || cell.classList.contains('placeholder')) {
                    return;
                } else {
                    const formatWidth = +(cell.style.width.replace('px', ''));
                    totalWidth += formatWidth;
                }
            });
            if (this.hasMenu) {
                totalWidth = totalWidth + 50;
            }
            if (this.selectable) {
                totalWidth = totalWidth + 75;
            }
            if (this.expandable) {
                totalWidth = totalWidth + 50;
            }
            if (totalWidth <= (bodyWrapper.offsetWidth + 1)) {
                bodyWrapper.style.minWidth = 'auto';
                mainWrapper.style.overflow = 'visible';
                mainWrapper.removeEventListener('scroll', this.verticalScroll);
            } else if (+(this.minWidth.replace('px', '')) < totalWidth) {
                bodyWrapper.style.minWidth = totalWidth + 'px';
                mainWrapper.style.overflow = 'auto';
                mainWrapper.addEventListener('scroll', this.verticalScroll);
            } else if (this.minWidth) {
                bodyWrapper.style.minWidth = this.minWidth;
                mainWrapper.style.overflow = 'auto';
                mainWrapper.addEventListener('scroll', this.verticalScroll);
            }
        },
        handleLazyLoad() {
            if (this.lazyLoading) {
                const lazyLoader = this.$refs.lazyLoader;
                const lazyLoaderPosition = lazyLoader.getBoundingClientRect().y;
                const windowHeight = window.innerHeight;
                if (lazyLoaderPosition <= windowHeight) {
                    if (this.hasNextPage && !this.loading) {
                        this.nextPage();
                    }
                }
            }
        },
        handleHeader() {
            // sticky header logic
            let margin = this.headerMargin;
            const filterRow = this.$refs.filterRow.$el;
            const stickyWrapper = this.$refs.stickyWrapper;
            const tableHeader = this.$refs.tableHeader;
            const tableContainer = this.$refs.tableContainer;
            const headerPlaceholder = this.$refs.headerPlaceholder;
            if (headerPlaceholder) {
            const headerPlaceholderOffset = headerPlaceholder.getBoundingClientRect().y - margin;
            const headerPlaceholderEdge = headerPlaceholder.getBoundingClientRect().x;
            let updatedEdge = null;
            if (stickyWrapper) {
                updatedEdge = headerPlaceholderEdge - stickyWrapper.getBoundingClientRect().x;
            }
            const headerWidth = headerPlaceholder.offsetWidth;
            const containerWidth = tableContainer.offsetWidth;
            const cellRow = this.$refs.cells;

            if (headerPlaceholderOffset <= 0) {
                stickyWrapper.classList.add('sticky');
                if (filterRow && this.stickyPanel) { filterRow.classList.add('sticky') }
                stickyWrapper.style.width = containerWidth + 'px';
                stickyWrapper.style.top = margin + 'px';
                tableHeader.style.width = headerWidth + 'px';
                tableHeader.style.left = updatedEdge + 'px';
                headerPlaceholder.classList.add('visible');

                // sync cell width with header cells
                if (this.selectable || this.expandable) {
                    const selectAllCell = this.$refs.selectAllCell;
                    const saWidth = cellRow.children[0].children[0].offsetWidth;
                    selectAllCell.style.width = saWidth + 'px';
                }
                if (this.hasMenu) {
                    const menuCell = this.$refs.menuCell;
                    const menuWidth = cellRow.children[0].lastChild.offsetWidth;
                    menuCell.style.width = menuWidth + 'px';
                }
            } else {
                stickyWrapper.classList.remove('sticky');
                if (filterRow && this.stickyPanel) { filterRow.classList.remove('sticky') }
                headerPlaceholder.classList.remove('visible');
                tableHeader.style.left = '';
                // unsync cell width with header cells
                if (this.selectable || this.expandable) {
                    const selectAllCell = this.$refs.selectAllCell;
                    selectAllCell.style.width = '';
                }
                if (this.hasMenu) {
                    const menuCell = this.$refs.menuCell;
                    menuCell.style.width = '';
                }
            }
            }
        },
        verticalScroll() {
            // this is for large overflowing tables that wider that users screen - for positioning sticky header
            const headerPlaceholder = this.$refs.headerPlaceholder;
            const stickyWrapper = this.$refs.stickyWrapper;
            if (headerPlaceholder && stickyWrapper.classList.contains('sticky')) {
                const headerPlaceholderEdge = headerPlaceholder.getBoundingClientRect().x;
                const updatedEdge = headerPlaceholderEdge - stickyWrapper.getBoundingClientRect().x;
                const tableHeader = this.$refs.tableHeader;
                tableHeader.style.left = updatedEdge + 'px';
            }
        },
    },
};
</script>

<style lang="scss">
.custom-table-container {
    margin: 20px auto 68px;
    padding: 5px;
    width: 100%;
    padding: 0 30px;
    .custom-table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        .header-placeholder{
            display: table-row;
            height: 0;
            &.visible{
                height: 75px;
            }
            &.visible.thin{
                height: 55px;
            }
        }
        .sticky-wrapper{
            &.sticky{
                position: fixed;
                z-index: 99;
                display: block;
                overflow: hidden;
                height: 80px;
                box-shadow: 0 9px 7px -7px rgba(0,0,0,0.25);
               .table-header{
                    background: white;
                    z-index: 99;
                    position:absolute;
                    display:table;
                    width: 100%;
                }
                .custom-alert-container{
                    position: absolute;
                    width: 100%;
                    z-index: 999;
                    top:40px;
                }
            }
            &.thin{
                height: 55px;
                .table-header {
                    height: 55px;
                }
                .custom-alert-container{
                    top: 12px;
                }
            }
        }
        .table-header {
            display: table-row;
            position: relative;
            height: 80px;
            .alert-wrapper{
                position: absolute;
                left:0;
                right:0;
                top:100%;
                z-index: 9;
            }
            .header-cell {
                display: table-cell;
                color: #6D797F;
                font-size: 12px;
                font-weight: 400;
                padding: 15px 15px 0 15px;
                vertical-align: top;
                position: relative;
                .label {
                    margin: 0;
                }
                .multiple-selected{
                    font-size: 12px;
                    white-space: nowrap;
                }
                .multiple-back-end-selected {
                    display: none;
                    margin-left: 3px;
                    &:last-of-type {
                        display: inline-block;
                    }
                }
                .filter{
                    margin-top:5px;
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    .v-input--selection-controls{
                        margin-top:20px;
                        padding-top:0;
                    }
                    .search-icon{
                        position: absolute;
                        font-size: 18px;
                        top: 4px;
                        left:3px;
                        opacity: 0.5;
                        margin-top: 0;
                    }
                }
                .styled-search{
                    outline:none;
                    padding-left: 25px;
                    border: 1px solid rgba(143, 158, 166, 0.5);
                    width: 100%;
                    height: 25px;
                }
                &.placeholder{
                    width: 50px;
                }
                &.select-all {
                    width: 100px;
                    padding-left: 20px;
                    .v-input {
                        margin: 0;
                        padding-left: 10px;
                    }
                    .single{
                        white-space: nowrap;
                    }
                }
                &.active {
                    color: $cape-code;
                }
                &.sortable {
                    .single {
                        cursor: pointer;
                        white-space: nowrap;
                        & > div > span{
                            display: inline-flex;
                        }
                        img{
                            width: 15px;
                            margin-left:5px;
                            transform: rotate(180deg);
                        }
                        .rotated{
                            transform: rotate(0);
                        }
                    }
                }
                .sorting-icon {
                    position: relative;
                    bottom: 3px;
                    left: 5px;
                }
                .stacked {
                    .top {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .table-row {
            .selection-cell {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-left: 30px;
                .v-input {
                    margin-right: 0;
                }
                .row-toggle {
                    margin-left: -15px;
                    margin-right: 15px;
                }
                .toggle {
                    display: flex;
                    align-items: center;
                }
            }

            &.alt-color {
                background-color: #F9FAFA;
            }
            &:has(> td.red-bg) {
                background-color: #FBE6E2;
            }
        }
        .expand-area {
            background-color: #EFF1F2;
        }
        .expandable-area{
            min-height: 80px;
            display: flex;
            align-items: center;
            padding: 0 60px;
        }
        .expand-hidden{
            display: none;
        }
    }

    .selected-row {
        background-color: $blue-100 !important;
    }

    .sorting-icon {
        cursor: pointer;
    }
    .column.sortable {
        cursor: pointer;
        &:hover {
            color: $gray-darkest;
        }
    }

    .rotate {
        transform: rotate(180deg);
    }
    .rotate-180 {
        transform: rotate(0);
    }

    .toggle {
        transition: transform 0.2s ease-in-out;
    }
    .vertical-menu {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding: 3px 20px;
        max-width: 55px;
        div{
            width: 8px;
            height: 8px;
            margin:3px;
            border-radius: 50%;
            background: #B3C2C9;
        }
        &:hover {
            cursor: pointer;
            div {
                background: #94999C;
            }
        }
    }
    .table-row {
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        .row-menu {
            text-align: right;
            position:relative;
            .floating-menu{
                position:absolute;
                background: white;
                border-radius: 5px;
                z-index: 88;
                min-width: 100px;
                right: 45px;
                top:0;
                padding: 25px;
                text-align: left;
                box-shadow: 0 0 10px 3px rgba(0,0,0,0.1);
                display: none;
                &:after{
                    content: '';
                    position:absolute;
                    width: 15px;
                    height: 15px;
                    transform: rotate(45deg);
                    right:-5px;
                    top:35px;
                    background: white;
                    box-shadow: 0 0 10px 3px rgba(0,0,0,0.1);
                    z-index: 2;
                }
                &:before{
                    content: '';
                    position:absolute;
                    width: 25px;
                    height: 40px;
                    right:0;
                    top:25px;
                    background: white;
                    z-index: 3;
                }
                &.visible{
                    display: block;
                }
            }
        }
    }

    .totals-row {
        background-color: $gray-lightshade;
        td > div {
            margin: 0.6rem 0;
        }

        td:first-child > div {
            text-align: end;
        }

        font-weight: 700;
        .column-total {
            padding: 10px 15px;
            text-align: right;
        }
        .footer-title {
            text-align: right;
            font-weight: bold;
        }

        .footer {
            background-color: $gray-lightshade;
            td > div {
                margin: 0.6rem 0;
            }
        }
        .average {
            color: $gray-primary;
            font-weight: 400;
            white-space: nowrap;
            font-size: 11px;
            display: flex;
            justify-content: center;
        }
    }
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 30px;
        position: relative;
        right: 0;
        left:0;
        bottom:0;
        &.modal-view {
            position: relative;
            margin-top: 0;
        }
        .controls {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .label,
            .selector,
            .currently-viewing,
            .prev-next {
                display: inline-block;
                margin: 0;
                margin-left: 60px;
            }
            .label {
                font-weight: 700;
            }
            .selector {
                width: 60px;
            }
            .prev,
            .next {
                padding: 0 30px;
                opacity: 0.25;
                cursor: not-allowed;
                pointer-events: none;
                &.active{
                    pointer-events: all;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
    .v-input--selection-controls__input {
        &:before {
            border: solid 2px #8F9EA6;
        }
    }
    .v-input--is-label-active {
        .v-input--selection-controls__input {
            &:before {
                border: none;
            }
        }
    }
    table .v-input--is-label-active .v-select__selection {
        position: absolute;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        font-size: 12px;
    }
    table .v-input--is-label-active .v-select__selections {
        overflow: hidden;
        position: relative;
        height: 22px;
    }
    table .styled-field.v-input.v-text-field.v-input.v-text-field input {
        position: absolute;
        font-size: 13px;
        margin-left: -5px;
    }
    table .v-select__selection--comma {
        padding-left: 0 !important;
    }
    .styled-field.v-input.v-text-field.v-input.v-text-field .v-input__append-inner:last-child {
        margin-right: 0;
        padding-left: 0;
    }
    .styled-field.v-input.v-text-field.v-input.v-text-field > .v-input__control {
        width: 100%;
    }
    .no-inventory {
        text-align: center;
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .styled-checkbox-checkmark {
        position: relative;
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        border: 2px solid;
        border-color: $gray;
        background-color: $white;
        transform-origin: center center;
        transform: scale(var(--checkmark-scale));
        transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
        &::after {
            content: '';
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 6px;
            height: 11px;
            border: solid $white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        &:hover {
            background-color: $alabaster-dark;
        }
        &.selected {
            background-color: $blue-btn;
            border-color: $blue-btn;
            &::after {
                display: block;
            }
        }
    }
    .filter-row-container.sticky + .filter-row-placeholder {
        margin-top: 70px;
    }
    .no-data-text {
        text-align: center;
        margin-top: 20vh;
        margin-bottom: 60px;
    }
}
</style>
