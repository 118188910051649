<template>
    <div>
        <div
            v-if="percentChange != Infinity"
            class="change"
            :class="metricDisposition">
            <icon
                v-if="percentChange !== 0"
                :class="chevronClass"
                name="chevron-right"
                :color="metricDisposition === 'good' ? '#A2CF75' : '#EE5643'"
                size="12" />
            <span>{{ Math.abs(percentChange) }}%</span>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';

export default {
    components: {
        Icon
    },
    props: {
        metricValue: {
            type: Number,
            default: 0,
        },
        previousMetricValue: {
            type: Number,
            default: 0,
        },
        changeReversed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        percentChange() {
            if (!this.metricValue && !this.previousMetricValue) {
                return 0;
            }
            return (((this.metricValue - this.previousMetricValue) / this.previousMetricValue) * 100).toFixed(2);
        },
        metricDisposition() {
            if (this.percentChange > 0 && !this.changeReversed) {
                return 'good';
            } else if (this.percentChange < 0 && !this.changeReversed) {
                return 'bad';
            } else if (this.percentChange < 0 && this.changeReversed) {
                return 'good';
            } else if (this.percentChange > 0 && this.changeReversed) {
                return 'bad';
            }
            return 'good';
        },
        chevronClass() {
            if (this.percentChange < 0) return 'chevron-down';
            return 'chevron-up';
        }
    }
};
</script>

<style lang="scss" scoped>
.change {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    &.bad {
        color: #EE5643;
    }
    &.good {
        color: #A2CF75;
    }
    .svg-icon {
        margin-right: 3px;
    }
}
.chevron-up {
    transform: rotate(-90deg);
}
.chevron-down {
    transform: rotate(90deg);
}
</style>