<template>
    <div
        class="date-range-picker date-range-picker--styled">
        <label>Previous Period</label>
        <date-range-picker
            :locale-data="{ firstDay: 1, format: 'MM-DD-YYYY' }"
            :show-week-numbers="true"
            :show-dropdowns="true"
            :auto-apply="true"
            :ranges="false"
            :max-date="maxDate"
            :date-range="dateRangeComparison"
            @update="handleUpdate">
            <template #input="picker">
                <span>{{ format(picker.startDate, 'MM-DD-YYYY') }} - {{ format(picker.endDate, 'MM-DD-YYYY') }}</span>
            </template>
        </date-range-picker>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import InitMetricsMixin from '@/mixins/init-metrics';
import { mapState } from 'vuex';
import { format, subDays } from 'date-fns';
import { SET_PREVIOUS_DATE_RANGE } from '@/store/mutation-types';

export default {
    components: {
        DateRangePicker
    },
    mixins: [
        InitMetricsMixin
    ],
    data() {
        return {
            format
        };
    },
    computed: {
        ...mapState({
            dateRange: (state) => state.metrics.dateRange,
            dateRangeComparison: (state) => state.metrics.dateRangeComparison,
        }),
        maxDate() {
            return subDays(this.dateRange.startDate, 1);
        }
    },
    methods: {
        handleUpdate(dateRange) {
            this.$store.commit(SET_PREVIOUS_DATE_RANGE, dateRange);

            // refresh metrics after the change
            this.loadMetrics();
        }
    }
};
</script>
