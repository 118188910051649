<template>
    <styled-interface>
        <template #interface-toolbar-external>
            <dealer-toolbar-global
                hide-comparison
                hide-interval />
        </template>

        <template #interface-heading>
            Inventory
        </template>
        <loader v-if="metricsInventoryLoading" />
        <template
            v-if="dealerHasAdProduct && !metricsInventoryLoading"
            #interface-heading-actions>
            <action-button
                icon="pdf"
                @click="showGenerateReportSlideout = true">
                download pdf
            </action-button>
        </template>

        <ads-upsell v-if="!dealerHasAdProduct && !metricsInventoryLoading" />

        <styled-card
            v-if="dealerHasAdProduct && !metricsInventoryLoading"
            class="mb-4">
            <template #heading>
                Vehicle Advertising
            </template>

            <vehicle-advertising
                v-if="!showLoader"
                :value="summaryData"
                class="mx-5"
                @showVehiclePanel="showVehiclePanel" />
        </styled-card>

        <generate-report-slideout
            v-model="showGenerateReportSlideout"
            :template-id="FACEBOOK_REPORT_ID" />
        <vehicle
            :id="panelVehicleID"
            v-model="showPanelVehicleDetail"
            @input="showPanelVehicleDetail = false" />
        <styled-card
            v-if="dealerHasAdProduct && !metricsInventoryLoading"
            class="mb-4">
            <template #heading>
                Inventory Details
            </template>

            <template #action-buttons>
                <action-button
                    icon="pdf"
                    @click="showGenerateReportSlideout = true">
                    download pdf
                </action-button>
                <action-button
                    icon="download"
                    @click="exportCSV()">
                    Export CSV
                </action-button>
            </template>

            <progressbar-timed
                v-model="loadingVehicles"
                class="pa-5"
                @complete="showLoader = false">
                {{ progressMessage }}
            </progressbar-timed>
            <custom-table
                v-if="!showLoader"
                id="inventory-details-table"
                name="inventory-details-table"
                min-width="1200px"
                :items="vehiclesFormatted"
                :fields="fields"
                :loading="loadingVehicles"
                :rows-per-page="25"
                :header-margin="140"
                notification=""
                notification-type=""
                hide-search
                hide-sort
                has-filters />
        </styled-card>
    </styled-interface>
</template>

<script>
import Loader from '@/components/globals/Loader.vue';
import DealerToolbarGlobal from '@/components/globals/DealerToolbarGlobal';
import ActionButton from '@/components/globals/ActionButton';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import AdsUpsell from '@/components/globals/AdsUpsell';
import ProgressbarTimed from '@/components/globals/ProgressbarTimed';
import VehicleAdvertising from './VehicleAdvertising';
import formatInsightsData from '@/apis/facebook/formatInsightsData';
import getDealerInventoryInsights from '@/apis/facebook/getDealerInventoryInsights';
import getDealerVehiclesById from '@/apis/inventory/getDealerVehiclesById';
import GenerateReportSlideout from '@/components/report-builder/GenerateReportSlideout';
import Vehicle from '../inventory/Vehicle';
import { ExportToCsv } from 'export-to-csv';
import { mapState, mapGetters } from 'vuex';
import { SET_FB_DEALER_CAMPAIGNS_SELECTED } from '@/store/mutation-types';
import { FACEBOOK_REPORT_ID } from '@/helpers/globals';
import { differenceInDays } from 'date-fns';
import numeral from 'numeral';
import getNumberFormats from '@/helpers/numberFormats';

const attributionWindows = ['1d_view', '1d_click', '7d_click'];

export default {
    name: 'ReportsVehiclePerformance',
    components: {
        Loader,
        DealerToolbarGlobal,
        ActionButton,
        AdsUpsell,
        ProgressbarTimed,
        StyledCard,
        StyledInterface,
        CustomTable,
        VehicleAdvertising,
        GenerateReportSlideout,
        Vehicle,
    },
    title: 'Performance Report',
    data() {
        return {
            FACEBOOK_REPORT_ID,
            numberFormats: getNumberFormats(),
            fields: [
                {
                    id: 'image',
                    header: '',
                    value: '',
                    align: 'left',
                    width: '130px',
                    type: 'component',
                    component: () => import('@/components/reports/VehicleImage.vue'),
                },
                {
                    id: 'vin',
                    header: 'VIN',
                    value: 'vin',
                    align: 'left',
                    width: '180px',
                    sortable: true,
                    filter: 'search',
                    type: 'component',
                    component: () => import('@/components/reports/VehicleLink.vue'),
                    callback: (item) => {
                        this.showVehiclePanel(item);
                    }
                },
                {
                    id: 'condition',
                    header: 'Condition',
                    value: 'condition',
                    align: 'left',
                    width: '145px',
                    sortable: true,
                    filter: 'multiple',
                    type: 'single',
                },
                {
                    id: 'year',
                    header: 'Year',
                    value: 'year',
                    align: 'left',
                    width: '145px',
                    sortable: true,
                    filter: 'multiple',
                    type: 'single',
                },
                {
                    id: 'make_name',
                    header: 'Make',
                    value: 'make_name',
                    align: 'left',
                    width: '145px',
                    sortable: true,
                    filter: 'multiple',
                    type: 'single',
                },
                {
                    id: 'model_name',
                    header: 'Model',
                    value: 'model_name',
                    align: 'left',
                    width: '145px',
                    sortable: true,
                    filter: 'multiple',
                    type: 'single',
                },
                {
                    id: 'trim_name',
                    header: 'Trim',
                    value: 'trim_name',
                    align: 'left',
                    width: '145px',
                    sortable: true,
                    filter: 'multiple',
                    type: 'single',
                },
                {
                    id: 'price',
                    header: 'Price',
                    value: 'price',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'spend',
                    header: 'Spend',
                    value: 'spend.formatted',
                    align: 'left',
                    width: '110px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'impressions',
                    header: {
                        top: 'Impressions',
                        bottom: 'CPM',
                    },
                    value: {
                        top: 'impressions.formatted',
                        bottom: 'cpm.formatted',
                    },
                    align: 'right',
                    width: '110px',
                    type: 'stacked',
                },
                {
                    id: 'clicks',
                    header: {
                        top: 'Clicks',
                        bottom: 'CPC',
                    },
                    value: {
                        top: 'clicks.formatted',
                        bottom: 'cost_per_click.formatted',
                    },
                    align: 'right',
                    width: '110px',
                    type: 'stacked',
                },
                {
                    id: 'form_leads',
                    header: {
                        top: 'Form Leads',
                        bottom: 'CPFL',
                    },
                    value: {
                        top: 'form_leads.formatted',
                        bottom: 'cost_per_form_lead.formatted',
                    },
                    align: 'right',
                    width: '110px',
                    type: 'stacked',
                },
                {
                    id: 'days_in_lot',
                    header: 'Days In Lot',
                    value: 'days_in_lot',
                    align: 'right',
                    width: '120px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'days_in_buyerbridge',
                    header: 'Days In BuyerBridge',
                    value: 'days_in_buyerbridge',
                    align: 'center',
                    width: '150px',
                    sortable: true,
                    type: 'single',
                },
                {
                    id: 'status',
                    header: 'Status',
                    value: 'status',
                    align: 'center',
                    width: '145px',
                    filter: 'multiple',
                    type: 'component',
                    component: () => import('@/components/reports/VehicleStatus.vue'),
                },
            ],
            filters: {
                make: [],
                model: [],
                condition: [],
                status: [],
            },
            vehicles: [],
            loadingVehicles: false,
            loadingProgress: 0,
            showLoader: false,
            progressMessage: '',
            vehicleInsights: [],
            summaryData: null,
            showGenerateReportSlideout: false,
            panelVehicleID: null,
            showPanelVehicleDetail: false,
            days_in_buyerbridge_list: [],
            topImpressions: 0,
            topContentViews: 0,
            topClicks: 0,
            topLeads: 0,
            topFormLeads: 0,
            avgSpendPerVINDataNewCount: 0,
            avgSpendPerVINDataUsedCount: 0,
            avgDaysAdvertisedDataNewCount: 0,
            avgDaysAdvertisedDataUsedCount: 0,
            avgVDPPerVINDataNewCount: 0,
            avgVDPPerVINDataUsedCount: 0,
            avgFormLeadsNewCount: 0,
            avgFormLeadsUsedCount: 0
        };
    },
    computed: {
        ...mapGetters([
            'dealerHasAdProduct'
        ]),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend',
            spendOverride: 'metrics/spendOverride',
        }),
        ...mapState({
            metricsInventoryLoading: (state) => state.metrics.facebook.ads.metricsInventoryLoading,
            currentDealerId: (state) => state.dealer.currentDealerId,
            dateRange: (state) => state.metrics.dateRange,
            attribution: (state) => state.metrics.facebook.ads.attribution,
            campaignsSelected: (state) => state.metrics.facebook.ads.campaignsSelected
        }),
        headers() {
            return this.availableHeaders.filter(header => {
                return this.hideSpend ? (header.value !== 'spend') : true;
            });
        },
        vehiclesFormatted() {
            if(this.loadingVehicles) return [];
            this.initialData();
            const res = this.vehicleInsights.reduce((allInsights, insights) => {
                const vehicleId = insights.product_id.split(',')?.[0];
                const vehicle = this.vehicles.find(vehicle =>
                    vehicle._source.vehicle_export_id == vehicleId
                );

                if (!vehicle) {
                    return allInsights;
                }

                const insightsFormatted = formatInsightsData(insights, {
                    spendOverride: this.spendOverride,
                    attribution: this.attribution,
                    attributionWindows,
                    offlineEventTypes: [] // disable
                });

                const source = vehicle._source;

                const customFields = {
                    trim_name: source.trim_name ? source.trim_name : '--',
                    price: numeral(source.price).format(this.numberFormats.currencyRound),
                    image: vehicle.fields.image[0],
                    condition: this.getVehicleCondition(source),
                    status: source.deleted_at ? 'Sold' : 'For Sale',
                    days_in_lot: source.date_first_on_lot ? differenceInDays(this.dateRange.endDate, source.date_first_on_lot) : 'N/A',
                    days_in_buyerbridge: source.created_at ? differenceInDays(this.dateRange.endDate, source.created_at) : 'N/A',
                    content_views: insightsFormatted.content_views.value + insightsFormatted.on_site_content_views.value
                };

                const insightValue = {
                    ...source,
                    ...insightsFormatted,
                    ...customFields
                };

                allInsights.push(insightValue);
                this.updateStatsData(insightValue);
                return allInsights;
            }, []);

            this.updateStatsAvgData(res.length);
            return res;
        }
    },
    watch: {
        campaignsSelected() {
            this.getVehicleInsights();
        },
        metricsInventoryLoading() {
            this.getVehicleInsights();
        },
        currentDealerId() {
            // clear previously selected campaigns when changing dealer
            this.$store.commit(SET_FB_DEALER_CAMPAIGNS_SELECTED, []);
            this.getVehicleInsights();
        },
        dateRange() {
            this.getVehicleInsights();
        }
    },
    created() {
        this.getVehicleInsights();
    },
    methods: {
        initialData() {
            this.summaryData = {
                statsData: {
                    topImpressions: '',
                    topContentViews: '',
                    topClicks: '',
                    topLeads: '',
                    topFormLeads: ''
                },

                statsIcon: {
                    topImpressions: '',
                    topContentViews: '',
                    topClicks: '',
                    topLeads: '',
                    topFormLeads: '',
                },

                vehicleId: {
                    topImpressions: '',
                    topContentViews: '',
                    topClicks: '',
                    topLeads: '',
                    topFormLeads: ''
                },

                avgSpendPerVINData: {
                    used: 0,
                    new: 0,
                    total:0,
                    avg: 0,
                },
                avgDaysAdvertisedData: {
                    new: 0,
                    used: 0,
                    range: 0,
                    avg: 0,
                },
                avgVDPPerVINData: {
                    new: 0,
                    used: 0,
                    total: 0,
                    avg: 0,
                },
                avgFormLeads: {
                    new: 0,
                    used: 0,
                    total: 0,
                    avg: 0,
                }
            };

            this.days_in_buyerbridge_list = [];

            this.avgSpendPerVINDataNewCount = 0;
            this.avgDaysAdvertisedDataNewCount = 0;
            this.avgVDPPerVINDataNewCount = 0;
            this.avgSpendPerVINDataUsedCount = 0;
            this.avgDaysAdvertisedDataUsedCount = 0;
            this.avgVDPPerVINDataUsedCount = 0;
            this.topContentViews = 0;
            this.topImpressions = 0;
            this.topLeads = 0;
            this.topFormLeads = 0;
            this.topClicks = 0;
        },
        updateStatsAvgData(count) {
            if (count == 0) return;

            this.summaryData.avgSpendPerVINData.avg = this.summaryData.avgSpendPerVINData.total / count;
            this.summaryData.avgDaysAdvertisedData.avg = this.summaryData.avgDaysAdvertisedData.avg / count;
            this.summaryData.avgDaysAdvertisedData.range = `${Math.min.apply(Math, this.days_in_buyerbridge_list)} - ${Math.max.apply(Math, this.days_in_buyerbridge_list)}`;
            this.summaryData.avgVDPPerVINData.avg = this.summaryData.avgVDPPerVINData.total / count;
            this.summaryData.avgFormLeads.avg = this.summaryData.avgFormLeads.total / count;

            this.summaryData.avgSpendPerVINData.new = this.summaryData.avgSpendPerVINData.new / this.avgSpendPerVINDataNewCount;
            this.summaryData.avgDaysAdvertisedData.new = this.summaryData.avgDaysAdvertisedData.new / this.avgDaysAdvertisedDataNewCount;
            this.summaryData.avgVDPPerVINData.new = this.summaryData.avgVDPPerVINData.new / this.avgVDPPerVINDataNewCount;
            this.summaryData.avgFormLeads.new = this.summaryData.avgFormLeads.new / this.avgFormLeadsNewCount;

            this.summaryData.avgSpendPerVINData.used = this.summaryData.avgSpendPerVINData.used / this.avgSpendPerVINDataUsedCount;
            this.summaryData.avgDaysAdvertisedData.used = this.summaryData.avgDaysAdvertisedData.used / this.avgDaysAdvertisedDataUsedCount;
            this.summaryData.avgVDPPerVINData.used = this.summaryData.avgVDPPerVINData.used / this.avgVDPPerVINDataUsedCount;
            this.summaryData.avgFormLeads.used = this.summaryData.avgFormLeads.used / this.avgFormLeadsUsedCount;
        },
        updateStatsData(insightValue) {
            this.summaryData.avgSpendPerVINData.total += insightValue.spend.value;
            this.summaryData.avgDaysAdvertisedData.avg += insightValue.days_in_buyerbridge;
            this.summaryData.avgVDPPerVINData.total += insightValue.content_views;
            this.summaryData.avgFormLeads.total += insightValue.form_leads.value;

            if (insightValue.days_in_buyerbridge != 0) {
                this.days_in_buyerbridge_list.push(insightValue.days_in_buyerbridge);
            }

            if (insightValue.condition === 'New') {
                this.summaryData.avgSpendPerVINData.new += insightValue.spend.value;
                this.avgSpendPerVINDataNewCount += 1;

                this.summaryData.avgDaysAdvertisedData.new += insightValue.days_in_buyerbridge;
                this.avgDaysAdvertisedDataNewCount += 1;

                this.summaryData.avgVDPPerVINData.new += insightValue.content_views;
                this.avgVDPPerVINDataNewCount += 1;

                this.summaryData.avgFormLeads.new += insightValue.form_leads.value;
                this.avgFormLeadsNewCount += 1;
            } else if ((insightValue.condition === 'Used') || (insightValue.condition === 'CPO')) {
                this.summaryData.avgSpendPerVINData.used += insightValue.spend.value;
                this.avgSpendPerVINDataUsedCount += 1;

                this.summaryData.avgDaysAdvertisedData.used += insightValue.days_in_buyerbridge;
                this.avgDaysAdvertisedDataUsedCount += 1;

                this.summaryData.avgVDPPerVINData.used += insightValue.content_views;
                this.avgVDPPerVINDataUsedCount += 1;

                this.summaryData.avgFormLeads.used += insightValue.form_leads.value;
                this.avgFormLeadsUsedCount += 1;
            }

            if (insightValue.content_views > this.topContentViews) {
                this.topContentViews = insightValue.content_views;
                this.summaryData.statsData.topContentViews = `${insightValue.year} ${insightValue.make_name} ${insightValue.model_name}`;
                this.summaryData.statsIcon.topContentViews = insightValue.body_name;
                this.summaryData.vehicleId.topContentViews = insightValue.vehicle_id;
            }

            if (insightValue.impressions.value > this.topImpressions) {
                this.topImpressions = insightValue.impressions.value;
                this.summaryData.statsData.topImpressions = `${insightValue.year} ${insightValue.make_name} ${insightValue.model_name}`;
                this.summaryData.statsIcon.topImpressions = insightValue.body_name;
                this.summaryData.vehicleId.topImpressions = insightValue.vehicle_id;
            }

            if (insightValue.leads.value > this.topLeads) {
                this.topLeads = insightValue.leads.value;
                this.summaryData.statsData.topLeads = `${insightValue.year} ${insightValue.make_name} ${insightValue.model_name}`;
                this.summaryData.statsIcon.topLeads = insightValue.body_name;
                this.summaryData.vehicleId.topLeads = insightValue.vehicle_id;
            }

            if (insightValue.clicks.value > this.topClicks) {
                this.topClicks = insightValue.clicks.value;
                this.summaryData.statsData.topClicks = `${insightValue.year} ${insightValue.make_name} ${insightValue.model_name}`;
                this.summaryData.statsIcon.topClicks = insightValue.body_name;
                this.summaryData.vehicleId.topClicks = insightValue.vehicle_id;
            }

            if (insightValue.form_leads.value > this.topFormLeads) {
                this.topFormLeads = insightValue.form_leads.value;
                this.summaryData.statsData.topFormLeads = `${insightValue.year} ${insightValue.make_name} ${insightValue.model_name}`;
                this.summaryData.statsIcon.topFormLeads = insightValue.body_name;
                this.summaryData.vehicleId.topFormLeads = insightValue.vehicle_id;
            }
        },
        async getVehicleInsights() {
            try {
                // Get Insights From FB
                this.loadingVehicles = true;
                this.showLoader = true;
                this.progressMessage = 'Loading data from Meta...';
                this.vehicleInsights = [];
                this.vehicles = [];

                const dealerId = this.currentDealerId;
                const { startDate, endDate } = this.dateRange;

                const config = {
                    dealerId,
                    startDate,
                    endDate,
                    attributionWindows,
                    campaigns: this.campaignsSelected.map(campaign => campaign.id)
                };
                if(this.noData) {
                    return false;
                }
                const vehicleInsights = await getDealerInventoryInsights(config);

                this.progressMessage = 'Almost there, get ready!';

                const vehicleIds = vehicleInsights.map(vehicle => {
                    return vehicle.product_id.split(',')?.[0];
                });

                const dealerVehiclesResponse = await getDealerVehiclesById({ vehicleIds, dealerId });
                this.vehicleInsights = vehicleInsights;
                this.vehicles = dealerVehiclesResponse.data.hits.hits;
            } catch (error) {
                console.error('Error loading vehicle data', error.response || error);
            } finally {
                this.loadingVehicles = false;
            }
        },
        getVehicleCondition(vehicle) {
            if (vehicle.certified) {
                return 'CPO';
            }
            return vehicle.used ? 'Used' : 'New';
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `vehicle_performance_${new Date().getTime()}`
            };

            const csvExporter = new ExportToCsv(options);
            const result = this.vehiclesFormatted.map(vehicle => {

                return {
                    vin: vehicle.vin,
                    condition: vehicle.condition,
                    year: vehicle.year,
                    make: vehicle.make_name,
                    model: vehicle.model_name,
                    trim: vehicle.trim_name,
                    price: this.$options.filters.numFormat(vehicle.price, '$0,0') ,
                    spend: vehicle.spend.formatted,
                    impressions: vehicle.impressions.formatted,
                    clicks: vehicle.clicks.formatted,
                    content_views: vehicle.content_views,
                    form_leans: vehicle.form_leads.formatted,
                    website_leads: vehicle.website_leads.formatted,
                    leads: vehicle.leads.formatted,
                    days_in_lot: vehicle.days_in_lot,
                    days_in_buyerbridge: vehicle.days_in_buyerbridge,
                    status: vehicle.status
                };
            });
            csvExporter.generateCsv(result);
        },
        showVehiclePanel(vehicle) {
            this.panelVehicleID = vehicle.vehicle_id;
            this.showPanelVehicleDetail = true;
        },
    }
};
</script>

<style lang="scss">
#inventory-details-table {
    .table-row > .table-cell:last-of-type {
        background-color: rgba(143, 158, 166, 0.1);
    }
}
</style>

