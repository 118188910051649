<template>
    <styled-card
        class="mt-4 mb-4 mx-3 gray-icons flex">
        <template #heading>
            Meta Ads Overall Stats
        </template>

        <template #action-buttons>
            <action-button
                class="mx-2"
                icon="download"
                @click="exportOverallStatsCSV()">
                Export
            </action-button>
        </template>

        <loader
            v-if="loadingIntervalInsights"
            class="py-5 bb-1-loblolly" />
        <div
            v-else-if="metricsChartData.length"
            class="px-5 pb-5 pt-4 bb-1-loblolly advertising-graph">
            <advertising-metrics-chart
                :select-campaigns="campaignsSelected"
                :date-range="dateRange"
                :dealer-id="currentDealerId"
                :left-metric="leftSelectMetric"
                :right-metric="rightSelectMetric"
                :metrics-chart-data="metricsChartData"
                @update="updateMetric" />
        </div>

        <loader
            v-if="loadingAccountInsights"
            class="mb-5" />

        <v-layout
            v-else-if="metricTotals"
            row
            wrap
            class="metric-items pa-5">
            <v-flex
                :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                class="sm6 xs12">
                <market-stats
                    title="REACH"
                    :metric-string="metricTotals.reach.formatted"
                    :metric-value="metricTotals.reach.value"
                    :previous-metric-value="showChange ? metricTotalsComparison.reach.value : 0"
                    :show-change="showChange"
                    :change-reversed="false"
                    :tool-tip-message="$t('metrics.reach')"
                    :class="{'select-left': leftSelectMetric === 'reach', 'select-right': rightSelectMetric === 'reach'}"
                    @click.native="clickMetric('reach')" />
            </v-flex>
            <v-flex
                :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                class="sm6 xs12">
                <market-stats
                    title="IMPRESSIONS"
                    :metric-string="metricTotals.impressions.formatted"
                    :metric-value="metricTotals.impressions.value"
                    :previous-metric-value="showChange ? metricTotalsComparison.impressions.value : 0"
                    :show-change="showChange"
                    :change-reversed="false"
                    :tool-tip-message="$t('metrics.impressions')"
                    :class="{'select-left': leftSelectMetric === 'impressions', 'select-right': rightSelectMetric === 'impressions'}"
                    @click.native="clickMetric('impressions')" />
            </v-flex>
            <v-flex
                :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                class="sm6 xs12">
                <market-stats
                    title="CLICKS"
                    :metric-string="metricTotals.clicks.formatted"
                    :metric-value="metricTotals.clicks.value"
                    :previous-metric-value="showChange ? metricTotalsComparison.clicks.value : 0"
                    :show-change="showChange"
                    :change-reversed="false"
                    :tool-tip-message="$t('metrics.clicks')"
                    :class="{'select-left': leftSelectMetric === 'clicks', 'select-right': rightSelectMetric === 'clicks'}"
                    @click.native="clickMetric('clicks')" />
            </v-flex>
            <v-flex
                :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                class="sm6 xs12">
                <market-stats
                    title="OUTBOUND CLICKS"
                    :metric-string="metricTotals.outbound_clicks.formatted"
                    :metric-value="metricTotals.outbound_clicks.value"
                    :previous-metric-value="showChange ? metricTotalsComparison.outbound_clicks.value : 0"
                    :show-change="showChange"
                    :change-reversed="false"
                    :tool-tip-message="$t('metrics.outbound_clicks')"
                    :class="{'select-left': leftSelectMetric === 'outbound_clicks', 'select-right': rightSelectMetric === 'outbound_clicks'}"
                    @click.native="clickMetric('outbound_clicks')" />
            </v-flex>
            <v-flex
                :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                class="sm6 xs12">
                <market-stats
                    title="LANDING PAGE VIEWS"
                    :metric-string="metricTotals.landing_page_views.formatted"
                    :metric-value="metricTotals.landing_page_views.value"
                    :previous-metric-value="showChange ? metricTotalsComparison.landing_page_views.value : 0"
                    :show-change="showChange"
                    :change-reversed="false"
                    :tool-tip-message="$t('metrics.landing_page_views')"
                    :class="{'select-left': leftSelectMetric === 'landing_page_views', 'select-right': rightSelectMetric === 'landing_page_views'}"
                    @click.native="clickMetric('landing_page_views')" />
            </v-flex>
            <v-flex
                :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                class="sm6 xs12">
                <market-stats
                    title="TOTAL LEADS"
                    :metric-string="metricTotals.leads.formatted"
                    :metric-value="metricTotals.leads.value"
                    :previous-metric-value="showChange ? metricTotalsComparison.leads.value : 0"
                    :show-change="showChange"
                    :change-reversed="false"
                    :tool-tip-message="$t('metrics.total_leads')"
                    :class="{'select-left': leftSelectMetric === 'leads', 'select-right': rightSelectMetric === 'leads'}"
                    @click.native="clickMetric('leads')" />
            </v-flex>
            <v-flex
                v-if="canViewOfflinePurchaseAttribution"
                :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                class="sm6 xs12">
                <market-stats
                    title="SALES"
                    :metric-string="metricTotals.offline_purchase.formatted"
                    :metric-value="metricTotals.offline_purchase.value"
                    :previous-metric-value="showChange ? metricTotalsComparison.offline_purchase.value : 0"
                    :show-change="showChange"
                    :change-reversed="false"
                    :tool-tip-message="$t('metrics.offline_purchase')"
                    :class="{'select-left': leftSelectMetric === 'offline_purchase', 'select-right': rightSelectMetric === 'offline_purchase'}"
                    @click.native="clickMetric('leads')" />
            </v-flex>
            <template v-if="!hideSpend">
                <v-flex
                    :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                    class="sm6 xs12">
                    <market-stats
                        title="SPEND"
                        :metric-string="metricTotals.spend.formatted"
                        :metric-value="metricTotals.spend.value"
                        :previous-metric-value="showChange ? metricTotalsComparison.spend.value : 0"
                        :show-change="showChange"
                        :change-reversed="false"
                        :tool-tip-message="$t('metrics.spend')"
                        :class="{'select-left': leftSelectMetric === 'spend', 'select-right': rightSelectMetric === 'spend'}"
                        @click.native="clickMetric('spend')" />
                </v-flex>
                <v-flex
                    :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                    class="sm6 xs12">
                    <market-stats
                        title="CPM"
                        :metric-string="metricTotals.cpm.formatted"
                        :metric-value="metricTotals.cpm.value"
                        :previous-metric-value="showChange ? metricTotalsComparison.cpm.value : 0"
                        :show-change="showChange"
                        :tool-tip-message="$t('metrics.cpm')"
                        :class="{'select-left': leftSelectMetric === 'cpm', 'select-right': rightSelectMetric === 'cpm'}"
                        @click.native="clickMetric('cpm')" />
                </v-flex>
                <v-flex
                    :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                    class="sm6 xs12">
                    <market-stats
                        title="CPC"
                        :metric-string="metricTotals.cost_per_click.formatted"
                        :metric-value="metricTotals.cost_per_click.value"
                        :previous-metric-value="showChange ? metricTotalsComparison.cost_per_click.value : 0"
                        :show-change="showChange"
                        :tool-tip-message="$t('metrics.cpc')"
                        :class="{'select-left': leftSelectMetric === 'cost_per_click', 'select-right': rightSelectMetric === 'cost_per_click'}"
                        @click.native="clickMetric('cost_per_click')" />
                </v-flex>
                <v-flex
                    :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                    class="sm6 xs12">
                    <market-stats
                        title="CPOC"
                        :metric-string="metricTotals.cost_per_outbound_click.formatted"
                        :metric-value="metricTotals.cost_per_outbound_click.value"
                        :previous-metric-value="showChange ? metricTotalsComparison.cost_per_outbound_click.value : 0"
                        :show-change="showChange"
                        :tool-tip-message="$t('metrics.cpoc')"
                        :class="{'select-left': leftSelectMetric === 'cost_per_outbound_click', 'select-right': rightSelectMetric === 'cost_per_outbound_click'}"
                        @click.native="clickMetric('cost_per_outbound_click')" />
                </v-flex>
                <v-flex
                    :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                    class="sm6 xs12">
                    <market-stats
                        title="CPLPV"
                        :metric-string="metricTotals.cost_per_landing_page_view.formatted"
                        :metric-value="metricTotals.cost_per_landing_page_view.value"
                        :previous-metric-value="showChange ? metricTotalsComparison.cost_per_landing_page_view.value : 0"
                        :show-change="showChange"
                        :tool-tip-message="$t('metrics.cplpv')"
                        :class="{'select-left': leftSelectMetric === 'cost_per_landing_page_view', 'select-right': rightSelectMetric === 'cost_per_landing_page_view'}"
                        @click.native="clickMetric('cost_per_landing_page_view')" />
                </v-flex>
                <v-flex
                    :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                    class="sm6 xs12">
                    <market-stats
                        title="CPL"
                        :metric-string="metricTotals.cost_per_lead.formatted"
                        :metric-value="metricTotals.cost_per_lead.value"
                        :previous-metric-value="showChange ? metricTotalsComparison.cost_per_lead.value : 0"
                        :show-change="showChange"
                        :tool-tip-message="$t('metrics.cpl')"
                        :class="{'select-left': leftSelectMetric === 'cost_per_lead', 'select-right': rightSelectMetric === 'cost_per_lead'}"
                        @click.native="clickMetric('cost_per_lead')" />
                </v-flex>
                <v-flex
                    v-if="canViewOfflinePurchaseAttribution"
                    :class="canViewOfflinePurchaseAttribution ? 'custom' : 'md2'"
                    class="sm6 xs12">
                    <market-stats
                        title="CPS"
                        :metric-string="metricTotals.cost_per_offline_purchase.formatted"
                        :metric-value="metricTotals.cost_per_offline_purchase.value"
                        :previous-metric-value="showChange ? metricTotalsComparison.cost_per_offline_purchase.value : 0"
                        :show-change="showChange"
                        :tool-tip-message="$t('metrics.cost_per_offline_purchase')"
                        :class="{'select-left': leftSelectMetric === 'cost_per_offline_purchase', 'select-right': rightSelectMetric === 'cost_per_offline_purchase'}"
                        @click.native="clickMetric('cost_per_lead')" />
                </v-flex>
            </template>
        </v-layout>
    </styled-card>
</template>

<script>
import StyledCard from '@/components/globals/StyledCard';
import ActionButton from '@/components/globals/ActionButton';
import MarketStats from '@/components/globals/MarketStats';
import Loader from '@/components/globals/Loader.vue';
import AdvertisingMetricsChart from './AdvertisingMetricsChart';
import { ExportToCsv } from 'export-to-csv';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        ActionButton,
        MarketStats,
        Loader,
        AdvertisingMetricsChart,
        StyledCard,
    },
    data() {
        return {
            leftSelectMetric: '',
            rightSelectMetric: '',
            compareSelect: 0,
        };
    },
    computed: {
        ...mapGetters([
            'dealerAdSpendOverride',
            'userIsClient'
        ]),
        ...mapGetters('metrics/facebook/ads', {
            metricTotals: 'metricsTotalsFormatted',
            metricsChartData: 'metricsIntervalsFormatted',
            metricTotalsComparison: 'metricsTotalsComparisonFormatted',
            canViewOfflinePurchaseAttribution: 'canViewOfflinePurchaseAttribution',
        }),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend'
        }),
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            currentDealer: (state) => state.dealer.currentDealer,
            dateRange: (state) => state.metrics.dateRange,
            campaignsSelected: (state) => state.metrics.facebook.ads.campaignsSelected,
            loadingAccountInsights: (state) => state.metrics.facebook.ads.metricsTotalsLoading,
            loadingIntervalInsights: (state) => state.metrics.facebook.ads.metricsIntervalsLoading,
            comparisonVisible: (state) => state.metrics.comparisonVisible,
        }),
        showChange() {
            return !this.userIsClient && !!this.metricTotalsComparison && this.comparisonVisible;
        }
    },
    watch: {
        dealerAdSpendOverride: {
            handler() {
                this.setDefaultMetricSelections();
            },
            immediate: true
        },
        hideSpend() {
            this.setDefaultMetricSelections();
        }
    },
    methods: {
        setDefaultMetricSelections() {
            this.leftSelectMetric = 'reach';
            this.rightSelectMetric = this.dealerAdSpendOverride?.type == 'no_spend' ? 'impressions' : 'spend';
        },
        updateMetric(v) {
            this.leftSelectMetric = v[0];
            this.rightSelectMetric = v[1];
        },
        clickMetric(metric_id) {
            if (this.compareSelect == 0) {
                if (this.rightSelectMetric == metric_id)
                    return;

                this.leftSelectMetric = metric_id;
                this.compareSelect = !this.compareSelect;
            } else {
                if (this.leftSelectMetric == metric_id)
                    return;

                this.rightSelectMetric = metric_id;
                this.compareSelect = !this.compareSelect;
            }
        },
        exportOverallStatsCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                title: 'Campaign Stats',
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `campaign_stats_${new Date().getTime()}.csv`
            };
            const csvExporter = new ExportToCsv(options);
            const exportData = this.metricsChartData.map(campaign => {
                const newData = {};
                newData['dealer_name'] = this.currentDealer.name;
                for (let metric in campaign) {
                    if(!['campaign_name', 'campaign_id', 'product_id'].includes(metric)) {
                        newData[metric] = campaign[metric].formatted;
                    }
                }
                return newData;
            });
            csvExporter.generateCsv(exportData);
        }
    }
};
</script>

<style lang="scss" scoped>
.metric-items {
    .select-left  {
        outline: 2px solid #7FDDFE !important;
        outline-offset: -7px;
    }
    @media (min-width: 960px) {
        .custom {
            flex-basis: calc(100%/7) !important;
            flex-grow: 0;
            max-width: calc(100%/7) !important;
        }
    }
    .select-right  {
        outline:2px solid #E350B4 !important;
        outline-offset: -7px;
    }
}
</style>

