e<template>
    <styled-interface>
        <template #interface-toolbar-external>
            <dealer-toolbar-global
                hide-interval
                hide-attribution
                hide-comparison
                hide-campaigns />
        </template>
        <div class="layout wrap">
            <global-alert />
            <onboarding-card
                v-if="!userIsClient"
                class="flex xs12" />
            <overall-status v-if="!userIsClient" />
            <no-data v-if="!loading && !platformMetricsAvailable" />
            <advertising-stats v-else />
            <metric-comparison
                v-if="!loading && platformMetricsAvailable"
                left-metric="impressions"
                right-metric="clicks"
                :metrics-data="metricsIntervals"
                :available-metrics="metricsAvailable" />
        </div>
    </styled-interface>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import OverallStatus from '@/components/dealers/dashboards/facebook/cards/OverallStatus';
import StyledInterface from '@/components/globals/StyledInterface';
import DealerToolbarGlobal from '@/components/globals/DealerToolbarGlobal';
import GlobalAlert from '@/components/globals/GlobalAlert';
import OnboardingCard from '@/components/dealers/DealerID/OnboardingCard';
import NoData from './cards/NoData';
import AdvertisingStats from './cards/advertising-stats/AdvertisingStats';
import MetricComparison from './cards/MetricComparison';
import { SET_INTERVAL } from '@/store/mutation-types';

export default {
    name: 'GlobalDashboard',
    title: 'Global Dashboard',
    components: {
        StyledInterface,
        DealerToolbarGlobal,
        GlobalAlert,
        OnboardingCard,
        OverallStatus,
        NoData,
        AdvertisingStats,
        MetricComparison,
    },
    data() {
        return {
            metricsAvailable: [
                { text: 'Clicks', value: 'clicks' },
                { text: 'Conversion Rate', value: 'conversion_rate' },
                { text: 'Cost Per Click (CPC)', value: 'cpc' },
                { text: 'Cost Per Lead (CPL)', value: 'cpl' },
                { text: 'Click Through Rate (CTR)', value: 'ctr' },
                { text: 'Impressions', value: 'impressions' },
                { text: 'Leads', value: 'leads' },
                { text: 'Spend', value: 'spend' },
            ]
        };
    },
    computed: {
        ...mapState({
            metrics: state => state.metrics,
            platformsSelected: state => state.metrics.aggregate.platformsSelected,
            interval: state => state.metrics.interval
        }),
        ...mapGetters([
            'dealerFeatures',
            'userIsClient'
        ]),
        ...mapGetters('metrics/aggregate',[
            'loading',
            'metricsTotals',
            'metricsIntervals',
            'hasMetrics'
        ]),
        platformMetricsAvailable() {
            const features = [
                'facebook_ad_account',
                'snapchat_ad_account',
                'tiktok_ad_account',
                'pinterest_ad_account',
            ];
            return features.some(f => this.dealerFeatures.includes(f)) && this.hasMetrics;
        }
    },
    activated() {
        // Prevents issues when FB is set to something other than daily
        this.$store.commit(SET_INTERVAL, 'day');
    },
};
</script>
