// User mutations
export const SET_STATUS = 'SET_STATUS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_TEMP_LOGIN_DETAILS = 'SET_TEMP_LOGIN_DETAILS';
export const SET_USER = 'SET_USER';
export const SET_INITIALIZING = 'SET_INITIALIZING';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_USER_WELCOMED = 'SET_USER_WELCOMED';

// Dealer mutations
export const SET_DEALERS = 'SET_DEALERS';
export const SET_DEALERS_LOADING = 'SET_DEALERS_LOADING';
export const UPDATE_DEALER = 'UPDATE_DEALER';
export const SET_DEALER = 'SET_DEALER';
export const SET_DEALER_ID = 'SET_DEALER_ID';
export const SET_DEALER_LOADING = 'SET_DEALER_LOADING';
export const SET_DEALER_FEATURE_STATUS = 'SET_DEALER_FEATURE_STATUS';

export const SET_DEALER_FEATURE_STATUS_LOADING = 'SET_DEALER_FEATURE_STATUS_LOADING';
export const SET_DEALER_FEATURE_STATUS_UPDATED_AT = 'SET_DEALER_FEATURE_STATUS_UPDATED_AT';
export const RECHECK_FACEBOOK_ASSETS = 'RECHECK_FACEBOOK_ASSETS';


// Agency mutations
export const SET_AGENCIES = 'SET_AGENCIES';
export const SET_AGENCIES_LOADING = 'SET_AGENCIES_LOADING';
export const SET_AGENCY = 'SET_AGENCY';
export const SET_AGENCY_LOADING = 'SET_AGENCY_LOADING';
export const SET_ORGANIZATION_CONFIGURATION = 'SET_ORGANIZATION_CONFIGURATION';
export const SET_AGENCY_PRIVACY_POLICY_IS_SIGNED = 'SET_AGENCY_PRIVACY_POLICY_IS_SIGNED';
export const SET_AGENCY_TERMS_IS_SIGNED = 'SET_AGENCY_TERMS_IS_SIGNED';
export const SET_AGENCY_PRIVACY_POLICY_LOADED = 'SET_AGENCY_PRIVACY_POLICY_LOADED';
export const SET_AGENCY_TERMS_LOADED = 'SET_AGENCY_TERMS_LOADED';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_SELECTED_GROUPS = 'SET_SELECTED_GROUPS';
export const SET_GROUPS_LOADING = 'SET_GROUPS_LOADING';

// Alert mutations
export const SET_DEALER_ALERTS = 'SET_DEALER_ALERTS';
export const SET_DEALER_ALERTS_LOADING = 'SET_DEALER_ALERTS_LOADING';
export const SET_AGENCY_ALERTS = 'SET_AGENCY_ALERTS';
export const SET_AGENCY_ALERTS_LOADING = 'SET_AGENCY_ALERTS_LOADING';
export const START_REFRESH_ALERT_TYPE = 'START_REFRESH_ALERT_TYPE';
export const COMPLETE_REFRESH_ALERT_TYPE = 'COMPLETE_REFRESH_ALERT_TYPE';
export const SET_ENABLING_ACTIVE = 'SET_ENABLING_ACTIVE';

// Brand mutations
export const SET_BRAND = 'SET_BRAND';

// Onboarding mutations
export const SET_ONBOARDING_INITIALIZING = 'SET_ONBOARDING_INITIALIZING';
export const SET_ONBOARDING_STATUS_LOADING = 'SET_ONBOARDING_STATUS_LOADING';
export const SET_ONBOARDING_EXISTING_FEATURES = 'SET_ONBOARDING_EXISTING_FEATURES';
export const SET_ONBOARDING_DATA = 'SET_ONBOARDING_DATA';
export const UPDATE_ONBOARDING_DATA = 'UPDATE_ONBOARDING_DATA';
export const SET_ONBOARDING_ID = 'SET_ONBOARDING_ID';
export const SET_ONBOARDING_TYPE = 'SET_ONBOARDING_TYPE';
export const SET_ONBOARDING_ERROR = 'SET_ONBOARDING_ERROR';
export const SET_ONBOARDING_PRODUCT_IDS = 'SET_ONBOARDING_PRODUCT_IDS';
export const SET_ONBOARDING_PLAY = 'SET_ONBOARDING_PLAY';
export const SET_ONBOARDING_PLAY_ID = 'SET_ONBOARDING_PLAY_ID';
export const SET_ONBOARDING_PLAY_LOADING = 'SET_ONBOARDING_PLAY_LOADING';
export const SET_ONBOARDING_PLAY_CAMPAIGNS = 'SET_ONBOARDING_PLAY_CAMPAIGNS';
export const SET_ONBOARDING_PLAY_CAMPAIGNS_LOADING = 'SET_ONBOARDING_PLAY_CAMPAIGNS_LOADING';
export const SET_ONBOARDING_CURRENT_STEP = 'SET_ONBOARDING_CURRENT_STEP';
export const SET_ONBOARDING_LATEST_STEP = 'SET_ONBOARDING_LATEST_STEP';
export const SET_ONBOARDING_NEXT_STEP = 'SET_ONBOARDING_NEXT_STEP';
export const SET_ONBOARDING_PREVIOUS_STEP = 'SET_ONBOARDING_PREVIOUS_STEP';
export const TOGGLE_ONBOARDING_SKIP_DISCLAIMER = 'TOGGLE_ONBOARDING_SKIP_DISCLAIMER';
export const SET_ONBOARDING_PRODUCTS = 'SET_ONBOARDING_PRODUCTS';
export const SET_ONBOARDINGS = 'SET_ONBOARDINGS';
export const SET_ONBOARDINGS_LOADING = 'SET_ONBOARDINGS_LOADING';
export const SET_ONBOARDINGS_UPDATED_AT = 'SET_ONBOARDINGS_UPDATED_AT';
export const SET_ONBOARDING_REDIRECT_URL = 'SET_ONBOARDING_REDIRECT_URL';
export const SET_DEALER_TIMEZONES = 'SET_DEALER_TIMEZONES';
export const SET_PAGE_EDITED = 'SET_PAGE_EDITED';
export const SET_AD_COPY_COMPLETE = 'SET_AD_COPY_COMPLETE';
export const SET_LANDING_PAGE_URLS_COMPLETE = 'SET_LANDING_PAGE_URLS_COMPLETE';
// Support mutations
export const TOGGLE_NEW_TICKET_DIALOG = 'TOGGLE_NEW_TICKET_DIALOG';
export const SET_TICKET_TYPE = 'SET_TICKET_TYPE';
export const SET_NEW_TICKET_DATA = 'SET_NEW_TICKET_DATA';

// Settings mutations
export const SET_USER_DATA = 'SET_USER_DATA';
export const TOGGLE_OPPORTUNITY_SLIDEOUT = 'TOGGLE_OPPORTUNITY_SLIDEOUT';
export const TOGGLE_CHANNEL_SETTINGS_SLIDEOUT = 'TOGGLE_CHANNEL_SETTINGS_SLIDEOUT';
export const TOGGLE_PAGE_CHANGE_SLIDEOUT = 'TOGGLE_PAGE_CHANGE_SLIDEOUT';
export const SET_CHANNEL_SETTINGS_TAB = 'SET_CHANNEL_SETTINGS_TAB';
export const SET_CHANNEL_CONNECTION_DEBUG_PANEL = 'SET_CHANNEL_CONNECTION_DEBUG_PANEL';
export const TOGGLE_ORGANIZATION_SETTINGS_SLIDEOUT = 'TOGGLE_ORGANIZATION_SETTINGS_SLIDEOUT';

// Uploader mutations
export const SET_DISMISSED_ALERTS = 'SET_DISMISSED_ALERTS';

// Report Builder mutations
export const SET_AVAILABLE_TEMPLATES = 'SET_AVAILABLE_TEMPLATES';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const SET_ACTIVE_PAGE = 'SET_ACTIVE_PAGE';
export const DUPLICATE_PAGE = 'DUPLICATE_PAGE';
export const MOVE_PAGE = 'MOVE_PAGE';
export const REORDER_PAGES = 'REORDER_PAGES';
export const ADD_PAGES = 'ADD_PAGES';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_PAGE_META = 'UPDATE_PAGE_META';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const DELETE_PAGE = 'DELETE_PAGE';

// Metrics mutations
export const SET_DATE_RANGE = 'metrics/SET_DATE_RANGE';
export const SET_PREVIOUS_DATE_RANGE = 'metrics/SET_PREVIOUS_DATE_RANGE';
export const SET_COMPARISON = 'metrics/SET_COMPARISON';
export const SET_INTERVAL = 'metrics/SET_INTERVAL';
export const SET_SPEND_OVERRIDE_DISABLE = 'metrics/SET_SPEND_OVERRIDE_DISABLE';
export const SET_AGG_PLATFORMS_SELECTED = 'metrics/aggregate/SET_AGG_PLATFORMS_SELECTED';

// Facebook Ads mutations
export const SET_FB_ATTRIBUTION = 'metrics/facebook/ads/SET_ATTRIBUTION';
export const UPDATE_FB_ATTRIBUTION = 'metrics/facebook/ads/UPDATE_ATTRIBUTION';

export const SET_FB_DEALER_OFFLINE_EVENTS = 'metrics/facebook/ads/SET_DEALER_OFFLINE_EVENTS';
export const SET_FB_DEALER_OFFLINE_EVENTS_LOADING = 'metrics/facebook/ads/SET_DEALER_OFFLINE_EVENTS_LOADING';
export const SET_FB_DEALER_OFFLINE_EVENTS_ERROR = 'metrics/facebook/ads/SET_DEALER_OFFLINE_EVENTS_ERROR';

export const SET_FB_DEALER_OFFLINE_EVENTS_CURRENT = 'metrics/facebook/ads/SET_DEALER_OFFLINE_EVENTS_CURRENT';
export const SET_FB_DEALER_OFFLINE_EVENTS_CURRENT_LOADING = 'metrics/facebook/ads/SET_DEALER_OFFLINE_EVENTS_CURRENT_LOADING';

export const SET_FB_DEALER_CAMPAIGNS = 'metrics/facebook/ads/SET_DEALER_CAMPAIGNS';
export const SET_FB_DEALER_CAMPAIGNS_SELECTED = 'metrics/facebook/ads/SET_DEALER_CAMPAIGNS_SELECTED';
export const SET_FB_DEALER_CAMPAIGNS_LOADING = 'metrics/facebook/ads/SET_DEALER_CAMPAIGNS_LOADING';

export const SET_FB_DEALER_INSIGHTS_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_LOADING';
export const SET_FB_DEALER_INSIGHTS_ERROR = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_ERROR';

export const SET_FB_DEALER_INSIGHTS_TOTALS = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS';
export const SET_FB_DEALER_INSIGHTS_TOTALS_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS_LOADING';

export const SET_FB_DEALER_INSIGHTS_INTERVALS = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_INTERVALS';
export const SET_FB_DEALER_INSIGHTS_INTERVALS_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_INTERVALS_LOADING';

export const SET_FB_DEALER_INSIGHTS_CAMPAIGNS = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_CAMPAIGNS';
export const SET_FB_DEALER_INSIGHTS_CAMPAIGNS_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_CAMPAIGNS_LOADING';

export const SET_FB_DEALER_INSIGHTS_INVENTORY= 'SET_FB_DEALER_INSIGHTS_INVENTORY';
export const SET_FB_DEALER_INSIGHTS_INVENTORY_LOADING= 'SET_FB_DEALER_INSIGHTS_INVENTORY_LOADING';

export const SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS_COMPARISON';
export const SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS_COMPARISON_LOADING';

export const SET_FB_DEALER_INSIGHTS_INTERVALS_COMPARISON = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_INTERVALS_COMPARISON';
export const SET_FB_DEALER_INSIGHTS_INTERVALS_COMPARISON_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_INTERVALS_COMPARISON_LOADING';

export const SET_FB_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON';
export const SET_FB_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON_LOADING';

export const SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS';
export const SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS_LOADING';

export const SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH';
export const SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH_LOADING = 'metrics/facebook/ads/SET_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH_LOADING';

// Facebook Marketplace mutations
export const SET_FB_DEALER_MARKETPLACE_METRICS_ERROR = 'metrics/facebook/marketplace/SET_METRICS_ERROR';

export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS = 'metrics/facebook/marketplace/SET_METRICS_TOTALS';
export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_LOADING = 'metrics/facebook/marketplace/SET_METRICS_TOTALS_LOADING';

export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_COMPARISON = 'metrics/facebook/marketplace/SET_METRICS_TOTALS_COMPARISON';
export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_COMPARISON_LOADING = 'metrics/facebook/marketplace/SET_METRICS_TOTALS_COMPARISON_LOADING';

export const SET_FB_DEALER_MARKETPLACE_METRICS_INTERVALS = 'metrics/facebook/marketplace/SET_METRICS_INTERVALS';
export const SET_FB_DEALER_MARKETPLACE_METRICS_INTERVALS_LOADING = 'metrics/facebook/marketplace/SET_METRICS_INTERVALS_LOADING';

export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_90_DAYS = 'metrics/facebook/marketplace/SET_METRICS_TOTALS_PREVIOUS_90_DAYS';
export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING = 'metrics/facebook/marketplace/SET_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING';

export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_MONTH = 'metrics/facebook/marketplace/SET_METRICS_TOTALS_PREVIOUS_MONTH';
export const SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_MONTH_LOADING = 'metrics/facebook/marketplace/SET_METRICS_TOTALS_PREVIOUS_MONTH_LOADING';


// Snapchat Ads mutations
export const SET_SC_DEALER_METRICS_ERROR = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_ERROR';
export const SET_SC_DEALER_METRICS_PREVIOUS_PERIOD_ERROR = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_PREVIOUS_PERIOD_ERROR';

export const SET_SC_DEALER_CAMPAIGNS = 'metrics/snapchat/ads/SET_SC_DEALER_CAMPAIGNS';
export const SET_SC_DEALER_CAMPAIGNS_SELECTED = 'metrics/snapchat/ads/SET_SC_DEALER_CAMPAIGNS_SELECTED';
export const SET_SC_DEALER_CAMPAIGNS_LOADING = 'metrics/snapchat/ads/SET_SC_DEALER_CAMPAIGNS_LOADING';

export const SET_SC_DEALER_METRICS_TOTALS = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS';
export const SET_SC_DEALER_METRICS_TOTALS_LOADING = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS_LOADING';

export const SET_SC_DEALER_METRICS_INTERVALS = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_INTERVALS';
export const SET_SC_DEALER_METRICS_INTERVALS_LOADING = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_INTERVALS_LOADING';

export const SET_SC_DEALER_METRICS_CAMPAIGNS = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_CAMPAIGNS';
export const SET_SC_DEALER_METRICS_CAMPAIGNS_LOADING = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_CAMPAIGNS_LOADING';

export const SET_SC_DEALER_METRICS_TOTALS_COMPARISON = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS_COMPARISON';
export const SET_SC_DEALER_METRICS_TOTALS_COMPARISON_LOADING = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS_COMPARISON_LOADING';

export const SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS';
export const SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING';

export const SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_MONTH = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_MONTH';
export const SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING = 'metrics/snapchat/ads/SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING';


// TikTok Ads mutations
export const SET_TT_DEALER_METRICS_ERROR = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_ERROR';
export const SET_TT_DEALER_METRICS_PREVIOUS_PERIOD_ERROR = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_PREVIOUS_PERIOD_ERROR';

export const SET_TT_DEALER_CAMPAIGNS = 'metrics/tiktok/ads/SET_TT_DEALER_CAMPAIGNS';
export const SET_TT_DEALER_CAMPAIGNS_SELECTED = 'metrics/tiktok/ads/SET_TT_DEALER_CAMPAIGNS_SELECTED';
export const SET_TT_DEALER_CAMPAIGNS_LOADING = 'metrics/tiktok/ads/SET_TT_DEALER_CAMPAIGNS_LOADING';

export const SET_TT_DEALER_METRICS_TOTALS = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS';
export const SET_TT_DEALER_METRICS_TOTALS_LOADING = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS_LOADING';

export const SET_TT_DEALER_METRICS_INTERVALS = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_INTERVALS';
export const SET_TT_DEALER_METRICS_INTERVALS_LOADING = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_INTERVALS_LOADING';

export const SET_TT_DEALER_METRICS_CAMPAIGNS = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_CAMPAIGNS';
export const SET_TT_DEALER_METRICS_CAMPAIGNS_LOADING = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_CAMPAIGNS_LOADING';

export const SET_TT_DEALER_METRICS_TOTALS_COMPARISON = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS_COMPARISON';
export const SET_TT_DEALER_METRICS_TOTALS_COMPARISON_LOADING = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS_COMPARISON_LOADING';

export const SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS';
export const SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING';

export const SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH';
export const SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING = 'metrics/tiktok/ads/SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING';

// Pinterest Ads mutations
export const SET_PIN_DEALER_METRICS_RUNNING = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_RUNNING';
export const SET_PIN_DEALER_METRICS_ERROR = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_ERROR';
export const SET_PIN_DEALER_METRICS_PREVIOUS_PERIOD_ERROR = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_PREVIOUS_PERIOD_ERROR';

export const SET_PIN_DEALER_CAMPAIGNS = 'metrics/pinterest/ads/SET_PIN_DEALER_CAMPAIGNS';
export const SET_PIN_DEALER_CAMPAIGNS_SELECTED = 'metrics/pinterest/ads/SET_PIN_DEALER_CAMPAIGNS_SELECTED';
export const SET_PIN_DEALER_CAMPAIGNS_LOADING = 'metrics/pinterest/ads/SET_PIN_DEALER_CAMPAIGNS_LOADING';

export const SET_PIN_DEALER_METRICS_TOTALS = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS';
export const SET_PIN_DEALER_METRICS_TOTALS_LOADING = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS_LOADING';

export const SET_PIN_DEALER_METRICS_INTERVALS = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_INTERVALS';
export const SET_PIN_DEALER_METRICS_INTERVALS_LOADING = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_INTERVALS_LOADING';

export const SET_PIN_DEALER_METRICS_CAMPAIGNS = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_CAMPAIGNS';
export const SET_PIN_DEALER_METRICS_CAMPAIGNS_LOADING = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_CAMPAIGNS_LOADING';

export const SET_PIN_DEALER_METRICS_TOTALS_COMPARISON = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS_COMPARISON';
export const SET_PIN_DEALER_METRICS_TOTALS_COMPARISON_LOADING = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS_COMPARISON_LOADING';

export const SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS';
export const SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING';

export const SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH';
export const SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING = 'metrics/pinterest/ads/SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING';

// Google Analytics mutations
export const SET_GA_CHANNELS = 'metrics/google/analytics/SET_CHANNELS';
export const SET_GA_METRICS = 'metrics/google/analytics/SET_METRICS';

export const SET_GA_METRICS_LOADING= 'metrics/google/analytics/SET_METRICS_LOADING';
export const SET_GA_METRICS_LOADING_ERROR = 'metrics/google/analytics/SET_METRICS_LOADING_ERROR';

export const SET_GA_METRICS_COMPARISON = 'metrics/google/analytics/SET_METRICS_COMPARISON';
export const SET_GA_METRICS_COMPARISON_LOADING = 'metrics/google/analytics/SET_METRICS_COMPARISON_LOADING';

export const SET_GA_METRICS_PREVIOUS_90_DAYS= 'metrics/google/analytics/SET_METRICS_PREVIOUS_90_DAYS';
export const SET_GA_METRICS_PREVIOUS_90_DAYS_LOADING= 'metrics/google/analytics/SET_METRICS_PREVIOUS_90_DAYS_LOADING';

export const SET_GA_METRICS_PREVIOUS_MONTH= 'metrics/google/analytics/SET_METRICS_PREVIOUS_MONTH';
export const SET_GA_METRICS_PREVIOUS_MONTH_LOADING= 'metrics/google/analytics/SET_METRICS_PREVIOUS_MONTH_LOADING';

// Creative Library mutations
export const SET_CREATIVE = 'SET_CREATIVE';
export const ADD_NEW_CREATIVE = 'ADD_NEW_CREATIVE';

// Playbook Management mutations
export const SET_PM_CURRENT_TAB = 'playbookManagement/SET_PM_CURRENT_TAB';
export const SET_PM_SELECTED_CAMPAIGNS = 'playbookManagement/SET_PM_SELECTED_CAMPAIGNS';
export const SET_PM_SELECTED_ADSETS = 'playbookManagement/SET_PM_SELECTED_ADSETS';
export const SET_PM_SELECTED_ADS = 'playbookManagement/SET_PM_SELECTED_ADS';
export const SET_PM_CAMPAIGNS = 'playbookManagement/SET_PM_CAMPAIGNS';
export const SET_PM_ADSETS = 'playbookManagement/SET_PM_ADSETS';
export const SET_PM_ADS = 'playbookManagement/SET_PM_ADS';
export const SET_PM_SELECTED_PLATFORMS = 'playbookManagement/SET_PM_SELECTED_PLATFORMS';
export const SET_PM_KEEP_ALIVE_KEY = 'playbookManagement/SET_PM_KEEP_ALIVE_KEY';
export const SET_PM_DEPLOYMENTS = 'playbookManagement/SET_PM_DEPLOYMENTS';
export const SET_PM_CAMPAIGN_MANAGING = 'playbookManagement/SET_PM_CAMPAIGN_MANAGING';

// Ad Deployment Step Mutations
export const SET_AD_DEPLOYMENT_PLATFORMS = 'adDeployment/SET_AD_DEPLOYMENT_PLATFORMS'; // 1. Channels
export const SET_AD_DEPLOYMENT_ADS = 'adDeployment/SET_AD_DEPLOYMENT_ADS'; // 2. Ads
export const SET_AD_DEPLOYMENT_CAMPAIGN_AND_AD_SET_SELECTION = 'adDeployment/SET_AD_DEPLOYMENT_CAMPAIGN_AND_AD_SET_SELECTION'; // 3. Campaign and Ad Set Selection
export const SET_AD_DEPLOYMENT_OPTIMIZATIONS = 'adDeployment/SET_AD_DEPLOYMENT_OPTIMIZATIONS'; // 4. Optimization
export const SET_AD_DEPLOYMENT_AUDIENCE = 'adDeployment/SET_AD_DEPLOYMENT_AUDIENCE'; // 4. Audience
export const SET_AD_DEPLOYMENT_MAP = 'adDeployment/SET_AD_DEPLOYMENT_MAP'; // 5. Map
export const SET_AD_DEPLOYMENT_BUDGET = 'adDeployment/SET_AD_DEPLOYMENT_BUDGET'; // 6. Budget

// Ad Stutio mutations
export const SET_AD_STUDIO_DATA = 'SET_AD_STUDIO_DATA';

// Layered designer mutations
export const SET_BLUR = 'SET_BLUR';
export const SET_COLOR = 'SET_COLOR';
export const SET_LAYERED_DESIGNER_ACTIVE_STATUS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_ACTIVE_STATUS';
export const SET_LAYERED_DESIGNER_CANVAS_ZOOM= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_CANVAS_ZOOM';
export const SET_LAYERED_DESIGNER_RECOMMENDED_SCALE = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_RECOMMENDED_SCALE';
export const SET_LAYERED_DESIGNER_CARDS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_CARDS';
export const SET_ID = 'SET_ID';

export const SET_LAYERED_DESIGNER_CURRENT_CARD= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_CURRENT_CARD';
export const SET_LAYERED_DESIGNER_CARD_LAYERS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_CARD_LAYERS';

export const SET_LAYERED_DESIGNER_FOCUSED_LAYER= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_DIMENSIONS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_DIMENSIONS';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_NAME = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_NAME';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHOR_POSITION= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHOR_POSITION';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHORED_POSITIONS = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHORED_POSITIONS';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_ROTATE_ANGLE= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_ROTATE_ANGLE';
export const SET_LAYERED_DESIGNER_CURRENT_CARD_DIMENSIONS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_CURRENT_CARD_DIMENSIONS';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_ORIGINAL_DIMENSIONS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_ORIGINAL_DIMENSIONS';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET';
export const SET_LAYERED_DESIGNER_CURRENT_CARD_NAME= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_CURRENT_CARD_NAME';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS';
export const SET_LAYERED_DESIGNER_CURRENT_CARD_THUMBNAIL= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_CURRENT_CARD_THUMBNAIL';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_IS_CLIPPING = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_IS_CLIPPING';
export const SET_LAYERED_DESIGNER_ID= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_ID';
export const SET_LAYERED_DESIGNER_LOADING_STATUS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_LOADING_STATUS';
export const SET_LAYERED_DESIGNER_FONT_CSS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FONT_CSS';
export const SET_LAYERED_DESIGNER_FONT_MANAGER= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FONT_MANAGER';
export const SET_LAYERED_DESIGNER_DOWNLOADED_FONTS= 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_DOWNLOADED_FONTS';
export const SET_LAYERED_DESIGNER_FOCUSED_LAYER_SCALE = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_FOCUSED_LAYER_SCALE';
export const SET_LAYERED_DESIGNER_TEXT_EDITOR_VISIBLE = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_TEXT_EDITOR_VISIBLE';
export const SET_LAYERED_DESIGNER_MODE = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_MODE';
export const SET_LAYERED_DESIGNER_WORKSPACE_OVERFLOW = 'creative-studio/layered-designer/SET_LAYERED_DESIGNER_WORKSPACE_OVERFLOW';
// Asset library mutations
export const SET_ASSET_LIBRARY_ACTIVE_STATUS= 'creative-studio/asset-library/SET_ASSET_LIBRARY_ACTIVE_STATUS';
export const SET_ASSET_LIBRARY_ASSET_TYPE= 'creative-studio/asset-library/SET_ASSET_LIBRARY_ASSET_TYPE';
export const SET_ASSET_LIBRARY_MODE= 'creative-studio/asset-library/SET_ASSET_LIBRARY_MODE';

// menu state
export const SET_GLOBAL_MENU = 'SET_GLOBAL_MENU';

// inventory dashboard
export const SET_ACTIVATED_CHANNELS = 'SET_ACTIVATED_CHANNELS';
export const SET_INVENTORY_ADS_VIEW = 'SET_INVENTORY_ADS_VIEW';

export default {
    SET_STATUS,
    SET_TOKEN,
    SET_IS_LOGGED_IN,
    SET_TEMP_LOGIN_DETAILS,
    SET_USER,
    SET_INITIALIZING,
    SET_USER_LOCATION,
    SET_DEALERS,
    SET_DEALERS_LOADING,
    UPDATE_DEALER,
    SET_DEALER,
    SET_DEALER_ID,
    SET_DEALER_LOADING,
    SET_DEALER_FEATURE_STATUS,
    SET_DEALER_FEATURE_STATUS_LOADING,
    SET_DEALER_FEATURE_STATUS_UPDATED_AT,
    SET_AGENCIES,
    SET_AGENCIES_LOADING,
    SET_AGENCY,
    SET_AGENCY_LOADING,
    SET_BRAND,
    SET_ONBOARDING_INITIALIZING,
    SET_ONBOARDING_STATUS_LOADING,
    SET_ONBOARDING_EXISTING_FEATURES,
    SET_ONBOARDING_DATA,
    UPDATE_ONBOARDING_DATA,
    SET_ONBOARDING_ID,
    SET_ONBOARDING_TYPE,
    SET_ONBOARDING_ERROR,
    SET_ONBOARDING_PRODUCT_IDS,
    SET_ONBOARDING_PLAY,
    SET_ONBOARDING_PLAY_ID,
    SET_ONBOARDING_PLAY_LOADING,
    SET_ONBOARDING_PLAY_CAMPAIGNS,
    SET_ONBOARDING_PLAY_CAMPAIGNS_LOADING,
    SET_ONBOARDING_CURRENT_STEP,
    SET_ONBOARDING_LATEST_STEP,
    SET_ONBOARDING_NEXT_STEP,
    SET_ONBOARDING_PREVIOUS_STEP,
    SET_ONBOARDING_PRODUCTS,
    SET_ONBOARDINGS,
    SET_ONBOARDINGS_LOADING,
    SET_ONBOARDINGS_UPDATED_AT,
    SET_ONBOARDING_REDIRECT_URL,
    TOGGLE_ONBOARDING_SKIP_DISCLAIMER,
    TOGGLE_NEW_TICKET_DIALOG,
    SET_TICKET_TYPE,
    SET_GROUPS,
    SET_SELECTED_GROUPS,
    SET_GROUPS_LOADING,
    SET_PAGE_EDITED,
    SET_AD_COPY_COMPLETE,
    SET_LANDING_PAGE_URLS_COMPLETE,

    // Report Builder mutations
    SET_AVAILABLE_TEMPLATES,
    SET_TEMPLATE,
    UPDATE_TEMPLATE,
    SET_ACTIVE_PAGE,
    DUPLICATE_PAGE,
    MOVE_PAGE,
    REORDER_PAGES,
    ADD_PAGES,
    UPDATE_PAGE,
    UPDATE_PAGE_META,
    UPDATE_WIDGET,
    DELETE_PAGE,
    SET_NEW_TICKET_DATA,
    TOGGLE_OPPORTUNITY_SLIDEOUT,
    TOGGLE_CHANNEL_SETTINGS_SLIDEOUT,
    TOGGLE_PAGE_CHANGE_SLIDEOUT,
    TOGGLE_ORGANIZATION_SETTINGS_SLIDEOUT,
    SET_CHANNEL_SETTINGS_TAB,
    SET_CHANNEL_CONNECTION_DEBUG_PANEL,
    SET_DISMISSED_ALERTS,
    SET_DEALER_TIMEZONES,
    RECHECK_FACEBOOK_ASSETS,

    // Export metrics mutations
    SET_DATE_RANGE,
    SET_PREVIOUS_DATE_RANGE,
    SET_COMPARISON,
    SET_INTERVAL,
    SET_SPEND_OVERRIDE_DISABLE,
    SET_AGG_PLATFORMS_SELECTED,

    // Export Facebook ads mutations
    SET_FB_ATTRIBUTION,
    UPDATE_FB_ATTRIBUTION,
    SET_FB_DEALER_OFFLINE_EVENTS,
    SET_FB_DEALER_OFFLINE_EVENTS_LOADING,
    SET_FB_DEALER_OFFLINE_EVENTS_ERROR,
    SET_FB_DEALER_OFFLINE_EVENTS_CURRENT,
    SET_FB_DEALER_OFFLINE_EVENTS_CURRENT_LOADING,
    SET_FB_DEALER_CAMPAIGNS,
    SET_FB_DEALER_CAMPAIGNS_SELECTED,
    SET_FB_DEALER_CAMPAIGNS_LOADING,
    SET_FB_DEALER_INSIGHTS_ERROR,
    SET_FB_DEALER_INSIGHTS_TOTALS,
    SET_FB_DEALER_INSIGHTS_TOTALS_LOADING,
    SET_FB_DEALER_INSIGHTS_INTERVALS,
    SET_FB_DEALER_INSIGHTS_INTERVALS_LOADING,
    SET_FB_DEALER_INSIGHTS_CAMPAIGNS,
    SET_FB_DEALER_INSIGHTS_CAMPAIGNS_LOADING,
    SET_FB_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON,
    SET_FB_DEALER_INSIGHTS_CAMPAIGNS_COMPARISON_LOADING,
    SET_FB_DEALER_INSIGHTS_INVENTORY,
    SET_FB_DEALER_INSIGHTS_INVENTORY_LOADING,
    SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON,
    SET_FB_DEALER_INSIGHTS_TOTALS_COMPARISON_LOADING,
    SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS,
    SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_90_DAYS_LOADING,
    SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH,
    SET_FB_DEALER_INSIGHTS_TOTALS_PREVIOUS_MONTH_LOADING,

    // Export Facebook marketplace mutations
    SET_FB_DEALER_MARKETPLACE_METRICS_ERROR,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_LOADING,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_COMPARISON,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_COMPARISON_LOADING,
    SET_FB_DEALER_MARKETPLACE_METRICS_INTERVALS,
    SET_FB_DEALER_MARKETPLACE_METRICS_INTERVALS_LOADING,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_90_DAYS,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_MONTH,
    SET_FB_DEALER_MARKETPLACE_METRICS_TOTALS_PREVIOUS_MONTH_LOADING,

    // Export Snapchat ads mutations
    SET_SC_DEALER_CAMPAIGNS,
    SET_SC_DEALER_CAMPAIGNS_SELECTED,
    SET_SC_DEALER_CAMPAIGNS_LOADING,
    SET_SC_DEALER_METRICS_ERROR,
    SET_SC_DEALER_METRICS_TOTALS,
    SET_SC_DEALER_METRICS_TOTALS_LOADING,
    SET_SC_DEALER_METRICS_INTERVALS,
    SET_SC_DEALER_METRICS_INTERVALS_LOADING,
    SET_SC_DEALER_METRICS_CAMPAIGNS,
    SET_SC_DEALER_METRICS_CAMPAIGNS_LOADING,
    SET_SC_DEALER_METRICS_PREVIOUS_PERIOD_ERROR,
    SET_SC_DEALER_METRICS_TOTALS_COMPARISON,
    SET_SC_DEALER_METRICS_TOTALS_COMPARISON_LOADING,
    SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS,
    SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING,
    SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_MONTH,
    SET_SC_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING,

    // Export TikTok ads mutations
    SET_TT_DEALER_CAMPAIGNS,
    SET_TT_DEALER_CAMPAIGNS_SELECTED,
    SET_TT_DEALER_CAMPAIGNS_LOADING,
    SET_TT_DEALER_METRICS_ERROR,
    SET_TT_DEALER_METRICS_TOTALS,
    SET_TT_DEALER_METRICS_TOTALS_LOADING,
    SET_TT_DEALER_METRICS_INTERVALS,
    SET_TT_DEALER_METRICS_INTERVALS_LOADING,
    SET_TT_DEALER_METRICS_CAMPAIGNS,
    SET_TT_DEALER_METRICS_CAMPAIGNS_LOADING,
    SET_TT_DEALER_METRICS_PREVIOUS_PERIOD_ERROR,
    SET_TT_DEALER_METRICS_TOTALS_COMPARISON,
    SET_TT_DEALER_METRICS_TOTALS_COMPARISON_LOADING,
    SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS,
    SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING,
    SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH,
    SET_TT_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING,

    // Export Pinterest ads mutations
    SET_PIN_DEALER_CAMPAIGNS,
    SET_PIN_DEALER_CAMPAIGNS_SELECTED,
    SET_PIN_DEALER_CAMPAIGNS_LOADING,
    SET_PIN_DEALER_METRICS_RUNNING,
    SET_PIN_DEALER_METRICS_ERROR,
    SET_PIN_DEALER_METRICS_TOTALS,
    SET_PIN_DEALER_METRICS_TOTALS_LOADING,
    SET_PIN_DEALER_METRICS_INTERVALS,
    SET_PIN_DEALER_METRICS_INTERVALS_LOADING,
    SET_PIN_DEALER_METRICS_CAMPAIGNS,
    SET_PIN_DEALER_METRICS_CAMPAIGNS_LOADING,
    SET_PIN_DEALER_METRICS_PREVIOUS_PERIOD_ERROR,
    SET_PIN_DEALER_METRICS_TOTALS_COMPARISON,
    SET_PIN_DEALER_METRICS_TOTALS_COMPARISON_LOADING,
    SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS,
    SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_90_DAYS_LOADING,
    SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH,
    SET_PIN_DEALER_METRICS_TOTALS_PREVIOUS_MONTH_LOADING,

    // Export Google Analytics mutations
    SET_GA_CHANNELS,
    SET_GA_METRICS,
    SET_GA_METRICS_LOADING,
    SET_GA_METRICS_LOADING_ERROR,
    SET_GA_METRICS_COMPARISON,
    SET_GA_METRICS_COMPARISON_LOADING,
    SET_GA_METRICS_PREVIOUS_90_DAYS,
    SET_GA_METRICS_PREVIOUS_90_DAYS_LOADING,
    SET_GA_METRICS_PREVIOUS_MONTH,
    SET_GA_METRICS_PREVIOUS_MONTH_LOADING,

    // Export Layered designer mutations
    SET_LAYERED_DESIGNER_ACTIVE_STATUS,
    SET_LAYERED_DESIGNER_CANVAS_ZOOM,
    SET_LAYERED_DESIGNER_RECOMMENDED_SCALE,
    SET_LAYERED_DESIGNER_CARDS,
    SET_LAYERED_DESIGNER_CURRENT_CARD,
    SET_LAYERED_DESIGNER_CARD_LAYERS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ASSET,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_POSITION,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_DIMENSIONS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_NAME,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHOR_POSITION,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ANCHORED_POSITIONS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ROTATE_ANGLE,
    SET_LAYERED_DESIGNER_CURRENT_CARD_DIMENSIONS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_CROP_OFFSET,
    SET_LAYERED_DESIGNER_CURRENT_CARD_NAME,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ALTERATIONS,
    SET_LAYERED_DESIGNER_CURRENT_CARD_THUMBNAIL,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_SHADOW,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_GRADIENT_COLOR,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_IS_CLIPPING,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_COLOR_MODE,
    SET_LAYERED_DESIGNER_ID,
    SET_LAYERED_DESIGNER_LOADING_STATUS,
    SET_LAYERED_DESIGNER_FONT_CSS,
    SET_LAYERED_DESIGNER_FONT_MANAGER,
    SET_LAYERED_DESIGNER_DOWNLOADED_FONTS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_ORIGINAL_DIMENSIONS,
    SET_LAYERED_DESIGNER_FOCUSED_LAYER_SCALE,
    SET_LAYERED_DESIGNER_TEXT_EDITOR_VISIBLE,
    SET_LAYERED_DESIGNER_MODE,
    SET_LAYERED_DESIGNER_WORKSPACE_OVERFLOW,
    // Export Asset library mutations
    SET_ASSET_LIBRARY_ACTIVE_STATUS,
    SET_ASSET_LIBRARY_ASSET_TYPE,
    SET_ASSET_LIBRARY_MODE,
    // Export Playbook Management mutations
    SET_PM_CURRENT_TAB,
    SET_PM_CAMPAIGNS,
    SET_PM_ADSETS,
    SET_PM_ADS,
    SET_PM_SELECTED_CAMPAIGNS,
    SET_PM_SELECTED_ADSETS,
    SET_PM_SELECTED_ADS,
    SET_PM_SELECTED_PLATFORMS,
    SET_PM_KEEP_ALIVE_KEY,
    SET_PM_DEPLOYMENTS,
    SET_PM_CAMPAIGN_MANAGING,

    // Ad deployment mutations
    SET_AD_DEPLOYMENT_PLATFORMS,
    SET_AD_DEPLOYMENT_ADS,
    SET_AD_DEPLOYMENT_CAMPAIGN_AND_AD_SET_SELECTION,
    SET_AD_DEPLOYMENT_OPTIMIZATIONS,
    SET_AD_DEPLOYMENT_AUDIENCE,
    SET_AD_DEPLOYMENT_MAP,
    SET_AD_DEPLOYMENT_BUDGET,

    // menu state
    SET_GLOBAL_MENU,

    // inventory dashboard
    SET_ACTIVATED_CHANNELS,
    SET_INVENTORY_ADS_VIEW,
};
