<template>
    <div class="funnel-container">
        <styled-selector
            label="Types"
            :items="availableFunnels"
            item-text="name"
            item-value="value"
            @input="changeFunnel" />
        <div
            class="styled-funnel"
            :class="{'hide-spend': hideSpend, 'hide-change': !showChange}">
            <div
                class="block"
                :class="{'active': metric == availableMetrics[0]}"
                @click="selectMetric(availableMetrics[0])">
                <div class="block__nameholder">
                    <div class="block__name">
                        {{ activeFunnel.metrics[0].title }}
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ getFormattedMetric(activeFunnel.metrics[0].metric) }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="insightsTotals[activeFunnel.metrics[0].metric].value"
                            :previous-metric-value="showChange ? insightsTotalsComparison[activeFunnel.metrics[0].metric].value : 0" />
                    </div>
                </div>
                <div class="block__divider" />
                <div class="block__metrics">
                    <div class="block__subname">
                        Spend
                    </div>
                    <div class="block__price">
                        {{ getFormattedMetric(activeFunnel.metrics[0].cost.metric) }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="insightsTotals.spend.value"
                            :previous-metric-value="showChange ? insightsTotalsComparison.spend.value : 0" />
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block__nameholder">
                    <div class="block__name">
                        {{ activeFunnel.metrics[0].rate.title }}
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ getFormattedMetric(activeFunnel.metrics[0].rate.metric) }}
                    </div>
                </div>
            </div>
            <div
                class="block"
                :class="{'active': metric == availableMetrics[1]}"
                @click="selectMetric(availableMetrics[1])">
                <div class="block__nameholder">
                    <div class="block__name">
                        {{ activeFunnel.metrics[1].title }}
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ getFormattedMetric(activeFunnel.metrics[1].metric) }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="insightsTotals[activeFunnel.metrics[1].metric].value"
                            :previous-metric-value="showChange ? insightsTotalsComparison[activeFunnel.metrics[1].metric].value : 0" />
                    </div>
                </div>
                <div class="block__divider" />
                <div class="block__metrics">
                    <div class="block__subname">
                        {{ activeFunnel.metrics[1].cost.title }}
                    </div>
                    <div class="block__price">
                        {{ getFormattedMetric(activeFunnel.metrics[1].cost.metric) }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="insightsTotals[activeFunnel.metrics[1].cost.metric].value"
                            :previous-metric-value="showChange ? insightsTotalsComparison[activeFunnel.metrics[1].cost.metric].value : 0"
                            change-reversed />
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block__nameholder">
                    <div class="block__name">
                        Conversion Rate
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ getFormattedMetric(activeFunnel.metrics[1].rate.metric) }}
                    </div>
                </div>
            </div>
            <div
                class="block"
                :class="{'active': metric == availableMetrics[2]}"
                @click="selectMetric(availableMetrics[2])">
                <div class="block__nameholder">
                    <div class="block__name">
                        Leads
                    </div>
                </div>
                <div class="block__nums">
                    <div class="block__count">
                        {{ getFormattedMetric(activeFunnel.metrics[2].metric) }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="insightsTotals.leads.value"
                            :previous-metric-value="showChange ? insightsTotalsComparison.leads.value : 0" />
                    </div>
                </div>
                <div class="block__divider" />
                <div class="block__metrics">
                    <div class="block__subname">
                        Cost / Lead
                    </div>
                    <div class="block__price">
                        {{ getFormattedMetric(activeFunnel.metrics[2].cost.metric) }}
                    </div>
                    <div class="block__comparison">
                        <percentage-change
                            :metric-value="insightsTotals.cost_per_lead.value"
                            :previous-metric-value="showChange ? insightsTotalsComparison.cost_per_lead.value : 0"
                            change-reversed />
                    </div>
                </div>
            </div>
            <template v-if="hasOfflinePurchaseEvents">
                <div class="block">
                    <div class="block__nameholder">
                        <div class="block__name">
                            Conversion Rate
                        </div>
                    </div>
                    <div class="block__nums">
                        <div class="block__count">
                            {{ getFormattedMetric(activeFunnel.metrics[2].rate.metric) }}
                        </div>
                    </div>
                </div>
                <div
                    class="block"
                    :class="{'active': metric == availableMetrics[3]}"
                    @click="selectMetric(availableMetrics[3])">
                    <div class="block__nameholder">
                        <div class="block__name">
                            Sales
                        </div>
                    </div>
                    <div class="block__nums">
                        <div class="block__count">
                            {{ getFormattedMetric(activeFunnel.metrics[3].metric) }}
                        </div>
                        <div class="block__comparison">
                            <percentage-change
                                :metric-value="insightsTotals.offline_purchase.value"
                                :previous-metric-value="showChange ? insightsTotalsComparison.offline_purchase.value : 0" />
                        </div>
                    </div>
                    <div class="block__divider" />
                    <div class="block__metrics">
                        <div class="block__subname">
                            Cost / Sale
                        </div>
                        <div class="block__price">
                            {{ getFormattedMetric(activeFunnel.metrics[3].cost.metric) }}
                        </div>
                        <div class="block__comparison">
                            <percentage-change
                                :metric-value="insightsTotals.cost_per_offline_purchase.value"
                                :previous-metric-value="showChange ? insightsTotalsComparison.cost_per_offline_purchase.value : 0"
                                change-reversed />
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <styled-button
            v-if="!hasOfflinePurchaseEvents && !hideSpend"
            id="upload-events-button"
            red
            :to="{
                name: 'dealer-offline-events',
                params: {
                    dealer_id: currentDealerId
                }
            }">
            UPLOAD EVENTS
        </styled-button>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PercentageChange from '@/components/globals/PercentageChange';
import StyledSelector from '@/components/globals/StyledSelector';
import StyledButton from '@/components/globals/StyledButton';

export default {
    name: 'AdvertisingFunnel',
    components: {
        PercentageChange,
        StyledSelector,
        StyledButton
    },

    props: {
        adInsights: {
            type: Object,
            default: ()=> {
                return {};
            }
        },
        metric: {
            type: Object,
            default: ()=> {
                return {};
            }
        }
    },
    data() {
        return {
            selectedFunnel: 'all',
            funnels: [
                {
                    name: 'All Traffic',
                    id: 'all',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Impressions',
                            metric: 'impressions',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Click Rate',
                                metric: 'ctr'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'Clicks',
                            metric: 'clicks',
                            cost: {
                                title: 'Cost / Click',
                                metric: 'cost_per_click'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'lead_to_click_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Per',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                },
                {
                    name: 'Website Traffic',
                    id: 'default',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Prospects',
                            metric: 'reach',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Landing Page View Rate',
                                metric: 'landing_page_view_rate'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'Landing Page Views',
                            metric: 'landing_page_views',
                            cost: {
                                title: 'Cost / View',
                                metric: 'cost_per_landing_page_view'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'lead_to_landing_page_view_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Sale',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                },
                {
                    name: 'Unique Traffic',
                    id: 'unique',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Prospects',
                            metric: 'reach',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Unique Click Rate',
                                metric: 'unique_click_rate'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'Unique Clicks',
                            metric: 'unique_clicks',
                            cost: {
                                title: 'Cost / Click',
                                metric: 'cost_per_unique_click'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'lead_to_unique_click_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            subtitle: null,
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Sale',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                },
                {
                    name: 'On-Facebook Listings',
                    id: 'onfacebook',
                    metrics: [
                        {
                            id: 'prospects',
                            title: 'Prospects',
                            metric: 'reach',
                            cost: {
                                title: 'Spend',
                                metric: 'spend'
                            },
                            rate: {
                                title: 'Unique Click Rate',
                                metric: 'unique_click_rate'
                            }
                        },
                        {
                            id: 'shoppers',
                            title: 'On-FB Content Views',
                            title_font_size: 12,
                            metric: 'clicks',
                            cost: {
                                title: 'Cost / Unique',
                                metric: 'cost_per_unique_on_site_content_view'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric:
                                    'lead_to_unique_on_site_view_content_rate'
                            }
                        },
                        {
                            id: 'leads',
                            title: 'Leads',
                            metric: 'leads',
                            cost: {
                                title: 'Cost / Lead',
                                metric: 'cost_per_lead'
                            },
                            rate: {
                                title: 'Conversion Rate',
                                metric: 'offline_purchase_rate'
                            }
                        },
                        {
                            id: 'sales',
                            title: 'Sales',
                            metric: 'offline_purchase',
                            cost: {
                                title: 'Cost / Per',
                                metric: 'cost_per_offline_purchase'
                            }
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        ...mapGetters('metrics/facebook/ads', {
            insightsTotals: 'metricsTotalsFormatted',
            insightsTotalsComparison: 'metricsTotalsComparisonFormatted',
            offlinePurchaseEvents: 'offlinePurchaseEvents'
        }),
        ...mapGetters({
            hideSpend: 'metrics/hideSpend'
        }),
        ...mapGetters([
            'userIsClient'
        ]),
        ...mapState({
            currentDealerId: state => state.dealer.currentDealerId,
            comparisonVisible: (state) => state.metrics.comparisonVisible,
        }),
        showChange() {
            return !this.userIsClient && !!this.insightsTotalsComparison && this.comparisonVisible;
        },
        hasOfflinePurchaseEvents() {
            return this.offlinePurchaseEvents.filter(event => event.count > 0).length > 0;
        },
        activeFunnel() {
            return this.funnels.find(
                funnel => funnel.id == this.selectedFunnel
            );
        },
        availableFunnels() {
            return this.funnels.map(funnel => {
                return {
                    name: funnel.name,
                    value: funnel.id
                };
            });
        },
        availableMetrics() {
            return this.activeFunnel.metrics.map(m => {
                return {
                    id: m.id,
                    name: m.title,
                    value: m.metric,
                    cost: { name: m.cost.title, key: m.cost.metric }
                };
            });
        }
    },
    created() {
        this.$emit('updateMetrics', this.availableMetrics);
        this.$emit('changeMetric', this.availableMetrics[0]);
    },
    methods: {
        changeFunnel(type) {
            this.selectedFunnel = type.value;
            this.$emit('updateMetrics', this.availableMetrics);
            this.$emit('changeMetric', this.availableMetrics[0]);
        },
        selectMetric(metric) {
            this.$emit('changeMetric', metric);
        },
        getFormattedMetric(metric) {
            if (this.insightsTotals[metric].value === 0) {
                return '-';
            }
            return this.insightsTotals[metric].formatted;
        }
    }
};
</script>

<style lang="scss" scoped>
.funnel-container {
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d8d8d8;
}
#upload-events-button {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    font-weight: 600;
}
</style>